<template>
<div class="modal-tbf modal-full-page">
    <div class="container-modal opacity-page" v-if="loaded">
        <div class="header-modal">
            <div class="title">
                {{$t('clocking.title') + ' - ' + moment().month(data.date.month - 1).year(data.date.year).format('MMMM yyyy')}}
            </div>
            <div class="actions">
                <button class="btn-tbf white only-icon close-btn" @click="cancelRole"><icon-close class="icon-close" /></button>
            </div>
        </div>

        <div class="info-content">
            <div class="container-content-modal clocking">
                <div class="name-label"> {{name}}</div>
                <div class="form-section clocking" v-for="(day, index) in clocking">
                    <div class="input-group w-20">
                        <div class="label-header-months">
                            <label class="label">{{day.day + ' - ' + day.day_name}}</label>
                        </div>
                    </div>
                    <div class="input-group w-40">
                        <div class="label-header">
                            <label v-if="index == 0" class="label title">{{$t('clocking.day_hours')}}</label>
                            <div v-if="day.worked_hours+day.night_hours > 24" class="error-msg">{{ $t('validator.overtime') }}</div>
                        </div>
                         <div v-if="day.is_editable" class="input-box bg-white role" v-bind:class="[{has_error: day.worked_hours === '' || day.worked_hours+day.night_hours > 24}, {'bg-gray': !day.is_editable}]">
                            <input @change="day.is_modified = 1" @keypress="onlyNumber"  type="text" class="input-text no-icon" v-model.number="day.worked_hours">
                        </div>
                        <div v-else class="input-box bg-white role" v-bind:class="[{has_error: day.worked_hours === '' || day.worked_hours > 24}, {'bg-gray': !day.is_editable}]">
                            <input :disabled="!day.is_editable" type="text" class="input-text no-icon" v-model.number="day.display_value">
                        </div>
                    </div>
                    <div class="input-group w-40">
                        <div class="label-header">
                            <label v-if="index == 0" class="label title">{{$t('clocking.night_hours')}}</label>
                        </div>
                        <div v-if="day.is_editable" class="input-box bg-white role" v-bind:class="[{has_error: day.night_hours === '' || day.worked_hours+day.night_hours > 24}, {'bg-gray': !day.is_editable}]">
                            <input @change="day.is_modified = 1" @keypress="onlyNumber"  type="text" class="input-text no-icon" v-model.number="day.night_hours">
                        </div>
                        <div v-else class="input-box bg-white role" v-bind:class="[{has_error: day.night_hours === ''}, , {'bg-gray': !day.is_editable}]">
                            <input :disabled="!day.is_editable" type="text" class="input-text no-icon" v-model.number="day.display_value">
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="form-submit role clocking">
                <button class="btn-tbf grey center" @click="cancelRole">
                    <div class="loader"></div>
                    <div class="text">{{$t('general.cancel')}}</div>
                </button>
                <div class="error-msg" v-show="error_message!=''">{{error_message}}</div>
                <button id='buttonUpdateClocking' class="btn-tbf blue center" @click="saveAction('update')">
                    <div class="loader"></div>
                    <div class="text">{{$t('general.update')}}</div>
                </button>
            </div>
        </div>
		<div id="submodalContainer" class="submodal-container" v-if="subModalShow">
			<div class="overlay-submodal"></div>
			<modal-confirm-close id="submodal_confirm_close" v-if="show_confirm_close" @close_modal="closeModalConfirmed" @cancel_close="cancelClose" />
		</div>
	</div>
</div>
</template>

<script type="text/javascript">
import IconClose from '../Icons/Close'
import ModalConfirmClose from '../Modals/ConfirmClose'
	export default {
		data() {
			return {
                name: '',
                description: '',
				loaded: false,
				subModalShow: false,
                show_confirm_close: false,
                error_message: '',
                clocking: {},
                name: ''
			};
		},
        props: {
            data: Object
        },
        components: {
            IconClose,
			ModalConfirmClose
        },
		mounted() {
            this.name = this.data.name
            this.clocking = this.data.model
			setTimeout(() => {
				this.loaded = true
				setTimeout(() => {
					$('.opacity-page').addClass('show')
				}, 0)
			}, 0)

			this.$root.$on("confirm_close", () => {
                if(this.checkInputs()){
                    this.subModalShow = true
                    this.show_confirm_close = true
                    setTimeout(() => {
                        document.getElementById('submodalContainer').classList.add('active')
                        setTimeout(() => {
                            document.getElementById('submodal_confirm_close').classList.add('active')
                        }, 200);
                    }, 0);
                }else{
                    this.$emit("close_modal");
                }
            });
		},
		methods: {
            onlyNumber ($event) {
                let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
                if (keyCode < 48 || keyCode > 57) { 
                    $event.preventDefault();
                }
            },
			cancelRole(){
				this.$emit("close_modal");
			},
            saveAction(type){
				var buttonName = `buttonUpdateClocking`
				var btnSubmit = document.getElementById(buttonName);
				btnSubmit.disabled = true

				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
                this.error_message = '';

				if (!$(".input-box.role").hasClass("has_error")){
					if(type == 'update'){
						this.updateClocking(this.clocking, btnSubmit, btnSubmitText, btnSubmitLoader)
					}
				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
            updateClocking(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.patch(`/payroll-timesheets/${this.data.id}`, {days: objData})
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
                            this.$emit("close_modal");
							this.$root.$emit('refresClockingIndex');
						}, 1000)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.add')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
			checkInputs(){
                var notEmpty = false

                if(this.name != ''){
                    notEmpty = true
                }

                return notEmpty
            },
            closeModalConfirmed(){
                this.$emit("close_modal");
            },
            cancelClose(){
                document.getElementById('submodal_confirm_close').classList.remove('active')
                setTimeout(() => {
                    document.getElementById('submodalContainer').classList.remove('active')
                    setTimeout(() => {
                        this.subModalShow = false;
                        this.show_confirm_close = false
                    }, 200);
                }, 200);
            },
		}
	};
</script>

<style lang="scss">
#modal_role {
    max-width: 500px;
    z-index: 10
}
div.input-box.bg-white.role > input.no-icon{
    padding: 0 45px 0 15px;
}
.form-submit.role > button{
    width: 50%;
}
.bg-gray {
    background: #f1f1f1;
}
.name-label {
    margin-bottom: 30px;
    font-weight: 500;
}
.clocking {
    max-height: 62vh;
    overflow-y: scroll;
    overflow-x: hidden;
}
.form-submit {
    &.clocking {
        border-top: 1px solid #f0f0f0;
    }
}
.form-section{
    &.clocking{
        .label{
            &.title{
                margin-bottom: 10px;
            }
        }
        &:last-child{
            padding-bottom: 20px;
        }
        .label-header{
            position:relative;
            height: 20px;
                .error-msg{
                    position: absolute;
                    bottom: -5px;
                    width: 100%;
                    text-align: right;
                    right: -50%;
            }
        }    
    }
}
</style>