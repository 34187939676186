<template>
	<div class="sidebar-settings-tbf">
		<div class="header">{{$t('navbar.contracts')}}</div>
		<div class="main-menu">
			<div class="item-menu">
				<router-link :to="{name: 'contracts' }">
					<span class="text">{{$t('contracts-navbar.contracts')}}</span>
					<div class="arrow"><icon-arrow /></div>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
    import IconArrow from '../Icons/Arrow.vue'

	export default {
		components: {
            IconArrow
		},
		data() {
			return {
				
			};
		},
		methods: {

		}
	};
</script>

<style lang="scss">
    @import "../../styles/templates/settings-template.scss";
</style>