<template>
<div class="modal-tbf modal-full-page">
    <div class="container-modal opacity-page" v-if="loaded">
        <div class="header-modal">
            <div class="title">
                {{data.update ? $t('salary.contract-data.edit_liquidation_data') : $t('salary.contract-data.complete_liquidation_data')}}
            </div>
            <div class="actions">
                <button class="btn-tbf white only-icon close-btn" @click="cancelLiquidation"><icon-close class="icon-close" /></button>
            </div>
        </div>
        <div class="info-content">
            <div class="container-content-modal">
                <div class="form-section">
                    <div class="input-group w-100">
                        <div class="label-header">
                            <label class="label">{{ $t('salary.contract-data.contract_end_date') }}*</label>
                            <div v-if="$v.liquidation.contract_end_date.$error" class="error-msg">{{ $t('validator.required') }}</div>
                        </div>
                        <div class="input-box" v-bind:class="{has_error: $v.liquidation.contract_end_date.$error}">
                            <div class="icon-left"><icon-calendar /></div>
                            <div class="icon-right" v-if="Object.keys(liquidation.contract_end_date).length" @click.stop="liquidation.contract_end_date = {},liquidation.last_day_of_activity = {}"><icon-close class="icon-clear" /></div>
                            <FunctionalCalendar
                                ref="CalendarDateOfEmployment"
                                v-model="liquidation.contract_end_date"
                                class="calendar-tbf"
                                :configs="calendarConfigsEndData"
                                disabled
                                @input="changeEndDate"
                            >
                            <template v-slot:datePickerInput="props">
                                <input
                                class="vfc-single-input custom-input-picker"
                                type="text"
                                :value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
                                v-if="Object.keys(liquidation.contract_end_date).length"/>
                                <div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('salary.contract-data.contract_end_date-ph') }}</div>
                            </template>
                            </FunctionalCalendar>
                        </div>
                    </div>
                    <div class="input-group w-100">
                        <div class="label-header">
                            <label class="label">{{ $t('salary.contract-data.last_day_of_activity') }}*</label>
                            <div v-if="$v.liquidation.last_day_of_activity.$error" class="error-msg">{{ $t('validator.required') }}</div>
                        </div>
                        <div class="input-box" v-bind:class="{has_error: $v.liquidation.last_day_of_activity.$error}">
                            <div class="icon-left"><icon-calendar /></div>
                            <div class="icon-right"  v-if="Object.keys(liquidation.last_day_of_activity).length" @click.stop="liquidation.last_day_of_activity = {}"><icon-close class="icon-clear" /></div>
                            <FunctionalCalendar
                                ref="CalendarDateOfEmployment"
                                v-model="liquidation.last_day_of_activity"
                                class="calendar-tbf"
                                :configs="calendarConfigLastDay"
                            >
                            <template v-slot:datePickerInput="props">
                                <input
                                class="vfc-single-input custom-input-picker"
                                type="text"
                                :value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
                                v-if="Object.keys(liquidation.last_day_of_activity).length"/>
                                <div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('salary.contract-data.last_day_of_activity-ph') }}</div>
                            </template>
                            </FunctionalCalendar>
                        </div>
                    </div>
                    <div class="input-group w-100">
                        <div class="label-header">
                            <label class="label">{{ $t('salary.contract-data.resignation_date') }}</label>
                        </div>
                        <div class="input-box">
                            <div class="icon-left"><icon-calendar /></div>
                            <div class="icon-right"  v-if="Object.keys(liquidation.resignation_date).length" @click.stop="liquidation.resignation_date = {}"><icon-close class="icon-clear" /></div>
                            <FunctionalCalendar
                                ref="CalendarDateOfEmployment"
                                v-model="liquidation.resignation_date"
                                class="calendar-tbf"
                                :configs="calendarConfigResignationDay"
                            >
                            <template v-slot:datePickerInput="props">
                                <input
                                class="vfc-single-input custom-input-picker"
                                type="text"
                                :value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
                                v-if="Object.keys(liquidation.resignation_date).length"/>
                                <div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('salary.contract-data.resignation_date-ph') }}</div>
                            </template>
                            </FunctionalCalendar>
                        </div>
                    </div>

					<div class="input-group w-100">
						<div class="label-header">
							<label class="label">{{ $t('salary.contract-data.liquidation_reason') }}</label>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-liquidation /></div>
							<div class="icon-right" v-if="liquidation.selectedReason != ''" @click.stop="liquidation.selectedReason = ''"><icon-close class="icon-clear" /></div>
							<multiselect 
								v-model="liquidation.selectedReason"
								v-bind:class="{populate: liquidation.selectedReason != ''}"
								class="select-tags-tbf"
								:options="liquidationReasons"
								:allow-empty="false"
								:show-labels="false"
                                :maxHeight="240"
								track-by="id" 
								label="name"
								>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t('salary.contract-data.liquidation_reason-ph') }}
									</span>
								</template>
								<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
							</multiselect>
						</div>
					</div>

                    <div class="input-group w-100">
                        <div class="input-box bg-white">
                            <textarea rows="3" :placeholder="$t('salary.contract-data.contract_observations')" v-model="liquidation.liquidation_observations"></textarea>
                        </div>
                    </div>
                </div>
                <div class="form-submit skill">
                    <button class="btn-tbf grey center" @click="cancelLiquidation">
                        <div class="loader"></div>
                        <div class="text">{{$t('general.cancel')}}</div>
                    </button>
					<div class="error-msg" v-show="error_message!=''">{{error_message}}</div>
                    <button id='buttonCreateLiquidation' class="btn-tbf blue center" @click="saveAction('create')">
                        <div class="loader"></div>
                        <div class="text">{{$t('general.save')}}</div>
                    </button>
                </div>
            </div>
        </div>
		 <div id="submodalContainer" class="submodal-container" v-if="subModalShow">
			<div class="overlay-submodal"></div>
			<modal-confirm-close id="submodal_confirm_close" v-if="show_confirm_close" @close_modal="closeModalConfirmed" @cancel_close="cancelClose" />
		</div>
	</div>
</div>
</template>

<script type="text/javascript">
import IconClose from '../Icons/Close'
import IconCalendar from '../Icons/Calendar'
import IconLiquidation from '../Icons/Liquidation'
import { required, requiredIf } from 'vuelidate/lib/validators'
import ModalConfirmClose from '../Modals/ConfirmClose'
	export default {
        props: {
            data: Object
        },
		data() {
			return {
                liquidationReasons: [],
                liquidation: {
                    first_day_of_activity: {},
                    last_day_of_activity: {},
                    contract_end_date: {},
                    resignation_date: {},
                    selectedReason: '',
                    liquidation_observations: ''
                },
                calendarConfigResignationDay: {
					dateFormat: 'yyyy-mm-dd',
					isDatePicker: true,
					isDateRange: false,
					isModal: true,
					isAutoCloseable: true,
					changeMonthFunction: true,
					changeYearFunction: true,
                    limits: {min: '', max : ''}
				},
                calendarConfigLastDay: {
					dateFormat: 'yyyy-mm-dd',
					isDatePicker: true,
					isDateRange: false,
					isModal: true,
					isAutoCloseable: true,
					limits: {min: '', max : ''}
				},
				calendarConfigsEndData: {
					dateFormat: 'yyyy-mm-dd',
					isDatePicker: true,
					isDateRange: false,
					isModal: true,
					isAutoCloseable: true,
					limits: {min: '', max : ''}
				},
				loaded: false,
				subModalShow: false,
                show_confirm_close: false,
                error_message: ''
			};
		},
        components: {
            IconClose,
			IconCalendar,
            IconLiquidation,
			ModalConfirmClose
        },
        validations: {
            liquidation: {
                last_day_of_activity: { 
                    required: requiredIf(function () {
                        return (Object.keys(this.liquidation.contract_end_date).length && this.liquidation.contract_end_date.selectedDate != null) || (Object.keys(this.liquidation.contract_end_date).length && this.liquidation.contract_end_date.selectedDate != null && !this.data.update) || (this.liquidation.last_day_of_activity.selectedDate == null  && !this.data.update)
                    }),
                },
                contract_end_date: {
                    required: requiredIf(function () {
                        return (Object.keys(this.liquidation.last_day_of_activity).length && this.liquidation.last_day_of_activity.selectedDate != null) || (Object.keys(this.liquidation.last_day_of_activity).length && this.liquidation.last_day_of_activity.selectedDate != null  && !this.data.update) || (this.liquidation.contract_end_date.selectedDate == null  && !this.data.update)
                    }),
                }
            }
		},
		mounted() {
            this.getSelectData();
            this.getLiquidationData();

			this.$root.$on("confirm_close", () => {
                if(this.checkInputs()){
                    this.subModalShow = true
                    this.show_confirm_close = true
                    setTimeout(() => {
                        document.getElementById('submodalContainer').classList.add('active')
                        setTimeout(() => {
                            document.getElementById('submodal_confirm_close').classList.add('active')
                        }, 200);
                    }, 0);
                }else{
                    this.$emit("close_modal");
                }
            });
		},
		methods: {
            async getSelectData(){
                await axios.get(`${this.$auth.user().instance.id}/contract-form-noms`)
				.then(({data}) => {
                    this.liquidationReasons = data.data.liquidation_reasons
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				}).finally(() => {
                    setTimeout(() => {
                        this.loaded = true
                        setTimeout(() => {
                            $('.opacity-page').addClass('show')
                        }, 0)
                    }, 0)
                })
            },
            async getLiquidationData() {
                await axios.get(`/contracts/${this.data.id}/edit`).then((data) => {
                    this.liquidation.first_day_of_activity = data.data.data.first_day_of_activity

                    this.calendarConfigsEndData.limits.min = this.liquidation.first_day_of_activity
                    this.calendarConfigLastDay.limits.min = this.liquidation.first_day_of_activity
                    this.calendarConfigResignationDay.limits.min = this.liquidation.first_day_of_activity

                    if(data.data.data.contract_end_date) {
                        this.liquidation.contract_end_date = {
                            selectedDate: data.data.data.contract_end_date,
                            multipleDateRange: [],
                            selectedDates: [],
                            selectedDateTime: false,
                            selectedDatesItem: "",
                            selectedHour: "00",
                            selectedMinute: "00",
                            dateRange: {end: '',start: ''}
                        }
                    }
                    if(data.data.data.last_day_of_activity) {
                        this.liquidation.last_day_of_activity = {
                            selectedDate: data.data.data.last_day_of_activity,
                            multipleDateRange: [],
                            selectedDates: [],
                            selectedDateTime: false,
                            selectedDatesItem: "",
                            selectedHour: "00",
                            selectedMinute: "00",
                            dateRange: {end: '',start: ''}
                        }
                    }
                    if(data.data.data.resignation_date) {
                        this.liquidation.resignation_date = {
                            selectedDate: data.data.data.resignation_date,
                            multipleDateRange: [],
                            selectedDates: [],
                            selectedDateTime: false,
                            selectedDatesItem: "",
                            selectedHour: "00",
                            selectedMinute: "00",
                            dateRange: {end: '',start: ''}
                        }
                    }
                    
                    this.liquidation.selectedReason = data.data.data.liquidation_reason_id ? this.liquidationReasons.find(el => el.id == data.data.data.liquidation_reason_id) : ''
                    this.liquidation.liquidation_observations = data.data.data.liquidation_observations
                }).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
            },
			changeEndDate() {
                if(this.liquidation.last_day_of_activity.selectedDate){
                    if(this.moment(this.liquidation.contract_end_date.selectedDate) < this.moment(this.liquidation.last_day_of_activity.selectedDate)) {
                        this.liquidation.last_day_of_activity = {}
                        this.calendarConfigLastDay.limits.max = this.liquidation.contract_end_date.selectedDate
                    } else {
                        this.calendarConfigLastDay.limits.max = this.liquidation.contract_end_date.selectedDate
                    }
                } else {
                    this.calendarConfigLastDay.limits.max = this.liquidation.contract_end_date.selectedDate
                }
                if(this.liquidation.resignation_date.selectedDate){
                    if(this.moment(this.liquidation.contract_end_date.selectedDate) < this.moment(this.liquidation.resignation_date.selectedDate)) {
                        this.liquidation.resignation_date = {}
                        this.calendarConfigResignationDay.limits.max = this.liquidation.contract_end_date.selectedDate
                    } else {
                        this.calendarConfigResignationDay.limits.max = this.liquidation.contract_end_date.selectedDate
                    }
                } else {
                    this.calendarConfigResignationDay.limits.max = this.liquidation.contract_end_date.selectedDate
                }
			},
			cancelLiquidation(){
				this.$emit("close_modal");
			},
            saveAction(type){
				var buttonName = `buttonCreateLiquidation`
				var btnSubmit = document.getElementById(buttonName);
				btnSubmit.disabled = true

				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
                this.error_message = '';

				this.$v.$touch()
				if(!this.$v.$invalid){
					var objData = {
                        first_day_of_activity: (Object.keys(this.liquidation.last_day_of_activity).length || Object.keys(this.liquidation.contract_end_date).length) ? this.liquidation.first_day_of_activity : '',
						last_day_of_activity: Object.keys(this.liquidation.last_day_of_activity).length ? this.liquidation.last_day_of_activity.selectedDate : '',
                        contract_end_date: Object.keys(this.liquidation.contract_end_date).length ? this.liquidation.contract_end_date.selectedDate : '',
                        resignation_date: Object.keys(this.liquidation.resignation_date).length && (Object.keys(this.liquidation.last_day_of_activity).length || Object.keys(this.liquidation.contract_end_date).length) ? this.liquidation.resignation_date.selectedDate : '',
                        liquidation_reason_id: (Object.keys(this.liquidation.last_day_of_activity).length || Object.keys(this.liquidation.contract_end_date).length) && this.liquidation.selectedReason ? this.liquidation.selectedReason.id : '',
						liquidation_observations: (Object.keys(this.liquidation.last_day_of_activity).length || Object.keys(this.liquidation.contract_end_date).length) ? this.liquidation.liquidation_observations : ''
					}
					if(type == 'create'){
						this.createLiquidation(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}
				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.save') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
            createLiquidation(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.patch(`/contracts/${this.data.id}/liquidation`, objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.$v.$reset()
						setTimeout(()=>{
                            this.$emit("close_modal");
							this.$root.$emit('updateShowPage');
						}, 1000)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.save')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
                            if(error.response.data.errors.popup) {
								this.$root.$emit('open_modal_over_modal', 'error', {data: error.response.data.errors.popup[0]}, false);
							}
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
			checkInputs(){
                var notEmpty = false

                if(this.name != ''){
                    notEmpty = true
                }

                return notEmpty
            },
            closeModalConfirmed(){
                this.$emit("close_modal");
            },
            cancelClose(){
                document.getElementById('submodal_confirm_close').classList.remove('active')
                setTimeout(() => {
                    document.getElementById('submodalContainer').classList.remove('active')
                    setTimeout(() => {
                        this.subModalShow = false;
                        this.show_confirm_close = false
                    }, 200);
                }, 200);
            },
		}
	};
</script>

<style lang="scss">
#modal_skill {
    max-width: 500px;
    z-index: 10
}
div.input-box.bg-white.skill > input.no-icon{
    padding: 0 45px 0 15px;
}
.form-submit.skill > button{
    width: 50%;
}
</style>