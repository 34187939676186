<template>
	<div class="sidebar-notifications">

		<div class="top">
			<h1>{{ $t('notifications.title') }}</h1>
			<div class="btn-tbf white only-icon close-btn" @click="closeModal"><icon-close /></div>
		</div>

		<div class="notification-header">
			<div class="notifications-type" v-bind:class="{active: active_tab == 'activity'}" @click="changeTab('activity')">{{ $t('notifications.activity') }}</div>
			<div class="notifications-type" v-bind:class="{active: active_tab == 'new'}" @click="changeTab('new')">{{ $t('notifications.new') }} ({{ countUnread }})</div>
		</div>
			
		<div class="notifications-content" v-bind:class="{center: (active_tab == 'activity' && Object.keys(activity_notifications).length == 0) || (active_tab == 'new' && Object.keys(notifications).length == 0)}" v-if="loaded">
			<!-- Activitatea companiei -->

			<div class="notification-list" v-if="active_tab == 'activity'">
				<div class="notification-box" :id="'notification-box-'+notification.id" v-for="notification in activity_notifications" v-bind:class="{'is-link': notification.need_link}" :key="`notification-activity-${notification.id}`">
					<div class="left-side" v-if="$resize && $mq.above(600)"><icon-arrow :id="'notification-' + notification.id" class="icon-arrow right"/></div>
					<div class="center" @click="notification.need_link ?  viewItem(notification.entity_slug, notification, 'activity') : ''">
						<img v-if="notification.avatar != null" :src="notification.avatar" class="img-responsabile">
						<div class="bell" v-else><bell /></div>
						<div class="content-notification">
							<p v-html="notification.name"></p>
							<span class="date">{{notification.created_at | moment("D MMMM YYYY")}}</span>
						</div>
					</div>
					<div class="right-side" v-if="$resize && $mq.above(600)">
					</div>
				</div>
				<infinite-loading :identifier="1" @infinite="infiniteNotificationsActivity"><div slot="no-more"></div><div slot="no-results"></div></infinite-loading>
			</div>

			<div class="no-notifications" v-if="Object.keys(activity_notifications).length == 0 && active_tab == 'activity'">
				<icon-large-bell class="icon"/>
				<p>{{ $t('notifications.all-good') }}</p>
				<span>{{ $t('notifications.no-notifications') }}</span>
			</div>

			<!-- Notificari click-uibile -->
			<div class="notification-list" v-if="active_tab == 'new'">
				<div class="notification-box" :id="'notification-box-'+notification.id" v-for="notification in notifications" v-bind:class="{'is-link': notification.need_link}" :key="`notification-${notification.id}`">
					<div class="left-side" v-if="$resize && $mq.above(600)"><icon-arrow :id="'notification-' + notification.id" class="icon-arrow right"/></div>
					<div class="center" @click="notification.need_link ?  viewItem(notification.entity_slug, notification, 'personal') : ''">
						<img v-if="notification.avatar != null" :src="notification.avatar" class="img-responsabile">
						<div class="bell" v-else><bell /></div>
						<div class="content-notification" v-bind:class="{new: !notification.seen}">
							<p v-html="notification.name"></p>
							<span class="date">{{notification.created_at | moment("D MMMM YYYY")}}</span>
						</div>
					</div>
					<div class="right-side" v-if="$resize && $mq.above(600)">
					</div>
				</div>
				<infinite-loading :identifier="2" @infinite="infiniteNotifications"><div slot="no-more"></div><div slot="no-results"></div></infinite-loading>
			</div>

			<div class="no-notifications" v-if="Object.keys(notifications).length == 0 && active_tab == 'new'">
				<icon-large-bell class="icon"/>
				<p>{{ $t('notifications.all-good') }}</p>
				<span>{{ $t('notifications.no-notifications') }}</span>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconClose from "../Icons/Close"
	import Bell from "../Icons/Bell"
	import IconArrow from '../Icons/Arrow'
	import IconEditDots from '../Icons/EditDots'
	import IconLargeBell from '../Icons/LargeBell'
	import InfiniteLoading from 'vue-infinite-loading'

	export default {
		data() {
			return {
				loaded: true,
				notifications: [],
				activity_notifications: [],
				countUnread: 0,
				// read_notifications: {},
				active_tab: 'new',
				view_more: 20,
				counter: 1,
				viewMoreUnread: 20,
				counterUnread: 1
			};
		},
		props:{

		},
		components: {
			InfiniteLoading,
			IconClose,
			Bell,
			IconArrow,
			IconEditDots,
			IconLargeBell
		},
		async mounted() {
			await this.getNotifications();
			await this.getActivityNotifications();

			this.$root.$emit('clear_notifications');

			this.$root.$on("updateNotificationsList", (parsedData) => {
				if(parsedData.for_activity){
					this.activity_notifications.unshift(parsedData);
				}
				if(parsedData.for_personal){
					this.notifications.unshift(parsedData);
				}
			});
		},
		methods: {
			async getNotifications(){
				await axios.get('/notification-list').then(({data}) => {
					this.notifications = data.data.unread
					this.countUnread = data.data.count_unread
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			async getActivityNotifications(){
				await axios.get('/notification-list?type=activity').then(({data}) => {
					this.activity_notifications = data.data.activity
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => { this.loaded = true })
			},
			infiniteNotifications($state2){
				axios.get('/notification-list', {params: {view_more: this.viewMoreUnread }}).then(({data}) => {
					if(data.data.unread.length){
						this.counterUnread++
						this.viewMoreUnread = 20 * this.counterUnread
						this.notifications.push(...data.data.unread)
						$state2.loaded()
					}else{
						$state2.complete()
					}
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			infiniteNotificationsActivity($state){
				axios.get('/notification-list', {params: {type: 'activity', view_more: this.view_more }})
				.then(({data}) => {
					if(data.data.activity.length){
						this.counter++
						this.view_more = 20 * this.counter
						this.activity_notifications.push(...data.data.activity)
						$state.loaded()
					}else{
						$state.complete()
					}
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			viewItem(itemSlug, notification, column){
				if(notification.frontend_type == 'procedures'){
					this.$router.push({ name: 'procedure-show', params: { slug: itemSlug }})
				}

				if(column == "personal"){ this.markAsRead(notification); }
				this.closeModal();
			},
			markAsRead(notification){
				axios.patch('/notifications/' + notification.id)
				.then(({data}) => {
					var contentNotification = document.querySelector(`#notification-box-${notification.id} .content-notification`)
					if(contentNotification){contentNotification.classList.remove('new')}
				});
			},
			closeModal(){
				this.$emit("close_modal");
			},
			changeTab(val) {
				this.active_tab = val;
			}
		}
	};
</script>