<template>
	<div class="navbar-tbf row-space-tbf">
		<div class="space-left">
			<div class="back-btn" @click="goRoute(navbarBack)" v-if="navbarBack">
				<icon-arrow-back />
			</div>
		</div>
		<div class="content">
			<div class="title">
				<h1>{{ navbarTitle }}</h1>
			</div>
			
			<div class="profile-items">
				<div class="next-webinar" @click="showModal('webinar_tbf')">
					<div class="label">{{ $t('navbar.webinar') }}</div>
					<div class="date" v-if="$resize && $mq.above(779)">{{ getTextForWebinar() }}</div>
				</div>

				<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" class="mission-vision item-navbar" :delay="{show: 100, hide: 0}" >
					<button class="btn-navbar only-icon" @click="showModal('mission_vision')">
						<div class="icon"><icon-superman /></div>
					</button>

					<template slot="popover">
						<div class="simple-text">{{ $t('company.show_mission_vision') }}</div>
					</template>
				</v-popover>

				<div class="item-navbar" v-if="$auth.ready() && $auth.user().instance && ['active', 'grace_period'].includes($auth.user().instance.status)">
					<button class="btn-navbar only-icon" @click="showModal('notifications')">
						<div class="icon active" v-if="notifications.length || $auth.user().new_software_updates"><icon-notifications-on /></div>
						<div class="icon" v-else><icon-notifications /></div>
					</button>
				</div>
				
				<div class="input-box navbar-dropdown-contracts">
					<div class="icon-left"><icon-calendar /></div>
					<multiselect
						v-model="date"
						v-bind:class="{populate: date != ''}"
						class="select-tags-tbf"
						:options="months"
						:allow-empty="false"
						:show-labels="false"
						track-by="date" 
						label="name"
						ref="multiselectref"
						@open="openAtSelectedItem"
						@input="changeDate"
						>
						<template slot="singleLabel" slot-scope="props">
							<!-- <img v-if="props.option.img" class="option__image contracts" :src="props.option.img"> -->
							<div v-if="props.option.img" class="icon-multiselect">
								<icon-lock-locked />
							</div>
							<span class="option__desc">
								<span class="option__title">{{ props.option.name }}</span>
							</span>
						</template>
						<template slot="option" slot-scope="props">
							<!-- <img v-if="props.option.img" class="option__image contracts" :src="props.option.img"> -->
							<div v-if="props.option.img" class="icon-multiselect">
								<icon-lock-locked />
							</div>
							<div class="option__desc">
								<span class="option__title">{{ props.option.name }}</span>
							</div>
						</template>
						<template slot="placeholder" slot-scope="props">
							<span class="text">
								{{ $t('salary.modification.active_from-ph') }}
							</span>
						</template>
						<template slot="noResult">{{ $t('create-user.no-results') }}</template>
						<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
					</multiselect>
				</div>
				<v-popover class="lock item-navbar" v-if="payroll_date.is_locked" offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" >
					<button class="btn-navbar only-icon" @click="lockUnlockMonth(false)">
						<div class="icon">
							<icon-lock-locked />
						</div>
					</button>
					<template slot="popover">
						<div class="simple-text">{{$t('payroll.unlock_month')}}</div>
					</template>
				</v-popover>
				<v-popover v-else class="lock item-navbar" offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" >
				<button class="btn-navbar only-icon" @click="lockUnlockMonth(true)">
					<div class="icon">
						<icon-lock-open />
					</div>
				</button>
				<template slot="popover">
						<div class="simple-text">{{$t('payroll.lock_month')}}</div>
					</template>
				</v-popover>
				<div class="digital-applications dropdown item-navbar" @click="getApplications">
					<div class="btn-navbar only-icon" ref="dropdownApplications" id="dropdownApplications" data-toggle="dropdown">
						<div class="icon"><icon-applications /></div>
					</div>

				<div class="dropdown-menu dropdown-menu-center" id="dropdownApplications" aria-labelledby="dropdownApplications">
					<template v-if="loadApplications">
						<div class="application-item">
							<a class="application" href="https://central.tbf.ro/dashboard" target="_blank">
								<div class="icon"><img src="/build/images/central.svg"></div>
								<div class="text">Central</div>
							</a>
						</div>
						<div v-for="app in applications.filter(el => el.is_active)" class="application-item">
							<a class="application" :href="app.application.fe_host" target="_blank">
								<div class="icon"><img :src="app.application.image"></div>
								<div class="text">{{app.application.name}}</div>
							</a>
						</div>

						<div class="title-application" v-if="applications.filter(el => !el.is_active).length">Fa-ti cont si pe </div>
						<div v-for="app in applications.filter(el => !el.is_active)" class="application-item">
							<a class="application" :href="app.application.fe_host" target="_blank">
								<div class="icon"><img :src="app.application.image"></div>
								<div class="text">{{app.application.name}}</div>
							</a>
						</div>
						
					</template>
					<template v-else>
						<loader-applications  v-for="index in 9" :key="index"/>
					</template>
					
					</div>
				</div>

				<div class="profile-navbar dropdown item-navbar" v-if="$auth.ready() && $auth.check()">
					<div class="has_notification" v-if="notifications.length"></div>

					<img :src="$auth.user().avatar" ref="dropdownUser" id="dropdownUser" data-toggle="dropdown" v-if="$auth.user().avatar">
					<div class="user-circle" ref="dropdownUser" id="dropdownUser" data-toggle="dropdown" v-else>
						<icon-user />
					</div>

					<div class="dropdown-menu dropdown-menu-right" id="dropdownApplications" aria-labelledby="dropdownApplications" v-if="$auth.ready()">
						<div class="user-data">
							<div class="name">{{ $auth.user().first_name + ' ' + $auth.user().last_name }}</div>
							<div class="email">{{ $auth.user().email }}</div>
						</div>
						<div class="dropdown-item">
							<router-link :to="{name: 'settings-profile' }">
								<div class="text">{{ $t('navbar.account') }}</div>
							</router-link>
						</div>
						<div class="dropdown-item" v-if="$auth.user().instance && ['active', 'grace_period'].includes($auth.user().instance.status)">
							<a href="https://help.tbf.ro" target="_blank">
								<div class="text">{{ $t('navbar.help_center') }}</div>
							</a>
						</div>
						<div class="dropdown-item" @click="showModal('contact')">
							<div class="text">{{ $t('navbar.contact') }}</div>
						</div>
						<div class="dropdown-item">
							<div class="text" @click="$auth.logout()" >{{ $t('navbar.log_out') }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="space-right"></div>
	</div>
</template>

<script>
    import IconCalendar from '../Icons/Calendar'
	import IconBell from '../Icons/Bell'
	import IconDotsSquare from '../Icons/DotsSquare'
	import IconUser from '../Icons/User'
	import IconArrowBack from '../Icons/ArrowLong'
	import IconLockLocked from '../Icons/LockLocked'
	import IconLockOpen from '../Icons/LockOpen'
	import IconSuperman from '../Icons/Superman'
	import LoaderApplications from '../PagesLoaders/ApplicationsNavbar.vue'
	import IconQuestionCircle from '../Icons/QuestionCircle'
	import IconNotifications from '../Icons/Notifications'
	import IconNotificationsOn from '../Icons/NotificationsOn'
	import IconApplications from '../Icons/Applications'

	export default {
		data() {
			return {
				navbarTitle: '',
				navbarBack: '',
				idOrSlug: '',
				applications: [],
				notifications: [],
				loadApplications: false,
                months: [],
                date: {},
                payroll_date: {},
                month_id: ""
			};
		},
		components: {
            IconCalendar,
			IconBell,
			IconDotsSquare,
			IconArrowBack,
			IconLockLocked,
			IconLockOpen,
			IconUser,
			IconSuperman,
			LoaderApplications,
			IconQuestionCircle,
			IconApplications,
			IconNotifications,
			IconNotificationsOn
		},
		async mounted() {
            await this.getMonths()
            await this.getUserPayroll()
            await this.$auth.load().then(() => {
            	if(this.$auth.check() && this.$cookies.get('websocket_notifications') != 'start'){
            		this.initWebSocket()
            	}
            });

			this.$root.$on("navbar_title", (title, back = false, description = false, idOrSlug = false) => {
				this.navbarTitle = title
				this.navbarBack = back
				this.idOrSlug = idOrSlug
			});

			this.$root.$on("clear_notifications", () => {
				this.notifications = []
			});
		},
		methods: {
			openAtSelectedItem() {
				setTimeout(() => {
					var elmnt = document.getElementsByClassName("multiselect__option--selected")[0]
					elmnt.scrollIntoView({behavior: "auto", block: "center", inline: "nearest"});
				}, 20);
			},

			async getMonths() {
				await axios.get(`${this.$auth.user().instance.id}/months`)
				.then(({data}) => {
                    this.months = data.data.map(el => {
						return {date: el.date, name: el.name, month: el.month, year: el.year, img: el.is_locked ? '/build/images/lock.png' : null}
					})
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},

			async lockUnlockMonth(data) {
				let objData = {}
				objData.month = this.payroll_date.month
				objData.year = this.payroll_date.year
				objData.is_locked = data
				await axios.post( `/month-locks`, objData)
				.then(async({data}) => {
					await this.getMonths()
					await this.getUserPayroll();
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},

			async getUserPayroll() {
				await axios.get('/user-payroll-currents')
				.then(({data}) => {
					this.payroll_date = {};
					this.month_id = data.data.id
					this.date = this.months.find(el => el.year == data.data.year && el.month == data.data.month)
					this.payroll_date = data.data
					setTimeout(() => {
						this.$root.$emit("refreshSalaryIndex", data.data);
						this.$root.$emit("updatePayrollData", data.data);
					}, 200);
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},

            changeDate(date) {
                let objData = {};
				console.log(date)
				objData.month = date.month
				objData.year = date.year
				objData.is_locked = this.payroll_date.is_locked
                this.sendUserPayroll(objData)
            },

            async sendUserPayroll(objData) {
				await axios.patch(`/user-payroll-currents/${this.month_id}`, objData)
				.then(({data}) => {
					this.getUserPayroll()
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},

			getTextForWebinar(){
				let date = ''
				let currentHour = moment(moment().tz("Europe/Bucharest").format('YYYY-MM-DD HH:mm'));
				let webinarEndHour = moment('11:00', 'HH:mm').tz("Europe/Bucharest");

				var webinarStartHour = moment.tz("Europe/Bucharest").hours(10).startOf('hour');
				var localtz = moment.tz.guess()
				var dateClone = webinarStartHour.clone().tz(localtz)
				var formatDate = moment(dateClone).format('HH:mm')

				if(moment().isoWeekday() == 2 && currentHour.isAfter(webinarEndHour)) {
					date = `${this.$t('calendar.long_days.thursday')} ${this.$t('webinar_tbf.time', {hour: formatDate} )}`
				} else if(moment().isoWeekday() == 4 && currentHour.isAfter(webinarEndHour)) {
					date = `${this.$t('calendar.long_days.tuesday')} ${this.$t('webinar_tbf.time', {hour: formatDate} )}`
				} else if((moment().isoWeekday() == 2 || moment().isoWeekday() == 4) && currentHour.isBefore(webinarEndHour)) {
					date = `${this.$t('webinar_tbf.today')} ${this.$t('webinar_tbf.time', {hour: formatDate} )}`
				} else if(moment().isoWeekday() == 1 || moment().isoWeekday() == 3) {
					date = `${this.$t('webinar_tbf.tomorrow')} ${this.$t('webinar_tbf.time', {hour: formatDate} )}`
				} else if(moment().isoWeekday() > 4 || moment().isoWeekday() < 2) {
					date = `${this.$t('calendar.long_days.tuesday')} ${this.$t('webinar_tbf.time', {hour: formatDate} )}`
				} else if(moment().isoWeekday() > 2 && moment().isoWeekday() < 4) {
					date = `${this.$t('calendar.long_days.thursday')} ${this.$t('webinar_tbf.time', {hour: formatDate} )}`
				}

				return date;
			},
            
			getApplications() {
				axios.get('/user-applications').then(({data}) => {
					this.applications = data.data;
					this.loadApplications = true;
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				});
			},
			initWebSocket() {
				this.socket = new WebSocket(process.env.VUE_APP_WSS_LINK)
				this.socket.onopen = event => {
					this.$cookies.set('websocket_notifications', 'start')

					// check in to websocket
					this.socket.send(JSON.stringify({
						'user_id': this.$auth.user().id
					}));
				};

				// when receiving a message
				this.socket.onmessage = ({data}) => {
					const parsedData = JSON.parse(data);

					this.notifications.unshift(parsedData)
					this.$root.$emit('updateNotificationsList', parsedData);
				};

				this.socket.onerror = error => {
					console.log(`Websocket error`);
				};

				this.socket.onclose = event => {
					this.$cookies.set('websocket_notifications', 'close')

					if (event.wasClean) {
						console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
					} else {
						// e.g. server process killed or network down
						// event.code is usually 1006 in this case
						console.log('[close] Connection died');

						setTimeout(() => {
							this.initWebSocket();
						}, 600000);
					}
				};
			},
			goRoute(route){
				this.idOrSlug ? this.$router.push({ name: route, params: {slug: this.idOrSlug}}) : this.$router.push({ name: route})
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			}
		}
	};
</script>