<template>
<div class="modal-tbf modal-full-page">
    <div class="container-modal opacity-page" v-if="loaded">
        <div class="header-modal">
            <div class="title">
                {{$t('statements.title') + ' ' + data.model.first_name + ' ' + data.model.last_name}}
            </div>
            <div class="actions">
                <button class="btn-tbf white only-icon close-btn" @click="cancelMedical"><icon-close class="icon-close" /></button>
            </div>
        </div>

        <div class="info-content">
            <div class="container-content-modal">
                <div class="form-section">
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
                        <div class="label-header">
							<label class="label">{{ $t('statements.date_contract') }}</label>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-calendar /></div>
							<FunctionalCalendar
								ref="CalendarDateOfEmployment"
								v-model="statement_data"
								class="calendar-tbf"
								:configs="calendarConfigs"
							>
							<template v-slot:datePickerInput="props">
								<input
								class="vfc-single-input custom-input-picker"
								type="text"
								:value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
								v-if="Object.keys(statement_data).length"/>
								<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('statements.date_contract-ph') }}</div>
							</template>
							</FunctionalCalendar>
						</div>
                    </div>
                    <div class="input-group w-100 files" @click="downloadEmployeeStatement">
                        <div class="download-section no-border">
                            <div class="statement-icon"><icon-docx /></div>
                            <div class="statement-text">{{$t('statements.employee-statement')}}</div>
                            <div class="download-icon"><icon-download /></div>
                        </div>
                    </div>
                    <div class="input-group w-100 files" @click="downloadIncomeStatement">
                        <div class="download-section no-border">
                            <div class="statement-icon"><icon-docx /></div>
                            <div class="statement-text">{{$t('statements.income-statement')}}</div>
                            <div class="download-icon"><icon-download /></div>
                        </div>
                    </div>
                    <div class="input-group w-100 files" @click="downloadCmBaseStatement">
                        <div class="download-section no-border">
                            <div class="statement-icon"><icon-docx /></div>
                            <div class="statement-text">{{$t('statements.cm-base-statement')}}</div>
                            <div class="download-icon"><icon-download /></div>
                        </div>
                    </div>
                    <div class="input-group w-100 files" @click="downloadCmStatement">
                        <div class="download-section">
                            <div class="statement-icon"><icon-docx /></div>
                            <div class="statement-text">{{$t('statements.cm-statement')}}</div>
                            <div class="download-icon"><icon-download /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
		<div id="submodalContainer" class="submodal-container" v-if="subModalShow">
			<div class="overlay-submodal"></div>
			<modal-confirm-close id="submodal_confirm_close" v-if="show_confirm_close" @close_modal="closeModalConfirmed" @cancel_close="cancelClose" />
		</div>
	</div>
</div>
</template>

<script type="text/javascript">
import IconClose from '../Icons/Close'
import IconDocx from '../Icons/Docx'
import IconDownload from '../Icons/FileDownload'
import IconCalendar from '../Icons/Calendar'
import ModalConfirmClose from '../Modals/ConfirmClose'
	export default {
		data() {
			return {
				loaded: false,
				subModalShow: false,
                show_confirm_close: false,
                calendarConfigs: {
					dateFormat: 'yyyy-mm-dd',
					isDatePicker: true,
					isDateRange: false,
					isModal: true,
					isAutoCloseable: true,
					changeMonthFunction: true,
					changeYearFunction: true
				},
                statement_data: {
                    selectedDate: moment().format("YYYY-MM-DD"),
                    multipleDateRange: [],
                    selectedDates: [],
                    selectedDateTime: false,
                    selectedDatesItem: "",
                    selectedHour: "00",
                    selectedMinute: "00",
                    dateRange: {end: '',start: ''}
                },
                error_message: ''
			};
		},
        components: {
            IconClose,
            IconDocx,
            IconDownload,
            IconCalendar,
			ModalConfirmClose
        },
        props: {
            data: Object
        },
		mounted() {
			setTimeout(() => {
				this.loaded = true
				setTimeout(() => {
					$('.opacity-page').addClass('show')
				}, 0)
			}, 0)

			this.$root.$on("confirm_close", () => {
                this.$emit("close_modal");
            });
		},
		methods: {
			cancelMedical(){
				this.$emit("close_modal");
			},
            closeModalConfirmed(){
                this.$emit("close_modal");
            },
            downloadEmployeeStatement() {
				axios.get(`${this.$auth.user().instance.id}/payroll-reports/export/docx/employee-statement?contract_id=${this.data.model.id}&date=${this.statement_data.selectedDate}`, 
				{
                	responseType: 'blob'
				})
				.then((response) => {
                    const file = new Blob([response.data], {type: 'application/docx'});
                    let fileURL = window.URL.createObjectURL(file);

					let fileName = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
					let fileLink = document.createElement('a');
					fileLink.href = fileURL;
					fileLink.setAttribute( 'download', fileName );
					document.body.appendChild(fileLink);

					fileLink.click();
				}).catch(async(response) => {
                    const isJsonBlob = (data) => data instanceof Blob && data.type === "application/json";
                    const responseData = isJsonBlob(response.response?.data) ? await (response.response?.data)?.text() : response.response?.data || {};
                    const responseJson = (typeof responseData === "string") ? JSON.parse(responseData) : responseData;
                    
                    if(responseJson.errors.popup) {

                        console.log('popup')
                        this.$root.$emit('open_modal_over_modal', 'error', {data: responseJson.errors.popup[0]}, false);
                    }
				})
            },
            downloadIncomeStatement() {
                axios.get(`${this.$auth.user().instance.id}/payroll-reports/export/docx/income-statement?contract_id=${this.data.model.id}&date=${this.statement_data.selectedDate}`, 
				{
                	responseType: 'blob'
				})
				.then((response) => {
                    const file = new Blob([response.data], {type: 'application/docx'});
                    let fileURL = window.URL.createObjectURL(file);

					let fileName = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
					let fileLink = document.createElement('a');
					fileLink.href = fileURL;
					fileLink.setAttribute( 'download', fileName );
					document.body.appendChild(fileLink);

					fileLink.click();
				}).catch(async(response) => {
                    const isJsonBlob = (data) => data instanceof Blob && data.type === "application/json";
                    const responseData = isJsonBlob(response.response?.data) ? await (response.response?.data)?.text() : response.response?.data || {};
                    const responseJson = (typeof responseData === "string") ? JSON.parse(responseData) : responseData;
                    
                    if(responseJson.errors.popup) {
                        this.$root.$emit('open_modal_over_modal', 'error', {data: responseJson.errors.popup[0]}, false);
                    }
				})
            },
            downloadCmBaseStatement() {
                axios.get(`${this.$auth.user().instance.id}/payroll-reports/export/docx/cm-base-statement?contract_id=${this.data.model.id}&date=${this.statement_data.selectedDate}`, 
				{
                	responseType: 'blob'
				})
				.then((response) => {
                    const file = new Blob([response.data], {type: 'application/docx'});
                    let fileURL = window.URL.createObjectURL(file);

					let fileName = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
					let fileLink = document.createElement('a');
					fileLink.href = fileURL;
					fileLink.setAttribute( 'download', fileName );
					document.body.appendChild(fileLink);

					fileLink.click();
				}).catch(async(response) => {
                    const isJsonBlob = (data) => data instanceof Blob && data.type === "application/json";
                    const responseData = isJsonBlob(response.response?.data) ? await (response.response?.data)?.text() : response.response?.data || {};
                    const responseJson = (typeof responseData === "string") ? JSON.parse(responseData) : responseData;
                    
                    if(responseJson.errors.popup) {
                        this.$root.$emit('open_modal_over_modal', 'error', {data: responseJson.errors.popup[0]}, false);
                    }
				})
            },
            downloadCmStatement() {
                axios.get(`${this.$auth.user().instance.id}/payroll-reports/export/docx/cm-statement?contract_id=${this.data.model.id}&date=${this.statement_data.selectedDate}`, 
				{
                	responseType: 'blob'
				})
				.then((response) => {
                    const file = new Blob([response.data], {type: 'application/docx'});
                    let fileURL = window.URL.createObjectURL(file);

					let fileName = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
					let fileLink = document.createElement('a');
					fileLink.href = fileURL;
					fileLink.setAttribute( 'download', fileName );
					document.body.appendChild(fileLink);

					fileLink.click();
				}).catch(async(response) => {
                    const isJsonBlob = (data) => data instanceof Blob && data.type === "application/json";
                    const responseData = isJsonBlob(response.response?.data) ? await (response.response?.data)?.text() : response.response?.data || {};
                    const responseJson = (typeof responseData === "string") ? JSON.parse(responseData) : responseData;
                    
                    if(responseJson.errors.popup) {
                        this.$root.$emit('open_modal_over_modal', 'error', {data: responseJson.errors.popup[0]}, false);
                    }
				})
            },
            cancelClose(){
                document.getElementById('submodal_confirm_close').classList.remove('active')
                setTimeout(() => {
                    document.getElementById('submodalContainer').classList.remove('active')
                    setTimeout(() => {
                        this.subModalShow = false;
                        this.show_confirm_close = false
                    }, 200);
                }, 200);
            },
		}
	};
</script>

<style lang="scss">
#modal_role {
    max-width: 500px;
    z-index: 10
}
div.input-box.bg-white.role > input.no-icon{
    padding: 0 45px 0 15px;
}
.form-submit.role > button{
    width: 50%;
}
.input-group {
    &.files {
         margin-bottom: 0 !important;
    }
}
.download-section{
    cursor: pointer;
    display: flex;
    border: 1px solid #e5e7ef;
    height: 60px;
    &.no-border {
        border-bottom: none;
    }
    &:hover{
        background: #e5e7ef;
        border: 1px solid#AFB1B8;
        .statement-icon,
        .download-icon {
            svg {
                .fill-color {
                    fill: black;
                }
            }
        }
        // &.no-border {
        //     border-bottom: none;
        // }
    }
    .statement-text {
        width: 100%;
        align-self: center;
    }
    .statement-icon,
    .download-icon {
        width: 60px;
        align-self: center;
        svg {
            width: 40px;
            height: 40px;
            .fill-color {
                fill: #AFB1B8;
            }
        }
    }
    .download-icon {
        display: flex;
        justify-content: flex-end;
    }
    .statement-icon {
        display: flex;
        justify-content: flex-start;
        svg {
            width: 40px;
            height: 40px;
            .fill-color {
                fill: #7a96f9;
            }
        }
    }
}
</style>