<template>
	<div class="container-content-modal">
		<div class="list-packages">
			<div class="item-package" v-for="subPackage,index in packagesList.filter(el => el.package_bundle == 'Single')">
				<div class="box" v-bind:class="{active: subPackage.package_id == selectedPackage.package_id, 'error-selected': $v.selectedPackage.$error}" @click="selectPlan(subPackage)">
					<div class="header">
						<div class="title">{{subPackage.package_name}}</div>
						<div class="selected-check"><icon-check /></div>
					</div>
					<div class="price-box">
						<div class="value"><span class="old-price" v-if="subPackage.old_price_in_euro != subPackage.price_in_euro">{{ subPackage.old_price_in_euro }}</span> {{ newPrices.find((el) => el.package_id == subPackage.package_id) ? newPrices.find((el) => el.package_id == subPackage.package_id).price + ' EUR' : subPackage.price_in_euro + ' EUR' }}</div>
						<div class="period">/ {{ $t('general.monthly') }}</div>
					</div>
					<div class="list-features">
						<div class="item-feature" v-for="textFe in subPackage.includes">
							<div class="text">{{ textFe }}</div>
							<div class="icon-square"><icon-check /></div>
						</div>
					</div>
					<div class="action">
						<button class="btn-tbf" v-bind:class="[subPackage.package_id == selectedPackage.package_id ? 'blue' : 'white']" @click="selectPlan(subPackage)">
							<span class="text">{{ $t('subscription.select') }}</span>
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="form-section">
			<!-- Date facturare -->
			<div class="input-group w-mob-100 w-50">
				<div class="personal-choice" v-bind:class="{active: selectedTypePerson == 'company'}" @click="changeTypePerson('company')">
					<div class="type">
						<span class="title">{{ $t('subscription.company_title') }}</span>
						<span>{{ $t('subscription.team') }}</span>
					</div>
					<div class="checkboxes-group">
						<label class="checkbox-tbf">
							<input type="radio" value="company" v-model="selectedTypePerson" hidden>
							<span class="checkmark"></span>
						</label>					
					</div>
				</div>
			</div>
			<div class="input-group w-mob-100 w-50">
				<div class="personal-choice" v-bind:class="{active: selectedTypePerson == 'individual'}" @click="changeTypePerson('individual')">
					<div class="type">
						<span class="title">{{ $t('subscription.individual') }}</span>
						<span>{{ $t('subscription.personal') }}</span>
					</div>
					<div class="checkboxes-group">
						<label class="checkbox-tbf">
							<input type="radio" value="individual" v-model="selectedTypePerson" hidden>
							<span class="checkmark"></span>
						</label>					
					</div>
				</div>
			</div>

			<div class="input-group w-mob-100 w-50" v-if="selectedTypePerson == 'individual'">
				<div class="label-header">
					<label class="label">{{ $t('subscription.complet_name') }}</label>
				</div>
				<div class="input-box" v-bind:class="{has_error: $v.user.complet_name.$error, complete: user.complet_name != '' && !$v.user.complet_name.$error}">
					<div class="icon-left"><icon-people /></div>
					<div class="icon-right" v-if="user.complet_name != ''" @click.stop="user.complet_name = ''"><icon-close /></div>
					<input type="text" :placeholder="$t('subscription.complet_name')" class="input-text" v-model="user.complet_name" ref="first_input">
				</div>
			</div>

			<div class="input-group w-mob-100 w-50" v-if="selectedTypePerson == 'individual'">
				<div class="label-header">
					<label class="label">{{$t('subscription.cnp')}}</label>
				</div>
				<div class="input-box" v-bind:class="{has_error: $v.user.cnp.$error, complete: user.cnp != '' && !$v.user.cnp.$error }">
					<div class="icon-left"><icon-cnp /></div>
					<div class="icon-right" v-if="user.cnp != ''" @click.stop="user.cnp = ''"><icon-close /></div>
					<input type="text" :placeholder="$t('subscription.cnp')" class="input-text" v-model="user.cnp">
				</div>
			</div>

			<div class="input-group w-mob-100 w-50" v-if="selectedTypePerson == 'company'">
				<div class="label-header">
					<label class="label">{{$t('subscription.company')}}</label>
				</div>
				<div class="input-box" v-bind:class="{has_error: $v.user.company_name.$error, complete: user.company_name != '' && !$v.user.company_name.$error}">
					<div class="icon-left"><icon-building /></div>
					<div class="icon-right" v-if="user.company_name != ''" @click.stop="user.company_name = ''"><icon-close /></div>
					<input type="text" :placeholder="$t('subscription.company')" class="input-text" v-model="user.company_name">
				</div>
			</div>

			<div class="input-group w-mob-100 w-50" v-if="selectedTypePerson == 'company'">
				<div class="label-header">
					<label class="label">{{ $t('subscription.cui') }}</label>
					<div class="error-msg" v-if="invalidCui">{{ $t('subscription.cui_invalid') }}</div>
				</div>
				<div class="input-box" v-bind:class="{has_error: $v.user.company_cui.$error, complete: user.company_cui != '' && !$v.user.company_cui.$error}">
					<div class="icon-left"><icon-cui /></div>
					<div class="icon-right" v-if="user.company_cui != ''" @click.stop="user.company_cui = ''"><icon-close /></div>
					<input type="text" :placeholder="$t('subscription.cui')" class="input-text" v-model="user.company_cui">
				</div>
			</div>

			<div class="input-group w-50">
				<div class="label-header">
					<label class="label">{{ $t('subscription.coupon.label') }}</label>
				</div>
				<div class="input-with-btn">
					<div class="input-box">
						<div class="icon-left"><icon-password /></div>
						<input type="text" v-model="user.coupon_code" :placeholder="$t('subscription.coupon.coupon-placeholder')" class="input-text">
					</div>
					<div class="btn-right">
						<button id="buttonCode" class="btn-tbf grey center" @click="checkCode">
							<div class="loader"></div>
							<div class="text">{{ $t('subscription.coupon.apply') }}</div>
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="submit-form submit-with-check">
			<div class="input-group checkboxes-group terms-condition">
				<label class="checkbox-tbf" v-bind:class="{'error': $v.terms_and_conditions.$error}">
					<span class="text-checkbox" href="https://tbf.ro/termeni-si-conditii-tbf-digital/" v-html="$t('subscription.terms', {terms_link: 'https://tbf.ro/termeni-si-conditii-tbf-digital/'})">
					</span>
					<input type="checkbox" value="1" v-model="terms_and_conditions">
					<span class="checkmark"></span>
				</label>						
			</div>
			<div class="submit-button-box">
				<button id="buttonNextStep" class="btn-tbf blue center" @click="goNextStep">
					<div class="loader"></div>
					<div class="text">{{$t('register.next_step')}}</div>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
	import IconClose from "../Icons/Close"
	import IconPeople from "../Icons/People"
	import IconCnp from '../Icons/Cnp'
	import IconBuilding	from '../Icons/Building'
	import IconCui	from '../Icons/Cui'
	import IconCheck from '../Icons/Check'
	import IconPassword from '../Icons/Password'
	import { required, minLength, requiredIf, sameAs } from 'vuelidate/lib/validators'

	export default {
		data(){
			return {
				optionsCountry: [],
				value_country: '',
				selectedTypePerson: 'company',
				invalidCui: '',
				terms_and_conditions: '',
				selectedPackage: {package_id: 0},
				packagesList: [],
				newPrices: []
			}
		},
		props:{
			user: Object
		},
		components: {
			IconClose,
			IconPeople,
			IconBuilding,
			IconCnp,
			IconCui,
			IconCheck,
			IconPassword
		},
		validations: {
			selectedPackage: {required},
			selectedTypePerson: {required},
			terms_and_conditions: { sameAs: val => val === true },
			user: {
				company_name: {
			        requiredIf: requiredIf((user) => {
			          return user.has_company === 1 || user.has_company === '' 
			        })
			    },
				company_cui: {
					requiredIf: requiredIf((user) => {
			          return user.has_company === 1 || user.has_company === '' 
			        })
				},
				complet_name: {
					requiredIf: requiredIf((user) => {
			          return user.has_company === 0
			        })
				},
				cnp: {
					requiredIf: requiredIf((user) => {
			          return user.has_company === 0 
			        }), 
					minLength: minLength(13)
				},
			}
		},
		async mounted() {
			this.user.complet_name = this.$auth.user().last_name + ' ' + this.$auth.user().first_name
			await this.getPackages();
		},
		methods: {
			async getPackages(){
				await axios.get('packages').then(({data}) => {
					this.packagesList = data.data;
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				});
			},
			async getCountries(){
				await axios.get('/countries').then(({data}) => {
					this.optionsCountry = data.data
					this.value_country = this.optionsCountry.find(el => el.iso == this.user.language.toUpperCase())
					this.user.country = this.value_country
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			goNextStep(){
				var buttonName = 'buttonNextStep'
                var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

                btnSubmit.disabled = true

				this.$v.$touch()
				if(this.$v.$invalid){
					btnSubmit.disabled = false
				}else{
					btnSubmit.classList.add('loading')
					btnSubmitLoader.classList.add('onProgress')
					btnSubmitText.innerHTML = this.$t('btn-submit.loading')
					if(this.user.has_company){
						this.checkVatNumber(btnSubmit, btnSubmitLoader, btnSubmitText)
					}else{
						this.submitRegister(btnSubmit, btnSubmitLoader, btnSubmitText)
					}
				}
			},
			checkCode(){
	            var buttonName = 'buttonCode'
	            var btnSubmit = document.getElementById(buttonName);
	            btnSubmit.disabled = true

	            var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
	            var btnSubmitText = document.querySelector(`#${buttonName} .text`)

	            btnSubmit.classList.add('loading')
	            btnSubmitLoader.classList.add('onProgress')
	            btnSubmitText.innerHTML = this.$t('btn-submit.loading')

	            this.errorCoupon = false

	            if(this.user.coupon_code != ''){
		            axios.post('validate-coupon', {coupon : this.user.coupon_code})
		            .then((data) => {
		                if(data.data.is_valid){
		                    btnSubmitLoader.classList.add('finish')
		                    setTimeout(()=>{
		                        btnSubmitText.innerHTML = this.$t('btn-submit.success')
		                        btnSubmit.classList.add('completed')
		                        btnSubmitLoader.classList.remove('onProgress', 'finish')
		                        btnSubmit.classList.remove('loading')
		                        setTimeout(()=>{
		                            btnSubmit.classList.remove('completed')
		                            btnSubmitText.innerHTML = this.$t('subscription.coupon.apply-success')
		                            this.newPrices = data.data.values
		                        }, 1000)
		                    }, 300)
		                }else{
		                    btnSubmitLoader.classList.add('finish')
		                    setTimeout(()=>{
		                        btnSubmitText.innerHTML = this.$t('subscription.coupon.apply-error')
		                        btnSubmit.classList.add('error')
		                        btnSubmitLoader.classList.remove('onProgress', 'finish')
		                        btnSubmit.classList.remove('loading')
		                        setTimeout(()=>{
		                            btnSubmit.classList.remove('error')
		                            btnSubmitText.innerHTML = this.$t('subscription.coupon.apply')
		                            this.errorCoupon = true
		                            this.newPrices = []
		                            this.user.coupon_code = null;
		                        }, 1000)
		                    }, 300)
		                }
		            })
		            .catch(error => {
		                btnSubmitLoader.classList.add('finish')
		                setTimeout(()=>{
		                    btnSubmitText.innerHTML = this.$t('subscription.coupon.apply-error')
		                    btnSubmit.classList.add('error')
		                    btnSubmitLoader.classList.remove('onProgress', 'finish')
		                    btnSubmit.classList.remove('loading')
		                    setTimeout(()=>{
		                        btnSubmit.classList.remove('error')
		                        btnSubmitText.innerHTML = this.$t('subscription.coupon.apply')
		                        this.errorCoupon = true
		                        this.newPrices = []
		                        this.user.coupon_code = null;
								if(error.response.status == 500){
									this.error_message = this.$t('error.500')
								}
		                    }, 1000)
		                }, 300)
		            })
		            .finally(() => {
		                setTimeout(()=>{
		                    btnSubmit.disabled = false
		                }, 1500)
		            })
	            }else{
	            	btnSubmitLoader.classList.add('finish')
	            	setTimeout(()=>{
	            		btnSubmitText.innerHTML = this.$t('subscription.coupon.apply-error')
	            		btnSubmit.classList.add('error')
	            		btnSubmitLoader.classList.remove('onProgress', 'finish')
	            		btnSubmit.classList.remove('loading')
	            		setTimeout(()=>{
	            			btnSubmit.classList.remove('error')
	            			btnSubmitText.innerHTML = this.$t('subscription.coupon.apply')
	            			this.errorCoupon = true
		                    btnSubmit.disabled = false
	            		}, 1000)
	            	}, 300)
	            }
	        },
			submitRegister(btnSubmit, btnSubmitLoader, btnSubmitText){
				this.invalidCui = false;

				let formData = {
					country: 'RO',
					has_company: this.user.has_company,
					agree_with_terms: this.terms_and_conditions,
					package_token: this.selectedPackage.token,
					coupon: this.user.coupon_code
				}
				if(this.user.has_company){
					formData.company_name = this.user.company_name
					formData.cui = this.user.formatedCui
				}else{
					formData.name = this.user.complet_name
					formData.cnp = this.user.cnp
				}

				axios.post('/instances/store', formData).then((result) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
							this.$auth.fetch().then(() => { this.$router.push({ name: 'users'}) })
							this.$emit('change_slide', 'step_payment', {selectedPackage: this.selectedPackage, newPrices: this.newPrices});
						}, 1000)
					}, 300)
				})
				.catch(error => { 
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('register.next_step')
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
			selectCountry(value, id){
				this.user.country = value
			},
			changeTypePerson(type) {
				this.selectedTypePerson = type;
				if(this.selectedTypePerson == 'company'){
					this.user.has_company = 1;
				}else{
					this.user.has_company = 0;
				}
			},
			selectPlan(packageItem){
                this.selectedPackage = packageItem
				this.user.package_token = packageItem.token;
			},
			checkVatNumber(btnSubmit, btnSubmitLoader, btnSubmitText){
				var cui_number = this.user.company_cui.replace(/[^0-9]/g,'')
				var cui_country = this.user.company_cui.replace(/[^a-zA-Z]+/g, '').toUpperCase()

				if(!cui_country.length){
					cui_country = 'RO'
				}

				this.user.formatedCui = `${cui_country}${cui_number}`
				setTimeout(() => this.submitRegister(btnSubmit, btnSubmitLoader, btnSubmitText), 100)
			}
		}
	}
</script>

<style lang="scss">
	.list-packages{
		display: flex;
		margin-left: -15px;
		margin-right: -15px;
		margin-bottom: 30px;
		@media(max-width: 600px){
			flex-wrap: wrap;
		}
		.item-package{
			padding: 0 15px;
			flex: 1 1 0;
			@media(max-width: 600px){
				flex: 1 1 100%;
				&:first-child{
					margin-bottom: 30px;
				}
			}
			.box{
				border-radius: 18px;
				border: 2px solid $borderColor;
				background: #FFF;
				overflow: hidden;
				height: 100%;
				display: flex;
				flex-direction: column;
				&.error-selected {
					border-color: $red;
					box-shadow: 0 1px 3px 0 rgba(235, 27, 104, 0.2);
				}
				&.active{
					border: 2px solid $primary;
					.header{
						.selected-check{
							display: flex;
						}
					}
					.list-features{
						.item-feature{
							.icon-square{
								background: $bgBlue;
								border: 1px solid $borderBlue;
								box-shadow: 0px 1px 4px rgba(8, 129, 255, 0.3);
								svg{
									.stroke-color{
										stroke: #FFF;
									}
								}
							}
						}
					}
				}
				.header{
					padding: 28px 24px 0;
					display: flex;
					align-items: center;
					margin-bottom: 20px;
					.title{
						font-weight: 700;
						color: $primary;
						font-size: 16px;
						line-height: 24px;
					}
					.selected-check{
						display: none;
						margin-left: auto;
						height: 24px;
						width: 24px;
						border-radius: 50%;
						border: 1px solid $primary;
						align-items: center;
						justify-content: center;
						svg{
							width: 11px;
							height: auto;
							.stroke-color{
								stroke: $primary;
							}
						}
					}
				}
				.price-box{
					display: flex;
					align-items: flex-end;
					padding: 0 24px 22px;
					border-bottom: 2px solid $borderColor;
					height: 40px;
					.value{
						font-size: 22px;
						font-weight: 400;
						color: $primary;
						line-height: 1;
						.old-price{
							font-size: 18px;
							text-decoration: line-through;
							color: $placeholderColor;
						}
					}
					.period{
						font-size: 12px;
						color: #858792;
						margin-bottom: 4px;
						margin-left: 4px;
					}
					.custom-price{
						font-size: 14px;
						color: #858792;
						align-self: flex-start;
					}
				}
				.list-features{
					background: #FBFBFC;
					padding: 24px 24px;
					flex: 1;
					.item-feature{
						display: flex;
						align-items: center;
						margin-bottom: 12px;
						&:last-child{
							margin-bottom: 0;
						}
						.text{
							font-size: 12px;
						}
						.icon-square{
							display: flex;
							align-items: center;
							justify-content: center;
							margin-left: auto;
							height: 16px;
							width: 16px;
							border-radius: 4px;
							background: #FCFCFC;
							border: 1px solid $borderColor;
							svg{
								width: 8px;
								.stroke-color{
									stroke: $primary;
								}
							}
						}
					}
				}
				.action{
					background: #FBFBFC;
					padding: 0 24px 24px;
					.btn-tbf{
						width: 100%;
						justify-content: center;
					}
				}
			}
		}
	}
	.submit-with-check{
		display: flex;
		flex-wrap: wrap;
		flex-basis: 0;
		flex-grow: 1;
		margin-right: -15px;
		margin-left: -15px;
		align-items: center;
		.submit-button-box{
			flex: 1;
			padding: 0 15px;
			@media(max-width: 600px){
				flex: 1 1 100%;
			}
			#buttonNextStep{
				width: 100%;
			}
		}
		.terms-condition{
			flex: 1;
			padding: 0 15px;
			@media(max-width: 600px){
				flex: 1 1 100%;
				margin-bottom: 30px;
			}
		}
	}
</style>