import Vue from 'vue'

import auth                  from '@websanova/vue-auth/src/v2.js';
import driverAuthBearer      from '@websanova/vue-auth/src/drivers/auth/bearer.js';
import driverHttpAxios       from '@websanova/vue-auth/src/drivers/http/axios.1.x.js';
import driverRouterVueRouter from '@websanova/vue-auth/src/drivers/router/vue-router.2.x.js';
import driverOAuth2Google    from '@websanova/vue-auth/src/drivers/oauth2/google.js';
import driverOAuth2Facebook  from '@websanova/vue-auth/src/drivers/oauth2/facebook.js';

driverOAuth2Google.params.client_id = '547886745924-4vrbhl09fr3t771drtupacct6f788566.apps.googleusercontent.com';
driverOAuth2Facebook.params.client_id = '196729390739201';

Vue.use(auth, {
    plugins: {
        http: Vue.axios,
        router: Vue.router,
    },
    drivers: {
        auth: driverAuthBearer,
        http: driverHttpAxios, // Axios
        router: driverRouterVueRouter,
        oauth2: {
            google: driverOAuth2Google,
            facebook: driverOAuth2Facebook,
        }
    },
    options: {
        rolesKey: 'rights',
        notFoundRedirect: {name: 'not-exist'},
        rememberKey: 'auth_remember_digital',
        staySignedInKey: 'auth_stay_signed_in_digital',
        tokenDefaultKey: 'auth_token_digital',
        tokenImpersonateKey: 'auth_token_impersonate_digital',
        authRedirect: { path: '/login'},
        stores: ['cookie'],
        cookie: {
            Path: '/',
            Domain: process.env.VUE_APP_MAIN_DOMAIN,
            Expires: 12096,
            Secure: false,
            SameSite: 'Lax',
            'Max-Age': 2592000
        },
        logoutData: {
            redirect: '/login',
            makeRequest: true
        }
    }
});

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import VTooltip from 'v-tooltip'
window.v_tooltip_mobile = window.innerWidth < 780
Vue.use(VTooltip)

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import VueCardFormat from 'vue-credit-card-validation'
Vue.use(VueCardFormat)

import 'vue-multiselect/dist/vue-multiselect.min.css'
import Multiselect from 'vue-multiselect'
Vue.component('multiselect', Multiselect)

import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
Vue.component('vue-timepicker', VueTimepicker)

import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
Vue.component('date-range-picker', DateRangePicker)

import { MonthPickerInput } from 'vue-month-picker'
Vue.use(MonthPickerInput)

import vueDebounce from 'vue-debounce'
Vue.use(vueDebounce)

import VueApexCharts from 'vue-apexcharts';
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts);


import VueMoment from 'vue-moment'
import momentTz from 'moment-timezone'
window.moment = require('moment');
moment.updateLocale('ro', { week: { dow: 1, } })
window.moment.locale('ro', {
  relativeTime: {
    future: '+ %s',
  }
});

Vue.use(VueMoment, {moment, momentTz});
Vue.prototype.moment = moment;

import FunctionalCalendar from 'vue-functional-calendar'
Vue.use(FunctionalCalendar, {
    dayNames: moment.weekdaysMin(true),
    monthNames: moment.months(),
    shortMonthNames: moment.monthsShort()
});

import VueDragscroll from 'vue-dragscroll'
Vue.use(VueDragscroll)

import vueNumeralFilterInstaller from 'vue-numeral-filter';
Vue.use(vueNumeralFilterInstaller, { locale: 'en' });

import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(VueQuillEditor)

import VueGapi from 'vue-gapi'
Vue.use(VueGapi, {
    apiKey: '<YOUR_API_KEY>',
    clientId: '<YOUR_CLIENT_ID>.apps.googleusercontent.com',
    discoveryDocs: ['https://sheets.googleapis.com/$discovery/rest?version=v4'],
    scope: 'https://www.googleapis.com/auth/spreadsheets'
})
