<template>
    <div class="modal-tbf modal-full-page">
        <div class="container-modal opacity-page" v-if="loaded">
            <div class="header-modal">
                <div class="title">
                    {{$t('tree-chart.edit_company')}}
                </div>
                <div class="actions">
                    <button class="btn-tbf white only-icon close-btn" @click="cancelSubmit"><icon-close class="icon-close" /></button>
                </div>
            </div>

            <div class="info-content">
                <div class="container-content-modal">
                    <div class="form-section">
                       <div class="input-group profile">
						<div class="upload-img update" onclick="document.getElementById('avatarFile').click();">
							<img v-if="urlAvatar != ''" :src="urlAvatar" class="img-circle obj-contain"/>
							<img v-else src="../../assets/user-circle.svg" class="img-circle obj-contain"/>
							<div class="text">
								<p class="title">{{$t('tree-chart.logo')}}</p>
								<div class="with-image"><icon-upload /><p>{{$t('profile.upload_new_picture')}}</p></div>
							</div>
							<input style="display:none" id="avatarFile" type="file" accept="image/png, image/jpg, image/jpeg" v-on:change="onAvatarChange">
						</div>
					</div>
                        <div class="input-group w-100">
                            <div class="label-header">
                                <label class="label">{{$t('general.name')}}*</label>
                            </div>
                            <div class="input-box bg-white department" v-bind:class="{has_error: $v.name.$error}">
                                <div class="icon-right" v-if="name != ''" @click.stop="name = ''"><icon-close class="icon-clear" /></div>
                                <input type="text" :placeholder="$t('general.name_placeholder')" class="input-text no-icon" v-model="name">
                            </div>
                        </div>
                    </div>
                    <div class="form-submit user">
                        <button class="btn-tbf grey center" @click="cancelSubmit">
                            <div class="loader"></div>
                            <div class="text">{{$t('general.cancel')}}</div>
                        </button>
                        <div class="error-msg" v-show="error_message !='' ">{{error_message}}</div>
                        <button id='buttonEditCompany' class="btn-tbf blue center" @click="saveAction()">
                            <div class="loader"></div>
                            <div class="text">{{$t('general.update')}}</div>
                        </button>
                    </div>
                </div>
            </div>
            
            <div id="submodalContainer" class="submodal-container" v-if="subModalShow">
                <div class="overlay-submodal"></div>
                <modal-confirm-close id="submodal_confirm_close" v-if="show_confirm_close" @close_modal="closeModalConfirmed" @cancel_close="cancelClose" />
            </div>
    	</div>
        <company-loader-modal v-else/>
    </div>
</template>

<script type="text/javascript">
import IconClose from '../Icons/Close'
import IconPeople from '../Icons/People'
import IconUpload from '../Icons/Upload'
import ModalConfirmClose from '../Modals/ConfirmClose'
import { required } from 'vuelidate/lib/validators'
import CompanyLoaderModal from '../PagesLoaders/UserModal.vue'
	export default {
		data() {
			return {
                loaded: false,
				name: '',
                urlAvatar: '',
                newAvatar: '',
                subModalShow: false,
                show_confirm_close: false,
                error_message: ''
			};
		},
        components: {
            IconClose,
            IconPeople,
            IconUpload,
            ModalConfirmClose,
            CompanyLoaderModal
        },
       
        props: {
            data: Object
        },
        validations: {
			name: {required},
		},
		async mounted() {
            setTimeout(()=>{
                this.name = this.data.name
                this.urlAvatar = this.data.avatar
                this.loaded = true
                setTimeout(() => {
                    $('.opacity-page').addClass('show')
                }, 0)
            },0)

            this.$root.$on("confirm_close", () => {
                if(this.checkInputs()){
                    this.subModalShow = true
                    this.show_confirm_close = true
                    setTimeout(() => {
                        document.getElementById('submodalContainer').classList.add('active')
                        setTimeout(() => {
                            document.getElementById('submodal_confirm_close').classList.add('active')
                        }, 200);
                    }, 0);
                }else{
                    this.$emit("close_modal");
                }
            });
		},
		methods: {
			cancelSubmit(){
                if(this.checkInputs()){
                    this.subModalShow = true
                    this.show_confirm_close = true
                    setTimeout(() => {
                        document.getElementById('submodalContainer').classList.add('active')
                        setTimeout(() => {
                            document.getElementById('submodal_confirm_close').classList.add('active')
                        }, 200);
                    }, 0);
                }else{
                    this.$emit("close_modal");
                }
			},
            saveAction(){
				var buttonName = `buttonEditCompany`
				var btnSubmit = document.getElementById(buttonName);
				btnSubmit.disabled = true

				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
                this.error_message = '';

				this.$v.$touch()
				if(!this.$v.$invalid){
                    var objData = new FormData();

                    if(this.newAvatar) { objData.append('avatar', this.newAvatar) }

                    objData.append('name', this.name.charAt(0).toUpperCase() + this.name.slice(1)) 
					this.updateCompany(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
            updateCompany(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post(`/brand/${this.$auth.user().instance.id}/update`, objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.$v.$reset()
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('btn-submit.create')
                            this.$emit("close_modal");
                            if(this.data.from == 'chart'){
                                this.$root.$emit('refreshCompanyChart');
                            }
						}, 1000)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.add')
							btnSubmit.disabled = false
                            if(error.response.data.errors.hasOwnProperty('brand_avatar')){
                                this.error_message = this.$t('validator.brand_avatar')
                            }else if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							} 
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
            onAvatarChange(e){
				var files = e.target.files || e.dataTransfer.files;
				if (!files.length) return;

				this.urlAvatar = URL.createObjectURL(files[0]);
				this.newAvatar = files[0]
			},
            checkInputs(){
                var notEmpty = false

                if(this.name != ''){
                    notEmpty = true
                }

                return notEmpty
            },
            closeModalConfirmed(){
                this.$emit("close_modal");
            },
            cancelClose(){
                document.getElementById('submodal_confirm_close').classList.remove('active')
                setTimeout(() => {
                    document.getElementById('submodalContainer').classList.remove('active')
                    setTimeout(() => {
                        this.subModalShow = false;
                        this.show_confirm_close = false
                    }, 200);
                }, 200);
            },
		}
	};
</script>

<style lang="scss">
    #modal_company {
        max-width: 550px;
        z-index: 10
    }
    div.input-box.bg-white.company > input.no-icon{
        padding: 0 45px 0 15px;
    }
    .form-submit.company > button{
        width: 50%;
    }
</style>