<template>
<div class="modal-tbf modal-full-page">
    <div class="container-modal opacity-page" v-if="loaded">
        <div class="header-modal">
            <div class="title">
                {{ $t('nomenclature.benefits.add_benefit') }}
            </div>
            <div class="actions">
                <button class="btn-tbf white only-icon close-btn" @click="cancelRole"><icon-close class="icon-close" /></button>
            </div>
        </div>

        <div class="info-content">
            <div class="container-content-modal">
                <div class="form-section">
                    <div class="input-group w-100">
                        <div class="label-header">
                            <label class="label">{{ $t('general.name') }}*</label>
                            <div v-if="$v.benefit.name.$error" class="error-msg">{{ $t('validator.required') }}</div>
                        </div>
                        <div class="input-box bg-white" v-bind:class="{has_error: $v.benefit.name.$error}">
                            <div class="icon-left"><icon-benefit /></div>
                            <div class="icon-right" v-if="benefit.name != ''" @click.stop="benefit.name = ''"><icon-close class="icon-clear" /></div>
                            <input type="text" :placeholder="$t('skills.name-ph')" class="input-text no-icon" v-model="benefit.name">
                        </div>
                    </div>
                    <div class="input-group w-100">
                        <div class="label-header">
                            <label class="label">{{ $t('salary.benefits.calcul-type') }}*</label>
                            <div v-if="$v.benefit.selectedPercentage.$error" class="error-msg">{{ $t('validator.required') }}</div>
                        </div>
                        <div class="input-box bg-white" v-bind:class="{has_error: $v.benefit.selectedPercentage.$error}">
                            <div class="icon-left"><icon-calculator /></div>
                            <div class="icon-right" v-if="benefit.selectedPercentage != ''" @click.stop="benefit.selectedPercentage = ''"><icon-close class="icon-clear" /></div>
                            <multiselect 
                                v-model="benefit.selectedPercentage"
                                v-bind:class="{populate: benefit.selectedPercentage != ''}"
                                class="select-tags-tbf"
                                :options="percentageOptions"
                                :allow-empty="true"
                                :show-labels="false"
                                track-by="id" 
                                label="name"
                                @input="benefit.value = ''"
                                >
                                <template slot="placeholder" slot-scope="props">
                                    <span class="text">
                                        {{ $t('salary.benefits.calcul-type-ph') }}
                                    </span>
                                </template>
                                <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                                <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
                            </multiselect>
                        </div>
                    </div>
                    <div v-if="benefit.selectedPercentage.id == 1" class="input-group w-100">
                        <div class="label-header">
                            <label class="label">{{ $t('salary.benefits.percentage') }}*</label>
                            <div v-if="$v.benefit.value.$error" class="error-msg">{{ $t('validator.required') }}</div>
                        </div>
                        <div class="input-box bg-white" v-bind:class="{has_error: $v.benefit.value.$error}">
                            <div class="icon-left"><icon-percentage /></div>
                            <input type="text" @keypress="onlyNumber" class="input-text no-icon" :placeholder="$t('salary.benefits.percentage-ph')" v-model="benefit.value">
                        </div>
                    </div>
                    <div v-if="benefit.selectedPercentage.id == 2" class="input-group w-100">
                        <div class="label-header">
                            <label class="label">{{ $t('salary.benefits.fix_sum') }}*</label>
                            <div v-if="$v.benefit.value.$error" class="error-msg">{{ $t('validator.required') }}</div>
                        </div>
                        <div class="input-box bg-white" v-bind:class="{has_error: $v.benefit.value.$error}">
                            <div class="icon-left"><icon-calculator /></div>
                            <input type="text" @keypress="onlyNumber" class="input-text no-icon" :placeholder="$t('salary.benefits.fix_sum-ph')" v-model="benefit.value">
                        </div>
                    </div>
                    <div class="input-group w-50">
                        <div class="label-header">
                            <label class="label">{{ $t('nomenclature.benefits.is_permanent') }}</label>
                        </div>
                        <div class="input-box bor-0">
                            <div class="checkbox-list">
                                <div class="checkbox-item m-4" @click="benefit.is_permanent = 0" v-bind:class="{active: benefit.is_permanent === 0}">
                                    <div class="name">{{ $t('create-user.no') }}</div>
                                </div>
                                <div class="checkbox-item m-4" @click="benefit.is_permanent = 1" v-bind:class="{active: benefit.is_permanent === 1}">
                                    <div class="name">{{ $t('create-user.yes') }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="input-group w-50">
                        <div class="label-header">
                            <label class="label">{{ $t('nomenclature.benefits.include_sum_in_co_base') }}</label>
                        </div>
                        <div class="input-box bor-0">
                            <div class="checkbox-list">
                                <div class="checkbox-item m-4" @click="benefit.include_sum_in_CO_base = 0" v-bind:class="{active: benefit.include_sum_in_CO_base === 0}">
                                    <div class="name">{{ $t('create-user.no') }}</div>
                                </div>
                                <div class="checkbox-item m-4" @click="benefit.include_sum_in_CO_base = 1" v-bind:class="{active: benefit.include_sum_in_CO_base === 1}">
                                    <div class="name">{{ $t('create-user.yes') }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="input-group w-100">
                        <div class="label-header">
                            <label class="label">{{ $t('nomenclature.benefits.bonus_base') }}*</label>
                            <div v-if="$v.benefit.selectedBase.$error" class="error-msg">{{ $t('validator.required') }}</div>
                        </div>
                        <div class="input-box bg-white" v-bind:class="{has_error: $v.benefit.selectedBase.$error}">
                            <div class="icon-left"><icon-benefit /></div>
                            <div class="icon-right" v-if="benefit.selectedBase != ''" @click.stop="benefit.selectedBase = ''"><icon-close class="icon-clear" /></div>
                            <multiselect 
                                v-model="benefit.selectedBase"
                                v-bind:class="{populate: benefit.selectedBase != ''}"
                                class="select-tags-tbf"
                                :options="optionsBases"
                                :allow-empty="true"
                                :show-labels="false"
                                track-by="id" 
                                label="name"
                                >
                                <template slot="placeholder" slot-scope="props">
                                    <span class="text">
                                        {{ $t('nomenclature.benefits.bonus_base-ph') }}
                                    </span>
                                </template>
                                <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                                <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
                            </multiselect>
                        </div>
                    </div>
                    <div class="input-group w-100">
                        <div class="label-header">
                            <label class="label">{{ $t('nomenclature.benefits.bonus_type') }}</label>
                        </div>
                        <div class="input-box bg-white">
                            <div class="icon-left"><icon-benefit /></div>
                            <div class="icon-right" v-if="benefit.selectedType != ''" @click.stop="benefit.selectedType = ''"><icon-close class="icon-clear" /></div>
                            <multiselect 
                                v-model="benefit.selectedType"
                                v-bind:class="{populate: benefit.selectedType != ''}"
                                class="select-tags-tbf"
                                :options="optionsTypes"
                                :allow-empty="true"
                                :show-labels="false"
                                track-by="id" 
                                label="name"
                                >
                                <template slot="placeholder" slot-scope="props">
                                    <span class="text">
                                        {{ $t('nomenclature.benefits.bonus_type-ph') }}
                                    </span>
                                </template>
                                <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                                <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
                            </multiselect>
                        </div>
                    </div>
                <div class="form-submit benefit">
                    <button class="btn-tbf grey center" @click="cancelRole">
                        <div class="loader"></div>
                        <div class="text">{{$t('general.cancel')}}</div>
                    </button>
					<div class="error-msg" v-show="error_message!=''">{{error_message}}</div>
                    <button id='buttonCreateRole' class="btn-tbf blue center" @click="saveAction('create')">
                        <div class="loader"></div>
                        <div class="text">{{$t('general.add')}}</div>
                    </button>
                </div>
            </div>
        </div>
		<div id="submodalContainer" class="submodal-container" v-if="subModalShow">
			<div class="overlay-submodal"></div>
			<modal-confirm-close id="submodal_confirm_close" v-if="show_confirm_close" @close_modal="closeModalConfirmed" @cancel_close="cancelClose" />
		</div>
	</div>
</div>
</div>
</template>

<script type="text/javascript">
import IconClose from '../Icons/Close'
import IconEdit from '../Icons/Edit'
import IconTrash from '../Icons/Trash'
import IconBenefit from '../Icons/Benefit'
import IconCalculator from '../Icons/Calculator'
import IconPercentage from '../Icons/Percentage'

import { required } from 'vuelidate/lib/validators'
import ModalConfirmClose from '../Modals/ConfirmClose'
	export default {
		data() {
			return {
               bonuses: [],
                optionsBases: [],
                optionsTypes: [],
                percentageOptions: [{id: 1, name: this.$t('salary.benefits.percentage'), value: 1}, {id: 2, name: this.$t('salary.benefits.fix_sum'), value: 0}],
                benefit: {
                    name: '',
                    selectedPercentage: '',
                    value: '',
                    is_permanent: 0,
                    include_sum_in_CO_base: 0,
                    selectedBase: '',
                    selectedType: ''

                },
				benefitSelected: '',
				error_message: '',
                is_deletable: 0,
				loaded: false,
				subModalShow: false,
                show_confirm_close: false,
                error_message: ''
			};
		},
        components: {
          IconClose,
			IconEdit,
			IconTrash,
			IconBenefit,
            IconCalculator,
            IconPercentage,
			ModalConfirmClose
        },
        validations: {
			benefit:{
                name: { required },
                selectedPercentage: { required },
                value: { required },
                selectedBase: { required }
            }
		},
		async mounted() {
            await this.getBonusBases()
            await this.getBonusTypes()

			setTimeout(() => {
				this.loaded = true
				setTimeout(() => {
					$('.opacity-page').addClass('show')
				}, 0)
			}, 0)

			this.$root.$on("confirm_close", () => {
                if(this.checkInputs()){
                    this.subModalShow = true
                    this.show_confirm_close = true
                    setTimeout(() => {
                        document.getElementById('submodalContainer').classList.add('active')
                        setTimeout(() => {
                            document.getElementById('submodal_confirm_close').classList.add('active')
                        }, 200);
                    }, 0);
                }else{
                    this.$emit("close_modal");
                }
            });
		},
		methods: {
            onlyNumber ($event) {
                let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
                if (keyCode < 48 || keyCode > 57) { 
                    $event.preventDefault();
                }
            },
			cancelRole(){
				this.$emit("close_modal");
			},
            async getBonusBases() {
                await axios.get('/bonus-bases')
				.then(({data}) => {
					this.optionsBases = data
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
            },
            async getBonusTypes() {
                await axios.get('/bonus-types')
				.then(({data}) => {
					this.optionsTypes = data
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
            },
            saveAction(type){
				var buttonName = `buttonCreateRole`
				var btnSubmit = document.getElementById(buttonName);
				btnSubmit.disabled = true

				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
                this.error_message = '';

				this.$v.$touch()
				if(!this.$v.$invalid){
					var objData = {
						name: this.benefit.name.charAt(0).toUpperCase() + this.benefit.name.slice(1),
                        is_percentage: this.benefit.selectedPercentage ? this.benefit.selectedPercentage.value : '',
                        value: this.benefit.value,
                        is_permanent: this.benefit.is_permanent,
                        include_sum_in_CO_base: this.benefit.include_sum_in_CO_base,
                        bonus_base_id: this.benefit.selectedBase ? this.benefit.selectedBase.id : '',
                        bonus_type_id: this.benefit.selectedType ? this.benefit.selectedType.id : '',
					}

					if(type == 'create'){
						this.createBenefit(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}
				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
            createBenefit(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post('/bonuses/store', objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.$v.$reset()
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('btn-submit.create')
                            this.$emit("close_modal");
							this.$root.$emit('addDropdownBenefitCreate', data.data, 'role');
						}, 1000)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.add')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
			checkInputs(){
                var notEmpty = false

                if(this.name != ''){
                    notEmpty = true
                }

                return notEmpty
            },
            closeModalConfirmed(){
                this.$emit("close_modal");
            },
            cancelClose(){
                document.getElementById('submodal_confirm_close').classList.remove('active')
                setTimeout(() => {
                    document.getElementById('submodalContainer').classList.remove('active')
                    setTimeout(() => {
                        this.subModalShow = false;
                        this.show_confirm_close = false
                    }, 200);
                }, 200);
            },
		}
	};
</script>

<style lang="scss">
#modal_role {
    max-width: 500px;
    z-index: 10
}
div.input-box.bg-white.role > input.no-icon{
    padding: 0 45px 0 15px;
}
.form-submit {
    &.benefit{
        width: 100%;
        padding: 0 15px;
       button{
           width: 50%;
       }
    }
}
</style>