<template>
	<div class="modal_info modal_show_evaluation">
		<template v-if="loaded">
			<div class="header-submodal">
				<div class="title">{{ $t('show_user.evaluation.title', {name: `${data.user.first_name} ${data.user.last_name ? data.user.last_name : ''}` }) }}</div>
			</div>

			<div class="subheader-data">
				<div class="item">
					{{ $t('general.date') }}: {{ data.created_at | moment('DD MMMM YYYY') }}
				</div>
				<div class="item">
					{{ $t('show_user.evaluation.rating') }}: <star-rating v-model="data.stars" :show-rating="false" :star-size=15 :increment="0.5" :read-only=true />
				</div>
				<div class="item">
					{{ $t('show_user.evaluation.evaluator') }}: {{ `${data.evaluator.first_name} ${data.evaluator.last_name ? data.evaluator.last_name : ''}` }}
				</div>
			</div>

			<div class="description-modal">
				<div class="label">{{ $t('show_user.evaluation.good_things') }}</div>
				<div class="value">{{ data.good_things }}</div>
			</div>
			<div class="description-modal">
				<div class="label">{{ $t('show_user.evaluation.what_to_change') }}</div>
				<div class="value">{{ data.what_to_change }}</div>
			</div>

			<div class="button-actions">
				<button class="btn-tbf center blue" @click="closeModal">
					<div class="text">{{ $t('general.close') }}</div>
				</button>
			</div>
		</template>
	</div>
</template>

<script type="text/javascript">
	import IconClose from '../Icons/Close'
	import StarRating from 'vue-star-rating'

	export default {
		data() {
			return {
				loaded: false,
			};
		},
		props: {
            data: Object
        },
        components: {
        	IconClose,
        	StarRating
        },
		mounted() {
			setTimeout(() => { this.loaded = true }, 200)
		},
		methods: {
			closeModal(){
				this.$emit("close_modal");
			}
		}
	};
</script>