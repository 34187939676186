<template>
	<div class="modal_info modal_delete">
		<div class="header-submodal">
			<div class="image"><img src="/build/flappy/birdsimple.png"></div>
			<div class="title">{{ $t('subscription.title_renew') }}</div>
		</div>
		<div class="description-modal">
			{{ $t('subscription.question_renew') }}
		</div>

		<div class="button-actions">
			<button class="btn-tbf white center" @click="cancelAction">
				<div class="text">{{ $t('general.cancel') }}</div>
			</button>
			<button id="buttonSubmitRenew" class="btn-tbf blue center" @click="submitAction">
				<div class="loader"></div>
				<div class="text">{{ $t('subscription.renew') }}</div>
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">

	export default {
		data() {
			return {

			};
		},
		components: {

		},
		mounted() {
			
		},
		methods: {
			cancelAction(){
				this.$emit("close_modal");
			},
			submitAction(){
				var buttonName = `buttonSubmitRenew`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				axios.post('renew-subscription')
				.then(() => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
							this.$auth.fetch().then( () => { this.$emit("close_modal") })
						}, 1000)
					}, 300)
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				});
			}
		}
	};
</script>