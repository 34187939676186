<template>
	<div class="navbar-tbf row-space-tbf">
		<div class="space-left">
			<div class="back-btn" @click="goRoute(navbarBack)" v-if="navbarBack">
				<icon-arrow-back />
			</div>
		</div>
		<div class="content">
			<div class="title" :class="{'fw-500': $route.name == 'software-updates' || $route.name == 'software-updates-show'}">
				<h1>{{ navbarTitle }}</h1>
			</div>
			<div class="profile-items">
				<div class="grace-period-notification item-navbar" v-if="$auth.ready() && $auth.user().rights.instance == 'grace_period'" @click="showModal('payment_error')">
					<div class="icon" v-if="$resize && $mq.below(780)"><icon-atention /></div>
					<div class="text" v-else>{{ $t('navbar.grace_period_notification') }}</div>
				</div>

				<div class="next-webinar item-navbar" @click="showModal('webinar_tbf')">
					<div class="label">{{ $t('navbar.webinar') }}</div>
					<div class="date" v-if="$resize && $mq.above(779)">{{ getTextForWebinar() }}</div>
				</div>

				<div class="google-sync item-navbar" v-if="$auth.check() && !$auth.user().google_calendar && $auth.user().email == 'alex@tbf.ro'">
					<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" class="label" :delay="{show: 100, hide: 0}" >
						<button class="btn-navbar google-button" @click="$root.$emit('google_sign_in')">
							<img class="calendar-logo" src="/build/images/google-calendar.svg">
							<img class="google-logo" src="/build/images/google-logo.svg">
						</button>

						<template slot="popover">
							<div class="simple-text">{{ $t('sync_calendar.message_navbar') }}</div>
						</template>
					</v-popover>
				</div>

				<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" class="mission-vision item-navbar" :delay="{show: 100, hide: 0}" >
					<button class="btn-navbar only-icon" @click="showModal('mission_vision')">
						<div class="icon"><icon-superman /></div>
					</button>

					<template slot="popover">
						<div class="simple-text">{{ $t('company.show_mission_vision') }}</div>
					</template>
				</v-popover>

				<v-popover class="help item-navbar" offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 0, hide: 0}" v-if="helpData">
					<a class="btn-navbar only-icon" :href="helpData.link" target="_blank">
						<div class="icon"><icon-question-circle /></div>
					</a>

					<template slot="popover">
						<div class="simple-text">{{ $t(`help_links.${helpData.type}`) }}</div>
					</template>
				</v-popover>

				<div class="item-navbar" v-if="$auth.ready() && $auth.user().instance && ['active', 'grace_period'].includes($auth.user().instance.status)">
					<button class="btn-navbar only-icon" @click="showModal('notifications')">
						<div class="icon active" v-if="notifications.length"><icon-notifications-on /></div>
						<div class="icon" v-else><icon-notifications /></div>
					</button>
				</div>
				
				<div class="digital-applications dropdown item-navbar" @click="getApplications">
					<div class="btn-navbar only-icon" ref="dropdownApplications" id="dropdownApplications" data-toggle="dropdown">
						<div class="icon"><icon-applications /></div>
					</div>

					<div class="dropdown-menu dropdown-menu-center" id="dropdownApplications" aria-labelledby="dropdownApplications">
						<template v-if="loadApplications">
							<div class="application-item">
								<a class="application" href="https://central.tbf.ro/dashboard" target="_blank">
									<div class="icon"><img src="/build/images/central.svg"></div>
									<div class="text">{{ $t('navbar.soft_central') }}</div>
								</a>
							</div>
							<div v-for="app in applications.filter(el => el.is_active)" class="application-item">
								<a class="application" :href="app.application.fe_host" target="_blank">
									<div class="icon"><img :src="app.application.image"></div>
									<div class="text">{{app.application.name}}</div>
								</a>
							</div>

							<div class="title-application" v-if="applications.filter(el => !el.is_active).length">Fa-ti cont si pe </div>
							<div v-for="app in applications.filter(el => !el.is_active)" class="application-item">
								<a class="application" :href="app.application.fe_host" target="_blank">
									<div class="icon"><img :src="app.application.image"></div>
									<div class="text">{{app.application.name}}</div>
								</a>
							</div>
						</template>
						<template v-else>
							<loader-applications  v-for="index in 9" :key="index"/>
						</template>
					</div>
				</div>

				<div class="profile-navbar dropdown item-navbar" v-if="$auth.ready() && $auth.check()">
					<div class="has_notification" v-if="$auth.user().new_software_updates"></div>

					<img :src="$auth.user().avatar" ref="dropdownUser" id="dropdownUser" data-toggle="dropdown" v-if="$auth.user().avatar">
					<div class="user-circle" ref="dropdownUser" id="dropdownUser" data-toggle="dropdown" v-else>
						<icon-user />
					</div>

					<div class="dropdown-menu dropdown-menu-right" id="dropdownApplications" aria-labelledby="dropdownApplications" v-if="$auth.ready()">
						<div class="user-data">
							<div class="name">{{ $auth.user().first_name + ' ' + $auth.user().last_name }}</div>
							<div class="email">{{ $auth.user().email }}</div>
						</div>
						<div class="dropdown-item">
							<router-link :to="{name: 'settings-profile' }">
								<div class="text">{{ $t('navbar.account') }}</div>
							</router-link>
						</div>
						<div class="dropdown-item">
							<router-link :to="{name: 'software-updates' }">
								<div class="text" :class="{new: $auth.user().new_software_updates}">{{ $tc('navbar.software_updates', $auth.user().new_software_updates, {count: $auth.user().new_software_updates}) }}</div>
							</router-link>
						</div>
						<div class="dropdown-item">
							<router-link :to="{name: 'work-in-progress' }">
								<div class="text">{{ $t('navbar.work_in_progress') }}</div>
							</router-link>
						</div>
						<div class="dropdown-item" v-if="$auth.user().instance && ['active', 'grace_period'].includes($auth.user().instance.status)">
							<a href="https://help.tbf.ro" target="_blank">
								<div class="text">{{ $t('navbar.help_center') }}</div>
							</a>
						</div>
						<div class="dropdown-item" @click="showModal('contact')">
							<div class="text">{{ $t('navbar.contact') }}</div>
						</div>
						<div class="dropdown-item">
							<div class="text" @click="$auth.logout()" >{{ $t('navbar.log_out') }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="space-right"></div>
	</div>
</template>

<script>
	import IconBell from '../Icons/Bell'
	import IconDotsSquare from '../Icons/DotsSquare'
	import IconUser from '../Icons/User'
	import IconArrowBack from '../Icons/ArrowLong'
	import LoaderApplications from '../PagesLoaders/ApplicationsNavbar.vue'
	import IconAtention from '../Icons/Info'
	import IconSuperman from '../Icons/Superman'
	import IconQuestionCircle from '../Icons/QuestionCircle'
	import IconNotifications from '../Icons/Notifications'
	import IconNotificationsOn from '../Icons/NotificationsOn'
	import IconApplications from '../Icons/Applications'

	export default {
		data() {
			return {
				navbarTitle: '',
				navbarBack: '',
				idOrSlug: '',
				applications: [],
				notifications: [],
				loadApplications: false,
				helpData: this.$store.getters['help_links/currentLink'](this.$route.name)
			};
		},
		components: {
			IconBell,
			IconDotsSquare,
			IconArrowBack,
			IconUser,
			LoaderApplications,
			IconAtention,
			IconSuperman,
			IconQuestionCircle,
			IconApplications,
			IconNotifications,
			IconNotificationsOn
		},
		watch:{
			$route (to, from){
				this.helpData = this.$store.getters['help_links/currentLink'](to.name)
			}
		},
		mounted() {
            this.$auth.load().then(() => {
            	if(this.$auth.check() && this.$cookies.get('websocket_notifications') != 'start'){
            		this.initWebSocket()
            	}
            });

			this.$root.$on("navbar_title", (title, back = false, description = false, idOrSlug = false) => {
				this.navbarTitle = title
				this.navbarBack = back
				this.idOrSlug = idOrSlug
			});

			this.$root.$on("clear_notifications", () => {
				this.notifications = []
			});
		},
		methods: {
			getApplications() {
                this.applications = this.$store.state.applications.applications
                this.loadApplications = true;
			},
			initWebSocket() {
				this.socket = new WebSocket(process.env.VUE_APP_WSS_LINK)
				this.socket.onopen = event => {
					this.$cookies.set('websocket_notifications', 'start')

					// check in to websocket
					this.socket.send(JSON.stringify({
						'user_id': this.$auth.user().id
					}));
				};

				// when receiving a message
				this.socket.onmessage = ({data}) => {
					const parsedData = JSON.parse(data);

					this.notifications.unshift(parsedData)
					this.$root.$emit('updateNotificationsList', parsedData);
				};

				this.socket.onerror = error => {
					console.log(`Websocket error`);
				};

				this.socket.onclose = event => {
					this.$cookies.set('websocket_notifications', 'close')

					if (event.wasClean) {
						console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
					} else {
						// e.g. server process killed or network down
						// event.code is usually 1006 in this case
						console.log('[close] Connection died');

						setTimeout(() => {
							this.initWebSocket();
						}, 600000);
					}
				};
			},
			goRoute(route){
				this.idOrSlug ? this.$router.push({ name: route, params: {slug: this.idOrSlug}}) : this.$router.push({ name: route})
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
			getTextForWebinar(){
				let date = ''
				let currentHour = moment(moment().tz("Europe/Bucharest").format('YYYY-MM-DD HH:mm'));
				let webinarEndHour = moment('11:00', 'HH:mm').tz("Europe/Bucharest");

				var webinarStartHour = moment.tz("Europe/Bucharest").hours(10).startOf('hour');
				var localtz = moment.tz.guess()
				var dateClone = webinarStartHour.clone().tz(localtz)
				var formatDate = moment(dateClone).format('HH:mm')

				if(moment().isoWeekday() == 2 && currentHour.isAfter(webinarEndHour)) {
					date = `${this.$t('calendar.long_days.thursday')} ${this.$t('webinar_tbf.time', {hour: formatDate} )}`
				} else if(moment().isoWeekday() == 4 && currentHour.isAfter(webinarEndHour)) {
					date = `${this.$t('calendar.long_days.tuesday')} ${this.$t('webinar_tbf.time', {hour: formatDate} )}`
				} else if((moment().isoWeekday() == 2 || moment().isoWeekday() == 4) && currentHour.isBefore(webinarEndHour)) {
					date = `${this.$t('webinar_tbf.today')} ${this.$t('webinar_tbf.time', {hour: formatDate} )}`
				} else if(moment().isoWeekday() == 1 || moment().isoWeekday() == 3) {
					date = `${this.$t('webinar_tbf.tomorrow')} ${this.$t('webinar_tbf.time', {hour: formatDate} )}`
				} else if(moment().isoWeekday() > 4 || moment().isoWeekday() < 2) {
					date = `${this.$t('calendar.long_days.tuesday')} ${this.$t('webinar_tbf.time', {hour: formatDate} )}`
				} else if(moment().isoWeekday() > 2 && moment().isoWeekday() < 4) {
					date = `${this.$t('calendar.long_days.thursday')} ${this.$t('webinar_tbf.time', {hour: formatDate} )}`
				}

				return date;
			}
		}
	};
</script>