<template>
    <component :is="layout">
        <router-view/>
    </component>
</template>

<script>
    import LoginTemplate from '@/components/Layouts/LoginTemplate.vue'
    import FullTemplate from '@/components/Layouts/FullTemplate.vue'
    import MainTemplate from '@/components/Layouts/MainTemplate.vue'
    import FormTemplate from '@/components/Layouts/FormTemplate.vue'
    import SettingsTemplate from '@/components/Layouts/SettingsTemplate.vue'
    import SalaryTemplate from '@/components/Layouts/SalaryTemplate.vue'
    import ContractsTemplate from '@/components/Layouts/ContractsTemplate.vue'
    import NomenclatureTemplate from '@/components/Layouts/NomenclatureTemplate.vue'
    import SystemTemplate from '@/components/Layouts/SystemTemplate.vue'

    export default {
        data() {
            return {
                layout: 'main-template',
                finishApplications: false
            }
        },
        components: {
            MainTemplate,
            FormTemplate,
            SettingsTemplate,
            FullTemplate,
            LoginTemplate,
            SalaryTemplate,
            ContractsTemplate,
            NomenclatureTemplate,
            LoginTemplate,
            SystemTemplate
        },
        watch:{
            $route (to, from){
                if(['not-exist', 'forbbiden', 'not-found'].includes(this.$route.name) && !this.$auth.check()) {
                    this.layout = 'presentation-template'
                }else{
                    this.layout = this.$route.meta.layout
                }
            }
        },
        created() {
            this.api = gapi;
            this.handleClientLoad();
        },
        mounted(){
            if(['not-exist', 'forbbiden', 'not-found'].includes(this.$route.name) && !this.$auth.check()) {
                this.layout = 'presentation-template'
            }else{
                this.layout = this.$route.meta.layout
            }
            
            this.$cookies.set('websocket_notifications', 'close')

            var refreshIntervalId = setInterval(() => {
                if(this.finishApplications){
                    clearInterval(refreshIntervalId);
                }else{
                    this.getApplications()
                    this.checkStatus()
                }
            }, 50);

            this.$root.$on('google_sign_in', () => {
                this.googleSignIn()
            })

            this.$root.$on('google_sign_out', () => {
                this.googleSignOut()
            })
        },
        methods: {
            getApplications(){
                if(this.$auth.check()){
                    this.finishApplications = true
                    this.$store.dispatch('applications/getApplications');
                }
            },
            checkStatus(){
                if(this.$auth.check() && !this.$auth.user().is_active){
                    this.$auth.logout();
                }
            },
            handleClientLoad() {
                this.api.load('client:auth2', this.initClient);
            },
            initClient() {
                this.api.client.init({
                    apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
                    clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
                    discoveryDocs: [process.env.VUE_APP_GOOGLE_DISCOVERY_DOCS],
                    scope: process.env.VUE_APP_GOOGLE_SCOPES
                });
            },
            googleSignIn(event) {
                Promise.resolve(this.api.auth2.getAuthInstance().signIn())
                .then(value => {
                    this.api.client.calendar.calendarList.list()
                    .then(response => {
                        if(response.result.items.length > 1) {
                            this.$root.$emit('open_modal', 'calendar_list',  { access_token: value.wc.access_token, list: response.result.items })
                        } else {
                            axios.post('/sync-google-calendar', { access_token: value.wc.access_token })
                            .then(() => {
                                this.$auth.fetch()
                            })
                        }
                    })
                });
            },
            googleSignOut(event) {
                Promise.resolve(this.api.auth2.getAuthInstance().signOut())
                axios.post('/unsync-google-calendar')
                .then(() => {
                    this.$auth.fetch()
                })
            }
        }
    }
</script>