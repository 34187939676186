<template>
	<div class="sidebar-settings">
		<div class="top">
			<h1>{{ $t('settings-navbar.title') }}</h1>
			<div class="btn-tbf white only-icon close-btn" @click="closeModal"><icon-close /></div>
		</div>
			
		<div class="settings-content">
			<div  v-if="$auth.user().rights.admins_instance_crud" class="item-menu" @click.stop="closeModal">
				<router-link :to="{name: 'settings-work-schedules' }">
					<span class="text">{{$t('settings-navbar.work-schedules')}}</span>
				</router-link>
			</div>
			<div v-if="$auth.user().rights.admins_instance_crud" class="item-menu" @click.stop="closeModal">
				<router-link :to="{name: 'settings-departments' }">
					<span class="text">{{$t('settings-navbar.departments')}}</span>
				</router-link>
			</div>
			<div v-if="$auth.user().rights.admins_instance_crud" class="item-menu" @click.stop="closeModal">
				<router-link :to="{name: 'settings-workstations' }">
					<span class="text">{{$t('settings-navbar.workstations')}}</span>
				</router-link>
			</div>
			<div v-if="$auth.user().rights.admins_instance_crud" class="item-menu" @click.stop="closeModal">
				<router-link :to="{name: 'settings-skills' }">
					<span class="text">{{$t('settings-navbar.skills')}}</span>
				</router-link>
			</div>
			<!-- <div class="item-menu" v-if="$auth.user().rights.admins_instance_crud" @click.stop="closeModal">
				<router-link :to="{name: 'settings-holidays' }">
					<span class="text">{{$t('settings-navbar.holidays')}}</span>
				</router-link>
			</div> -->
			<div v-if="$auth.user().rights.admins_instance_crud" class="item-menu">
				<router-link :to="{name: 'settings-company' }">
					<span class="text">{{$t('settings-navbar.company')}}</span>
				</router-link>
			</div>
			<div class="item-menu" @click.stop="closeModal">
				<router-link :to="{name: 'settings-profile' }">
					<span class="text">{{$t('settings-navbar.profile')}}</span>
				</router-link>
			</div>
			<!-- <div class="item-menu" @click.stop="closeModal" v-if="$auth.check() && $auth.user().is_entrepreneur && !$auth.user().free_account">
				<router-link :to="{name: 'settings-subscriptions'}" >
					<span class="text">{{$t('settings-navbar.subscriptions')}}</span>
				</router-link>
			</div> -->
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconClose from "../Icons/Close"
	export default {
		data() {
			return {
			};
		},
		props:{

		},
		components: {
			IconClose
		},
		async mounted() {
			
		},
		methods: {
			closeModal(){
				this.$emit("close_modal");
			},
		}
	};
</script>