<template>
<div class="modal-tbf modal-full-page">
    <div class="container-modal opacity-page" v-if="loaded">
        <div class="header-modal">
            <div class="title">
                {{data.text}}
            </div>
            <div class="actions">
                <button class="btn-tbf white only-icon close-btn" @click="cancePdfPreview"><icon-close class="icon-close" /></button>
            </div>
        </div>

        <div class="info-content">
            <div class="actions-page mobile pdf">
                <button id="buttonDownload" class="btn-tbf blue" @click="downloadPdf(data.from)">
                    <div class="icon">
                        <icon-download />
                    </div>
					<div class="loader"></div>
                    <span v-if="$resize && $mq.above(600)" class="text">{{  $t('salary.download.download_pdf') }}</span>
                </button>
            </div>
            <div class="container-content-modal" v-html="data.data"></div>
        </div>
	</div>
</div>
</template>

<script type="text/javascript">
import IconClose from '../Icons/Close'
import IconDownload from '../Icons/Download'
import ModalConfirmClose from '../Modals/ConfirmClose'
	export default {
		data() {
			return {
				loaded: false,
			};
		},
        components: {
            IconClose,
            IconDownload,
			ModalConfirmClose
        },
        props: {
            data: Object
        },
		mounted() {
			setTimeout(() => {
				this.loaded = true
				setTimeout(() => {
					$('.opacity-page').addClass('show')
				}, 0)
			}, 0)

			this.$root.$on("confirm_close", () => {
                this.$emit("close_modal");
            });
		},
		methods: {
            downloadPdf(from) {
                switch (from) {
                    case 'payrolls':
                        this.downloadPayrollsPdf()
                        break;
                    case 'pay-stubs':
                        this.downloadPayStubsPdf()
                        break;
                    case 'bonuses':
                        this.downloadBonusesPdf()
                        break;
                    case 'retentions':
                        this.downloadRetentionPdf()
                        break;
                    case 'annual-co': 
                        this.downloadCoPdf()
                        break;
                    case 'payroll-timesheets': 
                        this.donwloadClockingPdf()
                        break;
                    case 'payroll-timesheet-days': 
                        this.donwloadClockingDailyPdf()
                        break;
                    case 'contracts': 
                        this.donwloadContractRaportPdf()
                        break;
                     case 'coinsureds': 
                        this.donwloadInsurersPdf()
                        break;
                    default:
                        break;
                }
            },
            downloadPayrollsPdf() {
				var buttonName = `buttonDownload`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				if(this.$resize && this.$mq.above(600)){
					btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				}

				axios.get(`${this.$auth.user().instance.id}/payroll-reports/export/pdf/payrolls?month=${this.data.date.month}&year=${this.data.date.year}`, 
				{
                    params: this.data.filters,
                	responseType: 'blob'
				})
				.then((response) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						if(this.$resize && this.$mq.above(600)){
							btnSubmitText.innerHTML = this.$t('btn-submit.success')
						}
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(() => {
							btnSubmit.classList.remove('completed')
							btnSubmit.disabled = false
							if(this.$resize && this.$mq.above(600)){
								btnSubmitText.innerHTML = this.$t('salary.download.download_pdf')
							}
					}, 500);

					const file = new Blob([response.data], {type: 'application/pdf'});
					let fileURL = window.URL.createObjectURL(file);

					let fileName = response.headers['content-disposition'].split('filename=')[1].replace(/"/g, '');
					let fileLink = document.createElement('a');
					fileLink.href = fileURL;
					fileLink.setAttribute( 'download', fileName );
					document.body.appendChild(fileLink);

					fileLink.click();
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('salary.download.download_pdf')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				})
            },
            downloadPayStubsPdf() {
				var buttonName = `buttonDownload`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				if(this.$resize && this.$mq.above(600)){
					btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				}

				axios.get(`${this.$auth.user().instance.id}/payroll-reports/export/pdf/pay-stubs?month=${this.data.date.month}&year=${this.data.date.year}`, 
				{
                    params: this.data.filters,
                	responseType: 'blob'
				})
				.then((response) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						if(this.$resize && this.$mq.above(600)){
							btnSubmitText.innerHTML = this.$t('btn-submit.success')
						}
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(() => {
							btnSubmit.classList.remove('completed')
							btnSubmit.disabled = false
							if(this.$resize && this.$mq.above(600)){
								btnSubmitText.innerHTML = this.$t('salary.download.download_pdf')
							}
					}, 500);

					const file = new Blob([response.data], {type: 'application/pdf'});
					let fileURL = window.URL.createObjectURL(file);

					let fileName = response.headers['content-disposition'].split('filename=')[1].replace(/"/g, '');
					let fileLink = document.createElement('a');
					fileLink.href = fileURL;
					fileLink.setAttribute( 'download', fileName );
					document.body.appendChild(fileLink);

					fileLink.click();
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('salary.download.download_pdf')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				})
            },
            downloadBonusesPdf() {
				var buttonName = `buttonDownload`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				if(this.$resize && this.$mq.above(600)){
					btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				}

				axios.get(`${this.$auth.user().instance.id}/payroll-reports/export/pdf/bonuses?month=${this.data.date.month}&year=${this.data.date.year}`, 
				{
                    params: this.data.filters,
                	responseType: 'blob'
				})
				.then((response) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						if(this.$resize && this.$mq.above(600)){
							btnSubmitText.innerHTML = this.$t('btn-submit.success')
						}
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(() => {
							btnSubmit.classList.remove('completed')
							btnSubmit.disabled = false
							if(this.$resize && this.$mq.above(600)){
								btnSubmitText.innerHTML = this.$t('salary.download.download_pdf')
							}
					}, 500);

					const file = new Blob([response.data], {type: 'application/pdf'});
					let fileURL = window.URL.createObjectURL(file);

					let fileName = response.headers['content-disposition'].split('filename=')[1].replace(/"/g, '');
					let fileLink = document.createElement('a');
					fileLink.href = fileURL;
					fileLink.setAttribute( 'download', fileName );
					document.body.appendChild(fileLink);

					fileLink.click();
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('salary.download.download_pdf')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				})
            },
            downloadRetentionPdf() {
				var buttonName = `buttonDownload`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				if(this.$resize && this.$mq.above(600)){
					btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				}
                axios.get(`${this.$auth.user().instance.id}/payroll-reports/export/pdf/retentions?month=${this.data.date.month}&year=${this.data.date.year}`, 
				{
                    params: this.data.filters,
                	responseType: 'blob'
				})
				.then((response) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						if(this.$resize && this.$mq.above(600)){
							btnSubmitText.innerHTML = this.$t('btn-submit.success')
						}
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(() => {
							btnSubmit.classList.remove('completed')
							btnSubmit.disabled = false
							if(this.$resize && this.$mq.above(600)){
								btnSubmitText.innerHTML = this.$t('salary.download.download_pdf')
							}
					}, 500);

					const file = new Blob([response.data], {type: 'application/pdf'});
					let fileURL = window.URL.createObjectURL(file);

					let fileName = response.headers['content-disposition'].split('filename=')[1].replace(/"/g, '');
					let fileLink = document.createElement('a');
					fileLink.href = fileURL;
					fileLink.setAttribute( 'download', fileName );
					document.body.appendChild(fileLink);

					fileLink.click();
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('salary.download.download_pdf')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				})
            },
            downloadCoPdf() {
				var buttonName = `buttonDownload`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				if(this.$resize && this.$mq.above(600)){
					btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				}
                axios.get(`${this.$auth.user().instance.id}/payroll-reports/export/pdf/annual-co?month=${this.data.date.month}&year=${this.data.date.year}`, 
				{
                    params: this.data.filters,
                	responseType: 'blob'
				})
				.then((response) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						if(this.$resize && this.$mq.above(600)){
							btnSubmitText.innerHTML = this.$t('btn-submit.success')
						}
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(() => {
							btnSubmit.classList.remove('completed')
							btnSubmit.disabled = false
							if(this.$resize && this.$mq.above(600)){
								btnSubmitText.innerHTML = this.$t('salary.download.download_pdf')
							}
					}, 500);

					const file = new Blob([response.data], {type: 'application/pdf'});
					let fileURL = window.URL.createObjectURL(file);

					let fileName = response.headers['content-disposition'].split('filename=')[1].replace(/"/g, '');
					let fileLink = document.createElement('a');
					fileLink.href = fileURL;
					fileLink.setAttribute( 'download', fileName );
					document.body.appendChild(fileLink);

					fileLink.click();
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('salary.download.download_pdf')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				})
            },
            donwloadClockingPdf(){
				var buttonName = `buttonDownload`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				if(this.$resize && this.$mq.above(600)){
					btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				}
                axios.get(`${this.$auth.user().instance.id}/payroll-reports/export/pdf/payroll-timesheets`, 
				{
                    params: this.data.filters,
                	responseType: 'blob'
				})
				.then((response) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						if(this.$resize && this.$mq.above(600)){
							btnSubmitText.innerHTML = this.$t('btn-submit.success')
						}
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(() => {
							btnSubmit.classList.remove('completed')
							btnSubmit.disabled = false
							if(this.$resize && this.$mq.above(600)){
								btnSubmitText.innerHTML = this.$t('salary.download.download_pdf')
							}
					}, 500);

					const file = new Blob([response.data], {type: 'application/pdf'});
					let fileURL = window.URL.createObjectURL(file);

					let fileName = response.headers['content-disposition'].split('filename=')[1].replace(/"/g, '');
					let fileLink = document.createElement('a');
					fileLink.href = fileURL;
					fileLink.setAttribute( 'download', fileName );
					document.body.appendChild(fileLink);

					fileLink.click();
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('salary.download.download_pdf')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				})
            },
            donwloadClockingDailyPdf() {
				var buttonName = `buttonDownload`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				if(this.$resize && this.$mq.above(600)){
					btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				}
                axios.get(`${this.$auth.user().instance.id}/payroll-reports/export/pdf/payroll-timesheet-days`, 
				{
                    params: this.data.filters,
                	responseType: 'blob'
				})
				.then((response) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						if(this.$resize && this.$mq.above(600)){
							btnSubmitText.innerHTML = this.$t('btn-submit.success')
						}
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(() => {
							btnSubmit.classList.remove('completed')
							btnSubmit.disabled = false
							if(this.$resize && this.$mq.above(600)){
								btnSubmitText.innerHTML = this.$t('salary.download.download_pdf')
							}
					}, 500);
					const file = new Blob([response.data], {type: 'application/pdf'});
					let fileURL = window.URL.createObjectURL(file);

					let fileName = response.headers['content-disposition'].split('filename=')[1].replace(/"/g, '');
					let fileLink = document.createElement('a');
					fileLink.href = fileURL;
					fileLink.setAttribute( 'download', fileName );
					document.body.appendChild(fileLink);

					fileLink.click();
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('salary.download.download_pdf')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				})
            },
            donwloadContractRaportPdf() {
				var buttonName = `buttonDownload`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				if(this.$resize && this.$mq.above(600)){
					btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				}

                axios.get(`${this.$auth.user().instance.id}/payroll-reports/export/pdf/contracts`, 
				{
                    params: this.data.filters,
                	responseType: 'blob'
				})
				.then((response) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						if(this.$resize && this.$mq.above(600)){
							btnSubmitText.innerHTML = this.$t('btn-submit.success')
						}
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(() => {
							btnSubmit.classList.remove('completed')
							btnSubmit.disabled = false
							if(this.$resize && this.$mq.above(600)){
								btnSubmitText.innerHTML = this.$t('salary.download.download_pdf')
							}
						}, 500);
						
						const file = new Blob([response.data], {type: 'application/pdf'});
						let fileURL = window.URL.createObjectURL(file);

						let fileName = response.headers['content-disposition'].split('filename=')[1].replace(/"/g, '');
						let fileLink = document.createElement('a');
						fileLink.href = fileURL;
						fileLink.setAttribute( 'download', fileName );
						document.body.appendChild(fileLink);

						fileLink.click();
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('salary.download.download_pdf')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				})
            },
            donwloadInsurersPdf() {
				var buttonName = `buttonDownload`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				if(this.$resize && this.$mq.above(600)){
					btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				}

                axios.get(`${this.$auth.user().instance.id}/payroll-reports/export/pdf/coinsureds`, 
				{
                    params: this.data.filters,
                	responseType: 'blob'
				})
				.then((response) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						if(this.$resize && this.$mq.above(600)){
							btnSubmitText.innerHTML = this.$t('btn-submit.success')
						}
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(() => {
							btnSubmit.classList.remove('completed')
							btnSubmit.disabled = false
							if(this.$resize && this.$mq.above(600)){
								btnSubmitText.innerHTML = this.$t('salary.download.download_pdf')
							}
					}, 500);

					const file = new Blob([response.data], {type: 'application/pdf'});
					let fileURL = window.URL.createObjectURL(file);

					let fileName = response.headers['content-disposition'].split('filename=')[1].replace(/"/g, '');
					let fileLink = document.createElement('a');
					fileLink.href = fileURL;
					fileLink.setAttribute( 'download', fileName );
					document.body.appendChild(fileLink);

					fileLink.click();
					}, 300);
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('salary.download.download_pdf')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				})
            },
			cancePdfPreview(){
				this.$emit("close_modal");
			},
            cancelClose(){
                document.getElementById('submodal_confirm_close').classList.remove('active')
                setTimeout(() => {
                    document.getElementById('submodalContainer').classList.remove('active')
                }, 200);
            }
		}
	};
</script>

<style lang="scss">
#modal_pdf {
    max-width: 80%;
}
.actions-page {
    &.pdf {
        display: flex;
        justify-content: flex-end;
    }
}
div.input-box.bg-white.role > input.no-icon{
    padding: 0 45px 0 15px;
}
.form-submit.role > button{
    width: 50%;
}
</style>