<template>
	<div class="modal-tbf progress-type-submodal">
		<div class="container-modal opacity-page" v-if="loaded">
			<div class="header-modal">
				<div class="title">{{ $t('responsibility.progress_type_title') }}</div>
				<div class="actions">
					<button class="btn-tbf white only-icon close-btn" @click="closeModal"><icon-close class="icon-close" /></button>
				</div>
			</div>

			<div class="info-content">
				<div class="container-content-modal">
					<div class="form-section">
						<div class="input-group w-100" v-bind:class="{has_error: $v.progress_type.type.$error}">
							<div class="label-header">
								<label class="label">{{ $t('responsibility.progress.type_label') }}</label>
								<div v-if="$v.progress_type.type.$error" class="error-msg">{{ $t('validator.required') }}</div>
							</div>

							<div class="input-box bor-0">
								<div class="progress-types" v-bind:class="{has_error: $v.progress_type.type.$error}">
									<div class="item-progress" v-for="item in optionsProgress" @click="progress_type.type = item" v-bind:class="{active: progress_type.type == item}">
										<div class="icon">
											<img :src="'/build/images/progress-type-' + item + '-active.svg'" class="active-icon">
											<img :src="'/build/images/progress-type-' + item + '.svg'" class="simple-icon">
										</div>
										<div class="text">
											<div class="placeholder-text">{{ $t('responsibility.progress.type_' + item) }}</div>
											<!-- <div class="placeholder-desc">{{ $t('responsibility.progress.type_ex_' + item) }}</div> -->
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="input-group w-100">
							<div class="label-header">
								<label class="label">{{ $t('responsibility.progress.unity_label') }}</label>
                                <div v-if="$v.progress_type.unity.$error" class="error-msg">{{ $t('validator.required') }}</div>
                            </div>

							<div class="input-box" v-bind:class="{has_error: $v.progress_type.unity.$error}">
								<div class="icon-left"><icon-unity /></div>
								<multiselect 
								v-model="progress_type.unity"
								:options="optionsUnits"
								class="select-tags-tbf"
								:allow-empty="false"
								:show-labels="false"
								label="label"
								track-by="value"
								:tag-placeholder="$t('responsibility.progress.grow-label-tag')"
								@tag="addUnity($event)"
								:taggable="true"
								:max-height="170"
								>
									<template slot="placeholder" slot-scope="props"><span class="text">{{$t('responsibility.progress.grow-label')}}</span></template>
									<template slot="noResult">{{$t('general.no_results')}}</template>
	                        		<template slot="noOptions">{{$t('general.empty_list')}}</template>
								</multiselect>
							</div>
						</div>

						<div class="input-group w-100">
							<div class="label-header">
								<label class="label">{{ $t('responsibility.progress.to_label') }}</label>
                                <div v-if="$v.progress_type.to.$error" class="error-msg">{{ $t('validator.required') }}</div>
                            </div>

							<div class="input-box" v-bind:class="{has_error: $v.progress_type.to.$error || validationLogic}">
								<div class="icon-left"><icon-end-value /></div>
                                <div class="icon-right" v-if="progress_type.to != ''" @click.stop="progress_type.to = ''"><icon-close class="icon-clear" /></div>
								<input type="text" v-model="progress_type.to" placeholder="15" class="input-text">
							</div>
						</div>
					</div>
					<div class="form-submit">
						<div class="error-msg" v-if="validationLogic != ''">{{ $t('validator.' + validationLogic) }}</div>
						<div class="actions">
							<button class="btn-tbf grey center" @click="closeModal">
								<div class="loader"></div>
								<div class="text">{{$t('general.cancel')}}</div>
							</button>
							<button id='buttonUpdateProgressData' class="btn-tbf blue center" @click="saveModifications">
								<div class="loader"></div>
								<div class="text">{{ $t('general.update') }}</div>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconClose from '../Icons/Close'
	import IconStartValue from '../Icons/StartValue'
	import IconEndValue from '../Icons/EndValue'
	import IconUnity from '../Icons/Unity'
	import { required, decimal } from 'vuelidate/lib/validators'
	export default {
		data() {
			return {
                loaded: false,
			  	optionsProgress: ['grow', 'decrease'],
			  	progress_type: {
                	type: '',
                	unity: '',
                	to: ''
                },
                optionsUnits: [],
                validationLogic: ''
			};
		},
		props:{
			progressData: String
		},
		components: {
			IconClose,
			IconStartValue,
			IconEndValue,
			IconUnity,
		},
		validations: {
			progress_type: {
				type: {required},
				unity: {required},
				to: {required, decimal}
			}
		},
		async mounted() {
			await this.getUnities()

            if(!(Object.keys(JSON.parse(this.progressData)).length === 0 && JSON.parse(this.progressData).constructor === Object)){
				this.progress_type = JSON.parse(this.progressData)

				var findUnit = this.optionsUnits.find(el => el.value == this.progress_type.unity);
				if(findUnit){
					this.progress_type.unity = findUnit
				}else{
					var newUnity = this.progress_type.unity;
					this.optionsUnits.push({label: newUnity, value: newUnity})
					this.progress_type.unity = {label: newUnity, value: newUnity}
				}
			}
		},
		methods: {
			async getUnities() {
				await axios.get('/role-responsibilities/unity/list')
				.then(({data}) => {
					this.optionsUnits = data.data
				})
				.finally(() => {
					this.loaded = true
					setTimeout(() => {
						$('.progress-type-submodal .opacity-page').addClass('show')
					}, 0)
				})
			},
			saveModifications(){
				var buttonName = `buttonUpdateProgressData`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				this.$v.$touch()

				if(!this.$v.$invalid) {
					this.progress_type.unity = this.progress_type.unity.value
					this.$emit("submitAction", JSON.stringify(this.progress_type));
				} else {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
			addUnity(newUnity){
				console.log(newUnity)

				this.optionsUnits.push({label: newUnity, value: newUnity})
				this.progress_type.unity = {label: newUnity, value: newUnity}
			},
			closeModal(){
				this.$emit("close_modal");
			},
		}
	};
</script>