<template>
	<div class="modal_info modal_error">
		<div class="header-submodal">
			<div class="image"><img src="/build/images/Warning.svg"></div>
			<div class="title">{{ $t('update.title') }}</div>
		</div>
		<div class="description-modal">
			{{ data.data }}
		</div>

		<div class="button-actions">
			<button class="btn-tbf white center" @click="cancelUpdate">
				<div class="text">{{ $t('general.close') }}</div>
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		data() {
			return {
			};
		},
		props: {
			data: Object
		},
		components: {
		},
		mounted() {
		},
		methods: {
			cancelUpdate(){
				this.$emit("close_modal");
			},
		}
	};
</script>