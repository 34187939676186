<template>
	<div class="modal_info modal_delete">
		<div class="header-submodal">
			<div class="image"><img src="/build/images/trash-icon.svg"></div>
			<div class="title">{{ $t('delete.title') }}</div>
		</div>
		<div class="description-modal">
			{{ $t('delete.question',{text: $t('delete.' + data.type)}) }}
			{{ $t('delete.atention') }}
		</div>

		<div class="button-actions">
			<button class="btn-tbf white center" @click="cancelDelete">
				<div class="text">{{ $t('general.cancel') }}</div>
			</button>
			<button id="buttonSubmitDelete" class="btn-tbf red center" @click="submitDelete">
				<div class="loader"></div>
				<div class="text">{{ $t('delete.delete') }}</div>
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		data() {
			return {
			};
		},
		props: {
			data: Object
		},
		components: {
		},
		mounted() {

		},
		methods: {
			cancelDelete(){
				this.$emit("close_modal");
			},
			submitDelete(){
				var buttonName = `buttonSubmitDelete`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				switch (this.data.type) {
					case 'user':
						this.deleteUser()
						break;
					case 'role':
						this.deleteRole()
						break;
					case 'work_schedule':
						this.deleteWorkSchedule() 
						break;
					case 'department':
						this.deleteDepartment()
						break;
					case 'work_station':
						this.deleteWorkStation()
						break;
					case 'skill':
						this.deleteSkill()
						break;
					case 'entry_congratulation':
						this.deleteCongratulation()
						break;
					case 'entry_warning':
						this.deleteWarning()
						break;
					case 'entry_evaluation':
						this.deleteEvaluation()
						break;
					case 'contract':
						this.deleteContract()
						break;
					case 'coinsured':
						this.deleteCoinsured()
						break;
					case 'sick-leaves':
						this.deleteSickLeaves()
						break;
					case 'vacation':
						this.deleteVacation()
						break;
					case 'benefit':
						this.deleteBenefit() 
						break;
					case 'suspension':
						this.deleteSuspension()
						break;
					case 'detachment':
						this.deleteDetachment()
						break;
					case 'retention':
						this.deleteRetention()
						break;
					case 'tickets':
						this.deleteTickets()
						break;
					case 'history': 
						this.deleteHistory()
						break;
					case 'bonus':
						this.deleteBonusNomenclature() 
						break;
					case 'retention-nomenclature':
						this.deleteRetentionNomenclature()
						break;
					case 'bank':
						this.deleteBankNomenclature()
					case 'responsibility':
						this.deleteResponsibilty()
						break;
					case 'vacantJob':
						this.deleteVacantJob()
						break;
					case 'vacation_day':
						this.deleteVacationDay()
						break;
				}
			},
			deleteUser(){
				axios.delete(`/users/${this.data.model.slug}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			deleteRole() {
				axios.delete(`/roles/${this.data.model.id}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			deleteWorkSchedule(){
				axios.delete(`/work-schedules/${this.data.model.id}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			deleteDepartment(){
				axios.delete(`/departments/${this.data.model.slug}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			deleteWorkStation(){
				axios.delete(`/workstations/${this.data.model.id}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			deleteSkill(){
				axios.delete(`/skills/${this.data.model.id}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			deleteCongratulation(){
				axios.delete(`/reviews/${this.data.model.id}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			deleteWarning(){
				axios.delete(`/reviews/${this.data.model.id}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			deleteEvaluation(){
				axios.delete(`/user-evaluations/${this.data.model.id}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			deleteContract() {
				axios.delete(`/contracts/${this.data.model.id}`).then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			deleteResponsibilty() {
				axios.delete(`/role-responsibilities/${this.data.model.id}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			deleteSuspension() {
				axios.delete(`/contract-suspensions/${this.data.model.id}`).then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			deleteVacantJob() {
				axios.delete(`/vacant-jobs/${this.data.model.slug}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			deleteDetachment() {
				axios.delete(`/contract-detachments/${this.data.model.id}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			deleteBenefit() {
				axios.delete(`/contract-bonuses/${this.data.model.id}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			deleteVacation() {
				axios.delete(`/annual-leaves/${this.data.model.id}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			deleteCoinsured() {
				axios.delete(`/coinsureds/${this.data.model.id}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			deleteSickLeaves() {
				axios.delete(`/sick-leaves/${this.data.model.id}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			deleteRetention() {
				axios.delete(`/contract-retentions/${this.data.model.id}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			deleteTickets() {
				axios.delete(`/payroll-tickets/${this.data.model.id}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			deleteHistory() {
				axios.delete(`/contract-modifications/${this.data.model.id}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
						if(error.response.data.errors.popup) {
							this.$emit("close_modal");
							setTimeout(()=>{
								this.$root.$emit('open_modal', 'error', {data: error.response.data.errors.popup[0]}, false);
							}, 1000)
						}
					}
				})
			},
			deleteBonusNomenclature() {
				axios.delete(`/bonuses/${this.data.model.id}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
						if(error.response.data.errors.in_use) {
							this.$emit("close_modal");
							setTimeout(()=>{
								this.$root.$emit('open_modal', 'error', {data: error.response.data.errors.in_use[0]}, false);
							}, 1000)
						}
					}
				})
			},
			deleteRetentionNomenclature() {
				axios.delete(`/retentions/${this.data.model.id}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			deleteBankNomenclature() {
				axios.delete(`/banks/${this.data.model.id}`).then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			deleteVacationDay() {
				axios.delete(`/vacation-days/${this.data.model.id}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			refreshPage(){
				var buttonName = `buttonSubmitDelete`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				switch (this.data.from) {
					case 'index_users':
						this.$root.$emit('refreshUsersIndex')
						break;
					case 'show_user':
						this.$router.push({ name: 'users' })
						break;
					case 'index_roles':
						this.$root.$emit('refreshRolesIndex')
						break;
					case 'show_role':
						this.$router.push({ name: 'roles' })
						break;
					case 'index_work_schedules':
						this.$root.$emit('refreshWrokSchedulesIndex')
						break;
					case 'index_departments':
						this.$root.$emit('refreshDepartmentsIndex')
						break;
					case 'index_work_stations':
						this.$root.$emit('refreshWorkStationsIndex')
						break;
					case 'index_skills':
						this.$root.$emit('refreshSkillsIndex')
						break;
					case 'index_congratulations':
						this.$root.$emit('refreshCongratulationsIndex')
						break;
					case 'index_evaluations':
						this.$root.$emit('refreshEvaluationsIndex')
						break;
					case 'index_warnings':
						this.$root.$emit('refreshWarningsIndex')
						break;
					case 'index_contracts':
						this.$root.$emit('refreshContractsIndex')
						break;
					case 'show_contracts':
						this.$router.push({name: 'contracts'})
						break;
					case 'index_coinsured':
						this.$root.$emit('refreshCoinsuredIndex')
						break;
					case 'index_sick-leaves':
						this.$root.$emit('refreshSickLeavesIndex')
						break;
					case 'index_vacation':
						this.$root.$emit('refreshVacationIndex')
						break;
					case 'index_benefit':
						this.$root.$emit('refreshBenefitIndex')
						break;
					case 'index_suspension':
						this.$root.$emit('refreshSuspensionIndex')
						break;
					case 'index_detachment':
						this.$root.$emit('refreshDetachmentIndex')
						break;
					case 'index_retention':
						this.$root.$emit('refreshRetentionIndex')
						break;
					case 'index_tickets':
						this.$root.$emit('refreshTicketsIndex')
						break;
					case 'index_history':
						this.$root.$emit('refreshHistoryIndex')
						break;
					case 'nomenclature_bonus':
						this.$root.$emit('refreshBonusNomenclature')
						break;
					case 'nomenclature_retention':
						this.$root.$emit('refreshRetentionsNomenclature')
						break;
					case 'nomenclature_bank':
						this.$root.$emit('refreshBanksNomenclature')
						break;
					case 'chart':
						this.$root.$emit('refreshCompanyChart')
						break;
					case 'role_list':
						this.$root.$emit('refreshShowRole')
						break;
					case 'index_vacant_jobs':
						this.$root.$emit('refreshVacantJobsIndex')
						break;
					case 'show_vacant_job':
						this.$router.push({ name: 'vacant-jobs' })
						break;
					case 'index_vacation_days_calendar':
						this.$root.$emit('refresh_vacation_days_calendar')
						break;
					case 'index_vacation_days_list':
						this.$root.$emit('refresh_vacation_days_list')
						break;
				}

				btnSubmitLoader.classList.add('finish')
				setTimeout(()=>{
					btnSubmitText.innerHTML = this.$t('btn-submit.success')
					btnSubmit.classList.add('completed')
					btnSubmitLoader.classList.remove('onProgress', 'finish')
					btnSubmit.classList.remove('loading')
					setTimeout(()=>{
						btnSubmit.classList.remove('completed')
						btnSubmitText.innerHTML = this.$t('delete.delete')
						this.$emit("close_modal");
					}, 1000)
				}, 300)
			}
		}
	};
</script>