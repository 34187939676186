<template>
	<div class="modal_info modal_evaluate_responsibility">
		<template v-if="loaded">
			<div class="header-submodal">
				<div class="title">{{ responsibility.name }}</div>
			</div>

			<div class="subheader-data">
				<div class="item" v-if="data.date">
					{{ $t('general.date') }}: {{ data.date | moment('DD MMMM YYYY') }}
				</div>
				<div class="item">
					{{ $t('roles.role') }}: {{ responsibility.role_name }}
				</div>
			</div>

			<div class="description-modal" v-html="responsibility.description" v-if="responsibility.description"></div>

			<div class="target" v-if="responsibility.type == 'value'">
				<div class="label">{{ $t('responsibility.target_responsibility') }}: </div>
				<div class="value">{{ $t('responsibility.progress_type_text.' + responsibility.progress_type, { to: parseFloat(responsibility.target), unit: responsibility.unity}  ) }}</div>
			</div>

			<div class="form-section" v-if="responsibility.type && data.from == 'user'" :class="{disabled: responsibility.is_deleted}">
				<div class="input-group w-100" v-if="responsibility.type == 'value'" :class="{disabled: free_day}">
					<div class="label-header">
						<label class="label">{{$t('responsibility.value')}}</label>
						<div v-if="$v.value.$error" class="error-msg">{{ !$v.value.decimal ? $t('validator.required_decimal') : $t('validator.required') }}</div>
					</div>
					<div class="input-box bg-white progress-input" v-bind:class="{has_error: $v.value.$error}">
						<div class="progress-left">
							<icon-progress />
							<div class="value" :class="{positive: checkPositiveClass(), negative: checkNegativeClass() }" v-if="valueDebounce">
								<span class="plus" v-if="parseFloat(parseFloat(parseFloat(valueDebounce).toFixed(2) - parseFloat(responsibility.target).toFixed(2)).toFixed(2)) > 0">+</span>{{ parseFloat(parseFloat(parseFloat(valueDebounce).toFixed(2) - parseFloat(responsibility.target).toFixed(2)).toFixed(2)) }}
							</div>
						</div>
						<input type="number" :placeholder="$t('responsibility.value_placeholder')" class="input-text no-icon" v-model="value" v-debounce:300="updateValue" :disabled="free_day || responsibility.is_deleted">
					</div>
				</div>

				<div class="input-group w-100 checkboxes-group"  v-if="responsibility.type == 'check'" :class="{disabled: free_day}">
					<div class="box-checkbox" v-bind:class="{has_error: $v.complete.$error, selected: complete === 0}">
						<label class="checkbox-tbf">
							<input type="radio" :value="0" v-model="complete" :disabled="free_day || responsibility.is_deleted">
							<img src="/build/images/mood-uncertain-bw.svg" class="inactive">
							<img src="/build/images/mood-trs-uncertain.svg" class="active">
							<span class="text-checkbox">{{ $t('responsibility.unfinished') }}</span>
						</label>
					</div>
					<div class="box-checkbox" v-bind:class="{has_error: $v.complete.$error, selected: complete === 1}">
						<label class="checkbox-tbf">
							<input type="radio" :value="1" v-model="complete" :disabled="free_day || responsibility.is_deleted">
							<img src="/build/images/mood-sure-bw.svg" class="inactive">
							<img src="/build/images/mood-trs-sure.svg" class="active">
							<span class="text-checkbox">{{ $t('responsibility.finished') }}</span>
						</label>						
					</div>
				</div>

				<div class="input-group w-100 mr-top" v-if="checkShowWhyNot()">
					<div class="label-header">
						<label class="label">{{$t('responsibility.why_not')}}</label>
						<div v-if="$v.why_not.$error" class="error-msg">{{ $t('validator.required') }}</div>
					</div>
					<div class="input-box" v-bind:class="{has_error: $v.why_not.$error}">
						<textarea v-model="why_not" :disabled="responsibility.is_deleted"></textarea>
					</div>
				</div>

				<div class="input-group w-100 mr-top free-day">
					<label class="checkbox-tbf">
						<input type="checkbox" value="1" v-model="free_day" :disabled="responsibility.is_deleted">
						<span class="checkmark"></span>
						<span class="text-checkbox">{{ $t('responsibility.free_day') }}</span>
					</label>						
				</div>
			</div>

			<div class="msg-error-is-deleted" v-if="responsibility.is_deleted">{{ $t('responsibility.is_deleted') }}</div>

			<div class="button-actions">
				<button class="btn-tbf center" :class="[data.from == 'user' ? 'grey' : 'blue']" @click="closeModal">
					<div class="text">{{ $t('general.close') }}</div>
				</button>
				<button id="buttonUpdate" class="btn-tbf blue center" @click="!responsibility.user_responsibility_log ? saveUpdates('create') : saveUpdates('update')" v-if="data.from == 'user' && !responsibility.is_deleted">
					<div class="loader"></div>
					<div class="text">{{ $t('general.update') }}</div>
				</button>
			</div>
		</template>
		<loader-show-responsibility v-else />
	</div>
</template>

<script type="text/javascript">
	import { required, requiredIf, decimal } from 'vuelidate/lib/validators'
	import IconProgress from '../Icons/Progress'
	import IconClose from '../Icons/Close'
	import LoaderShowResponsibility from '@/components/PagesLoaders/ShowResponsibility'

	export default {
		data() {
			return {
				loaded: false,
				value: '',
				valueDebounce: '',
				complete: '',
				why_not: '',
				free_day: false,
				responsibility: {}
			};
		},
		props: {
            data: Object
        },
        components: {
        	IconProgress,
        	IconClose,
        	LoaderShowResponsibility
        },
        validations: {
        	why_not: {required: requiredIf( function() { return this.checkShowWhyNot(); } )},
			value: {
				required: requiredIf( function() { return this.responsibility.type == 'value' && !this.free_day } ),
				decimal
			},
			complete: {
				required: requiredIf( function() { return this.responsibility.type == 'check' && !this.free_day } )
			}
		},
		mounted() {
			this.getDataResponsibility()
		},
		methods: {
			getDataResponsibility() {
				var paramsHeader = {}

				if(this.data.from == 'user'){
					paramsHeader.user_id = this.data.userId
					paramsHeader.date = this.data.date
				}

				axios.get(`/role-responsibilities/${this.data.id}/show`, { params: paramsHeader })
				.then(({data}) => {
					this.responsibility = data.data

					if(this.responsibility.user_responsibility_log) {
						this.free_day = this.responsibility.user_responsibility_log.free_day ? true : false
						this.complete = this.responsibility.user_responsibility_log.completed
						this.value = parseFloat(parseFloat(this.responsibility.user_responsibility_log.value).toFixed(2))
						this.valueDebounce = parseFloat(parseFloat(this.responsibility.user_responsibility_log.value).toFixed(2))
						this.why_not = this.responsibility.user_responsibility_log.why_not ? this.responsibility.user_responsibility_log.why_not : ''
					}
				})
				.finally(() => {
					this.loaded = true
				})
			},
			closeModal(){
				this.$emit("close_modal");
			},
			saveUpdates(type){
				var buttonName = `buttonUpdate`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				
				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				this.$v.$touch()

				if(!this.$v.$invalid){
					let objData = {
						role_responsibility_id: this.data.id,
						date: this.data.date,
						free_day: this.free_day ? 1 : 0,
						why_not: this.checkShowWhyNot() ? this.why_not : '',
						value: this.value ? this.value : 0
					}

					if(!this.free_day) {
						if(this.responsibility.type == 'check') {
							objData.completed = this.complete
						}
					}

					if(type == 'create'){
						this.createLog(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}else if(type == 'update'){
						this.updateLog(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}
				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
			createLog(objData, btnSubmit, btnSubmitText, btnSubmitLoader) {
				axios.post(`/${this.data.userSlug}/user-responsibility-logs/store`, objData)
				.then(({data}) => {
					this.$root.$emit('updateEventCalendar', {id: data.data.id, start: data.data.start, className: data.data.className})
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							this.$emit("close_modal");
						}, 500)
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.add')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
			},
			updateLog(objData, btnSubmit, btnSubmitText, btnSubmitLoader) {
				axios.patch(`/user-responsibility-logs/${this.responsibility.user_responsibility_log.id}`, objData)
				.then(({data}) => {
					this.$root.$emit('updateEventCalendar', {id: data.data.id, start: data.data.start, className: data.data.className})
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							this.$emit("close_modal");
						}, 500)
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.add')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
			},
			checkShowWhyNot(){
				if(this.responsibility.type == 'check' && this.complete === 0) {
        			return true
        		}else if(this.responsibility.type == 'value' && this.valueDebounce && this.checkNegativeClass()) {
        			return true
        		} else if (this.free_day) {
        			return true
        		} else {
        			return false
        		}
			},
			updateValue(val, e){
				this.valueDebounce = val
			},
			checkPositiveClass() {
				if(this.responsibility.progress_type == 'grow') {
					return parseFloat(this.valueDebounce) - parseFloat(this.responsibility.target) >= 0
				} else {
					return parseFloat(this.valueDebounce) - parseFloat(this.responsibility.target) <= 0
				}
			},
			checkNegativeClass() {
				if(this.responsibility.progress_type == 'grow') {
					return parseFloat(this.valueDebounce) - parseFloat(this.responsibility.target) < 0
				} else {
					return parseFloat(this.valueDebounce) - parseFloat(this.responsibility.target) > 0
				}
			}
		}
	};
</script>