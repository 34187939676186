<template>
	<div class="form-template">
		<slot></slot>
		<div class="overflow-modal" style="position: fixed" v-if="show_overflow">
			<div class="bg-modals" @click="closeModals()"></div>
            <modal-role id="modal_role" v-if="show_modal.role" :data="data.role" @close_modal="closeModal('role')" />
			<modal-workstation id="modal_workstation" v-if="show_modal.workstation" :data="data.workstation" @close_modal="closeModal('workstation')" />
			<modal-department id="modal_department" v-if="show_modal.department" :data="data.department" @close_modal="closeModal('department')"/>
			<modal-skill id="modal_skill" v-if="show_modal.skill" :data="data.skill" @close_modal="closeModal('skill')"/>
            <medical-released-income-bases id="modal_medical" v-if="show_modal.medical" :data="data.medical" @close_modal="closeModal('medical')" />
            <info-error id="modal_error" v-if="show_modal.error" :data="data.error" @close_modal="closeModal('error')"/>
            <modal-benefit id="modal_benefit" v-if="show_modal.benefit" :data="data.benefit" @close_modal="closeModal('benefit')" />
            <modal-skill id="modal_skill" v-if="show_modal.skill" :data="data.skill" @close_modal="closeModal('skill')"/>
            <modal-vacant-job-duration id="modal_vacant_job_duration" v-if="show_modal.vacant_job_duration" :data="data.vacant_job_duration" @close_modal="closeModal('vacant_job_duration')"/>
        </div>
	</div>
</template>

<script>
    import ModalRole from "../Modals/Role"
	import ModalWorkstation from "../Modals/Workstation"
	import ModalDepartment from "../Modals/Department"
	import ModalSkill from "../Modals/Skill"
    import MedicalReleasedIncomeBases from "../Modals/MedicalReleasedIncomeBases.vue"
    import InfoError from "../Modals/InfoError"
    import ModalBenefit from "../Modals/Benefit"
	import ModalVacantJobDuration from "../Modals/VacantJobDuration"

    export default {
        data() {
            return {
                show_overflow: false,
                show_modal: {
					role: false,
                    department: false,
                    workstation: false,
                    skill: false,
                    medical: false,
                    error: false,
                    benefit: false
                },
                data: {
                    department: {},
                    medical: {},
                    error: {},
                    benefit: {},
                    vacant_job_duration: {}
                },
                item: {}
            }
        },
        components: {
            ModalRole,
			ModalWorkstation,
			ModalDepartment,
			ModalSkill,
            MedicalReleasedIncomeBases,
            InfoError,
            ModalBenefit,
            ModalVacantJobDuration
        },
        mounted(){
            this.$root.$on("open_modal", (type, data = false) => {
                this.active_modal = type
                this.data[type] = data ? data : ''

                setTimeout(() => {
                    this.show_overflow = true
                    this.show_modal[type] = true
                    setTimeout(() => {
                        $('.overflow-modal').addClass('show')
                        $("#modal_" + type).addClass("active")
                    }, 0);
                }, 0);
            })
        },
        methods:{ 
            closeModals(){
                var arrayModalsNeedVerification = ['role', 'workstation', 'department', 'skill']
                if(arrayModalsNeedVerification.includes(this.active_modal)){
                    this.$root.$emit('confirm_close')
                }else{
                    this.closeModal()
                }
            },
            closeModal(type = false){
                var target_modal = type ? type : this.active_modal
                $("#modal_" + target_modal).removeClass("active")

                setTimeout(() => {
                    this.show_modal[target_modal] = false
                    $('.overflow-modal').removeClass('show')
                    setTimeout(() => {
                        this.show_overflow = false;
                    }, 200)
                }, 200);
            },
        }
    }
</script>

<style lang="scss">
    @import "../../styles/templates/form-template.scss";
</style>