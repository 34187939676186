<template>
	<div class="sidebar-settings">
		<div class="top">
			<h1>{{ $t('contracts-navbar.salary') }}</h1>
			<div class="btn-tbf white only-icon close-btn" @click="closeModal"><icon-close /></div>
		</div>
			
		<div class="settings-content">
			<div class="item-menu" @click.stop="closeModal">
				<router-link :to="{name: 'salary-dashboard', params: {data: payroll_date}  }">
					<span class="text">{{$t('salary-navbar.dashboard')}}</span>
				</router-link>
			</div>
			<div class="item-menu" @click.stop="closeModal">
				<router-link :to="{name: 'clocking', params: {data: payroll_date}  }">
					<span class="text">{{$t('salary-navbar.clocking')}}</span>
				</router-link>
			</div>
            <div class="item-menu" @click.stop="closeModal">
				<router-link :to="{name: 'payroll', params: {data: payroll_date}  }">
					<span class="text">{{$t('salary-navbar.salary_state')}}</span>
				</router-link>
			</div>
            <div class="item-menu" @click.stop="closeModal">
				<router-link :to="{name: 'sick-leaves', params: {data: payroll_date}  }">
					<span class="text">{{$t('salary-navbar.sick_leaves')}}</span>
				</router-link>
			</div>
            <div class="item-menu" @click.stop="closeModal">
				<router-link :to="{name: 'other-leaves', params: {data: payroll_date}  }">
					<span class="text">{{$t('salary-navbar.other_leaves')}}</span>
				</router-link>
			</div>
            <div class="item-menu" @click.stop="closeModal">
				<router-link :to="{name: 'tickets', params: {data: payroll_date}  }">
					<span class="text">{{$t('salary-navbar.tickets')}}</span>
				</router-link>
			</div>
            <div class="item-menu" @click.stop="closeModal">
				<router-link :to="{name: 'bonusses', params: {data: payroll_date}  }">
					<span class="text">{{$t('salary-navbar.bonuses')}}</span>
				</router-link>
			</div>
			<div class="item-menu" @click.stop="closeModal">
				<router-link :to="{name: 'others-retentions', params: {data: payroll_date}  }">
					<span class="text">{{$t('salary-navbar.others_retentions')}}</span>
				</router-link>
			</div>
            <div class="item-menu" @click.stop="closeModal">
				<router-link :to="{name: 'retentions', params: {data: payroll_date}  }">
					<span class="text">{{$t('salary-navbar.retentions')}}</span>
				</router-link>
			</div>
            <div class="item-menu" @click.stop="closeModal">
				<router-link :to="{name: 'suspensions', params: {data: payroll_date}  }">
					<span class="text">{{$t('salary-navbar.suspensions')}}</span>
				</router-link>
			</div>
            <div class="item-menu" @click.stop="closeModal">
				<router-link :to="{name: 'detachments', params: {data: payroll_date}  }">
					<span class="text">{{$t('salary-navbar.detachments')}}</span>
				</router-link>
			</div>
			<div class="item-menu" @click.stop="closeModal">
				<router-link :to="{name: 'd112', params: {data: payroll_date}  }">
					<span class="text">{{$t('salary-navbar.d112')}}</span>
				</router-link>
			</div>
			<div class="item-menu" @click.stop="closeModal">
				<router-link :to="{name: 'bank-files', params: {data: payroll_date} }">
					<span class="text">{{$t('salary-navbar.bank_files')}}</span>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconClose from "../Icons/Close"
	export default {
		data() {
			return {
			};
		},
		props:{

		},
		components: {
			IconClose
		},
		async mounted() {
			this.$root.$on('updatePayrollData', (data) => {
				this.payroll_date = data
			})
		},
		methods: {
			closeModal(){
				this.$emit("close_modal");
			},
		}
	};
</script>