import Vue    from 'vue';

import http   from './http'
import store  from './store'
import router from './router'
import config from './config'
import i18n from './i18n'

import JQuery from 'jquery'
window.$ = JQuery

import App from './components/App.vue';

import 'bootstrap'
import "./styles/global.scss"

import {MediaQueries} from 'vue-media-queries';
const mediaQueries = new MediaQueries();
Vue.use(mediaQueries);

Vue.config.productionTip = false;
new Vue({
    el: '#app',
    http: http,
    store: store,
    router: router,
    config: config,
    i18n,
    mediaQueries: mediaQueries,
    render: h => h(App)
});