<template>
	<div class="modal_info modal_show_day_off">
		<div class="header-submodal with-btn">
			<div class="title">{{ $t('days_off.history_user', {name: data.user_name}) }}</div>
			<div class="button-close">
				<div class="btn-tbf white only-icon close-btn" @click="closeModal"><icon-close class="icon-close" /></div>
			</div>
		</div>

		<template v-if="loaded">
			<div class="list-days-off" v-if="listDaysOff.length">
				<div v-for="dayOff in listDaysOff" class="item">
					<div class="name">{{ dayOff.name }}</div>
					<div class="type">{{ $t(`days_off.options_type.${dayOff.type}`) }}</div>
					<div class="interval">
						<div class="total-days">{{ $tc('days_off.days', dayOff.free_days) }}</div>
						<div class="dates" v-if="dayOff.start_date == dayOff.end_date">
							{{ dayOff.start_date | moment('DD.MM.YY') }}
						</div>
						<div class="dates" v-else>
							{{ dayOff.start_date | moment('DD.MM.YY') }} - {{ dayOff.end_date | moment('DD.MM.YY') }}
						</div>
					</div>
				</div>
			</div>
		</template>
		<div class="list-days-off" v-else>
			<div class="placeholder-loader" v-for="n in 3" style="height: 30px; width: 100%; background-color: #f1f2f2; margin-bottom: 15px;"></div>
		</div>

		<infinite-loading :identifier="infiniteId" @infinite="getUserDaysOffList" ref="infiniteLoading"><div slot="spinner"></div><div slot="no-more"></div><div slot="no-results"></div></infinite-loading>
	</div>
</template>

<script type="text/javascript">
	import IconClose from '../Icons/Close'
	import LoaderShowResponsibility from '@/components/PagesLoaders/ShowResponsibility'
	import InfiniteLoading from 'vue-infinite-loading'

	export default {
		data() {
			return {
				loaded: false,
				totalResults: 0,
				listDaysOff: [],
				page: 0,
				infiniteId: 1
			};
		},
		props: {
            data: Object
        },
        components: {
        	IconClose,
        	LoaderShowResponsibility,
        	InfiniteLoading
        },
		mounted() {
			// this.getUserDaysOffList()
		},
		methods: {
			getUserDaysOffList($state) {
				var paramsCall = { 
					view_more: this.page * 10,
					type: ["day_off", "sick_leave"],
					user_ids: [this.data.user_id]
				}

				axios.get(`/${this.$auth.user().instance.id}/vacation-days`, { params: paramsCall })
				.then(({data}) => {
					this.totalResults = data.total_results

					if(data.data.length) {
						this.page++
						this.listDaysOff.push(...data.data)
						setTimeout(() => { $state.loaded() }, 200)
        				if(data.data.length < 10){
        					$state.complete()
        				}
					} else{
						$state.complete()
					}
				})
				.finally(() => {
					this.loaded = true
				})
			},
			closeModal(){
				this.$emit("close_modal");
			}
		}
	};
</script>