<template>
	<div class="main-template">
		<sidebar v-if="$resize && $mq.above(781)"/>
        <sidebar-nomenclature v-if="$resize && $mq.above(781)" />
		<div class="container-tbf">
			<navbar />
			<div class="container-content">
				<slot></slot>
			</div>
            <sidebar-mobile v-if="$resize && $mq.below(780)"/>
		</div>

        <div class="overflow-modal" v-if="show_overflow">
            <div class="bg-modals" @click="closeModals()"></div>
            
            <modal-delete id="modal_delete" v-if="show_modal.delete" :data="data.delete" @close_modal="closeModal('delete')" />
            <modal-cancel-subscription id="modal_cancel_subscription" v-if="show_modal.cancel_subscription" @close_modal="closeModal('cancel_subscription')" />
            <modal-renew-subscription id="modal_renew_subscription" v-if="show_modal.renew_subscription" @close_modal="closeModal('renew_subscription')" />
            <modal-change-plan-confirmation id="modal_change_plan_confirmation" v-if="show_modal.change_plan_confirmation" :data="data.change_plan_confirmation" @close_modal="closeModal('change_plan_confirmation')"/>
            <modal-change-subscription id="modal_change_subscription" v-if="show_modal.change_subscription" @close_modal="closeModal('change_subscription')"/>
            <modal-notifications id="modal_notifications" v-if="show_modal.notifications" @close_modal="closeModal('notifications')"/>
            <modal-salary-mobile id="modal_salary_mobile" v-if="show_modal.salary_mobile" @close_modal="closeModal('salary_mobile')"/>
            <modal-clocking id="modal_clocking" v-if="show_modal.clocking" :data="data.clocking" @close_modal="closeModal('clocking')" />
            <modal-nomenclature id="modal_nomenclature_mobile" v-if="show_modal.nomenclature_mobile" :data="data.nomenclature_mobile" @close_modal="closeModal('nomenclature_mobile')" />
            <modal-mission-vision id="modal_mission_vision" v-if="show_modal.mission_vision" @close_modal="closeModal('mission_vision')" />
            <modal-webinar-tehnic id="modal_webinar_tbf" v-if="show_modal.webinar_tbf" @close_modal="closeModal('webinar_tbf')"/>
            <modal-contact id="modal_contact" v-if="show_modal.contact" @close_modal="closeModal('contact')"/>
            <modal-update id="modal_update" v-if="show_modal.update" :data="data.update" @close_modal="closeModal('update')" />
            <info-error id="modal_error" v-if="show_modal.error" :data="data.error" @close_modal="closeModal('error')"/>
        </div>
	</div>
</template>

<script>
    import Sidebar from '../General/Sidebar.vue'
    import SidebarNomenclature from '../General/SidebarNomenclature.vue'
    import Navbar from '../General/Navbar.vue'
    import ModalChangePlanConfirmation from "../Modals/ChangePlanConfirmation"
    import ModalDelete from "../Modals/Delete"
    import ModalCancelSubscription from "../Modals/CancelSubscription"
    import ModalRenewSubscription from "../Modals/RenewSubscription"
    import ModalNotifications from "../General/SidebarNotifications"
    import ModalChangeSubscription from "../Modals/ChangeSubscription"
    import SidebarMobile from "../General/SidebarMobile"
    import ModalSalaryMobile from "../General/SidebarSalaryMobile"
    import ModalClocking from "../Modals/Clocking"
    import ModalNomenclature from "../General/SidebarNomenclatureMobile"
    import ModalMissionVision from "../Modals/MissionVision"
    import ModalWebinarTehnic from "../Modals/WebinarTehnic"
    import ModalContact from "../Modals/Contact"
    import InfoError from "../Modals/InfoError"
    import ModalUpdate from "../Modals/ConfirmUpdate"

    export default {
        data() {
            return {
                show_overflow: false,
                show_modal: {
                    change_plan_confirmation: false,
                    cancel_subscription: false,
                    delete: false,
                    notifications: false,
                    renew_subscription: false,
                    salary_mobile: false,
                    clocking: false,
                    update: false,
                    nomenclature_mobile: false,
                    mission_vision: false,
                    webinar_tbf: false,
                    contact: false,
                    error: false
                },
                data: {
                    change_plan_confirmation: {},
                    delete: {},
                    clocking: {},
                    update: {},
                    nomenclature_mobile: {},
                    error: {}
                }
            }
        },
        components: {
            Sidebar,
            SidebarNomenclature,
            Navbar,
            ModalChangePlanConfirmation,
            ModalDelete,
            ModalCancelSubscription,
            ModalNotifications,
            ModalRenewSubscription,
            ModalChangeSubscription,
            SidebarMobile,
            ModalSalaryMobile,
            ModalClocking,
            ModalNomenclature,
            ModalMissionVision,
            ModalWebinarTehnic,
            ModalContact,
            InfoError,
            ModalUpdate
        },
        mounted(){
            this.$root.$on("open_modal", (type, data = false) => {
                this.active_modal = type
                this.data[type] = data ? data : {}

                setTimeout(() => {
                    this.show_overflow = true
                    this.show_modal[type] = true
                    setTimeout(() => {
                        $('.overflow-modal').addClass('show')
                        $("#modal_" + type).addClass("active")
                    }, 0);
                }, 0);
            })
        },
        methods: {
            closeModals(){
                this.closeModal()
            },
            closeModal(type = false, closeOverflow = true){
                var target_modal = type ? type : this.active_modal
                $("#modal_" + target_modal).removeClass("active")

                setTimeout(() => {
                    this.show_modal[target_modal] = false

                    if(closeOverflow){
                        $('.overflow-modal').removeClass('show')
                        setTimeout(() => {
                            this.show_overflow = false;
                        }, 200)
                    }
                }, 200);
            }
        }
    }
</script>