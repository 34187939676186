<template>
	<div class="sidebar-settings-tbf">
		<div class="header">{{$t('nomenclature-sidebar.nomenclature')}}</div>
		<div class="main-menu">
			<div class="item-menu">
				<router-link :to="{name: 'nomenclature-benefits' }">
					<span class="text">{{$t('nomenclature-sidebar.benefits')}}</span>
					<div class="arrow"><icon-arrow /></div>
				</router-link>
			</div>
            <div class="item-menu">
				<router-link :to="{name: 'nomenclature-retentions' }">
					<span class="text">{{$t('nomenclature-sidebar.deductions')}}</span>
					<div class="arrow"><icon-arrow /></div>
				</router-link>
			</div>
			<div class="item-menu">
				<router-link :to="{name: 'nomenclature-banks' }">
					<span class="text">{{$t('nomenclature-sidebar.banks')}}</span>
					<div class="arrow"><icon-arrow /></div>
				</router-link>
			</div>
			<div class="item-menu">
				<router-link :to="{name: 'monthly-settings' }">
					<span class="text">{{$t('nomenclature-sidebar.monthly_settings')}}</span>
					<div class="arrow"><icon-arrow /></div>
				</router-link>
			</div>
			<div class="item-menu">
				<router-link :to="{name: 'nomenclature-company' }">
					<span class="text">{{$t('nomenclature-sidebar.company')}}</span>
					<div class="arrow"><icon-arrow /></div>
				</router-link>
			</div>
            <!-- <div class="item-menu">
				<router-link :to="{name: 'nomenclature-contract-types' }">
					<span class="text">{{$t('nomenclature-sidebar.contract_types')}}</span>
					<div class="arrow"><icon-arrow /></div>
				</router-link>
			</div> -->
      <div class="item-menu">
				<router-link :to="{name: 'nomenclature-settings' }">
					<span class="text">{{$t('nomenclature-sidebar.settings')}}</span>
					<div class="arrow"><icon-arrow /></div>
				</router-link>
			</div>
      <div v-if="$auth.user().right == 'master_admin'" class="item-menu">
        <router-link :to="{name: 'nomenclature-import' }">
          <span class="text">{{$t('nomenclature-sidebar.import')}}</span>
          <div class="arrow"><icon-arrow /></div>
        </router-link>
      </div>
		</div>
	</div>
</template>

<script>
    import IconArrow from '../Icons/Arrow.vue'

	export default {
		components: {
            IconArrow
		},
		data() {
			return {
				
			};
		},
    mounted() {
      console.log(this.$auth.user())
    },
    methods: {

		}
	};
</script>

<style lang="scss">
    @import "../../styles/templates/settings-template.scss";
</style>