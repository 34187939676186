<template>
	<div class="sidebar-org">
		<div class="close-button" v-if="$resize && $mq.above(601)">
			<button class="btn-tbf white only-icon" @click="closeModal"><icon-close /></button>
		</div>
		<div class="content-sidebar" v-if="loaded">
			<div class="header">
				<div class="title with-image">
					<div class="avatar">
						<img :src="user.avatar" class="avatar-user" v-if="user.avatar">
						<div class="user-circle" v-else>
							<icon-user/>
						</div>
					</div>
					<div class="text">{{ user.first_name + ' ' + user.last_name }}</div>
				</div>
				<div class="action buttons">
					<router-link :to="{name: 'user-show', params: { slug: user.slug } }" class="btn-edit show"><icon-show /></router-link>
					<router-link :to="{name: 'user-edit', params: { slug: user.slug } }" class="btn-edit"><icon-edit /></router-link>
					<div class="close-button" v-if="$resize && $mq.below(600)">
						<button class="btn-tbf white only-icon" @click="closeModal"><icon-close /></button>
					</div>
				</div>
			</div>

			<div class="actions-header">
				<button class="button-simple-header" @click="$router.push({ name: 'user-show', params: { slug: user.slug }, query: {tab: 'congratulations'} })">
					<div class="text">{{ $t('show_user.add_congratulations') }}</div>
				</button>
				<button class="button-simple-header" @click="$router.push({ name: 'user-show', params: { slug: user.slug }, query: {tab: 'warnings'} })">
					<div class="text ">{{ $t('show_user.add_warnings') }}</div>
				</button>
				<button class="button-simple-header" @click="$router.push({ name: 'user-show', params: { slug: user.slug }, query: {tab: 'assessments'} })">
					<div class="text ">{{ $t('show_user.add_assessments') }}</div>
				</button>
			</div>

			<div class="box-label-text" v-if="user.roles.length">
				<div class="label">{{ $t('general.roles') }}</div>
				<div class="text">{{ user.roles.map(a => a.name).join(", ") }}</div>
			</div>

			<div class="box-label-text" v-if="user.departments.length">
				<div class="label">{{ $t('profile.departments') }}</div>
				<div class="text">{{ user.departments.join(", ") }}</div>
			</div>

			<div class="accordion-sidebar" v-if="soft_objective">
				<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapsePromiseObjective">
					<div class="icon"><div class="circle-status" :class="{red: ['not_completed', 'not_set'].includes(promiseObjective.status), yellow: ['pending'].includes(promiseObjective.status), green: ['completed'].includes(promiseObjective.status), grey: ['no_key_result', 'not_working'].includes(promiseObjective.status) || !promiseObjective.ready}"></div></div>

					<div class="title">{{ $t('promise_objectives.title') }}</div>
					<div class="actions">
						<button class="arrow-collapse show collapsed" type="button" data-toggle="collapse" data-target="#collapsePromiseObjective" aria-expanded="true">
							<icon-arrow />
						</button>
					</div>
				</div>

				<div class="collapse content-accordion" id="collapsePromiseObjective">
					<div class="box-radius">
						<div class="data">
							<div class="value" v-if="promiseObjective.name">{{ promiseObjective.name }}</div>
							<div class="value" v-else>{{ $t(`promise_objectives.status.${promiseObjective.status}`) }}</div>
						</div>

						<div class="data" v-if="promiseObjective.why_not">
							<div class="label">{{ $t('promise_objectives.why_not') }}</div>
							<div class="value">{{ promiseObjective.why_not }}</div>
						</div>

						<div class="data" v-if="promiseObjective.key_results_names && promiseObjective.key_results_names.length">
							<div class="label">{{ $t('promise_objectives.key_results') }}</div>
							<div class="value">
								<div class="list-item" v-for="kr in promiseObjective.key_results_names">{{ kr }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="accordion-sidebar" v-if="user.roles.find(el => el.responsibilities.length)">
				<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapseResponsibilities">
					<div class="icon"><icon-responsibility /></div>

					<div class="title">{{ $t('user-details.responsibilities') }}</div>
					<div class="actions">
						<button class="arrow-collapse show collapsed" type="button" data-toggle="collapse" data-target="#collapseResponsibilities" aria-expanded="true">
							<icon-arrow />
						</button>
					</div>
				</div>

				<div class="collapse content-accordion" id="collapseResponsibilities">
					<div class="box-radius">
						<div class="data" v-for="role in user.roles">
							<div class="label">{{ role.name }}</div>
							<div class="value" v-for="responsibility in role.responsibilities">{{ responsibility }}</div>
						</div>
					</div>
				</div>
			</div>

			<div class="accordion-sidebar" v-if="user.work_schedule">
				<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapseWorkSchedules">
					<div class="icon"><icon-work-schedule /></div>

					<div class="title">{{ $t('show_user.work-schedule') }}</div>
					<div class="actions">
						<button class="arrow-collapse show collapsed" type="button" data-toggle="collapse" data-target="#collapseWorkSchedules" aria-expanded="true">
							<icon-arrow />
						</button>
					</div>
				</div>

				<div class="collapse content-accordion" id="collapseWorkSchedules">
					<div class="box-radius">
						<div class="inline-data" v-for="schedule in user.work_schedule.schedules">
							<div class="label">{{ $t(`general.${schedule.day}`) }}</div>
							<div class="value">{{ `${schedule.start_time} - ${schedule.end_time}` }}</div>
						</div>
					</div>
				</div>
			</div>

			<div class="accordion-sidebar" v-if="user.skills.length">
				<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapseSkills">
					<div class="icon"><icon-skill /></div>

					<div class="title">{{ $t('show_user.skills') }}</div>
					<div class="actions">
						<button class="arrow-collapse show collapsed" type="button" data-toggle="collapse" data-target="#collapseSkills" aria-expanded="true">
							<icon-arrow />
						</button>
					</div>
				</div>

				<div class="collapse content-accordion" id="collapseSkills">
					<div class="box-radius">
						<div class="inline-data" v-for="skill in user.skills">
							<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" class="label" :delay="{show: 100, hide: 0}" >
								<div class="label">{{ skill.name }}</div>
								<template slot="popover">
									<div class="simple-text">{{ skill.description }}</div>
								</template>
							</v-popover>
							<div class="value">{{ $t(`skill.type.${skill.level}`) }}</div>
						</div>
					</div>
				</div>
			</div>

			<div class="accordion-sidebar" v-if="user.foreign_languages.length">
				<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapseForeignLanguages">
					<div class="icon"><icon-globe /></div>

					<div class="title">{{ $t('show_user.foreign_languages') }}</div>
					<div class="actions">
						<button class="arrow-collapse show collapsed" type="button" data-toggle="collapse" data-target="#collapseForeignLanguages" aria-expanded="true">
							<icon-arrow />
						</button>
					</div>
				</div>

				<div class="collapse content-accordion" id="collapseForeignLanguages">
					<div class="box-radius">
						<div class="data" v-for="foreign_language in user.foreign_languages">
							<div class="label">{{ foreign_language.name }}</div>
							<div class="value">{{ foreign_language.levels.map((el) => { return `${ $t('foreign_languages.' + el['type'])}: ${el['level']}`}).join(", ") }}</div>
						</div>
					</div>
				</div>
			</div>

			<div class="accordion-sidebar">
				<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapsePersonalData">
					<div class="icon"><icon-user /></div>

					<div class="title">{{ $t('show_user.user-details') }}</div>
					<div class="actions">
						<button class="arrow-collapse show collapsed" type="button" data-toggle="collapse" data-target="#collapsePersonalData" aria-expanded="true">
							<icon-arrow />
						</button>
					</div>
				</div>

				<div class="collapse content-accordion" id="collapsePersonalData">
					<div class="box-radius">
						<div class="inline-data">
							<div class="label">{{ $t('profile.rights') }}</div>
							<div class="value">{{ $t(`profile.rights_name.${user.right}`) }}</div>
						</div>
						<div class="inline-data" v-if="user.business_phone">
							<div class="label">{{ $t('profile.phone') }}</div>
							<div class="value">{{ user.phone }}</div>
						</div>
						<div class="inline-data" v-if="user.workstation">
							<div class="label">{{ $t('create-user.workstation') }}</div>
							<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" class="value" :delay="{show: 100, hide: 0}" >
								<div class="value">{{ user.workstation.name }}</div>
								<template slot="popover">
									<div class="simple-text">{{ user.workstation.description }}</div>
								</template>
							</v-popover>
						</div>
						<div class="inline-data" v-if="user.date_of_employment">
							<div class="label">{{ $t('show_user.date-of-employment') }}</div>
							<div class="value">{{ user.date_of_employment | moment('DD MMM YYYY') }}</div>
						</div>
						<div class="inline-data" v-if="user.vacation_days">
							<div class="label">{{ $t('show_user.vacations-days') }}</div>
							<div class="value">{{ user.vacation_days }}</div>
						</div>

						<div class="subtitle-accordion">{{ $t('show_user.personal-data') }}</div>

						<div class="inline-data" v-if="user.business_email">
							<div class="label">{{ $t('show_user.email') }}</div>
							<div class="value">{{ user.business_email }}</div>
						</div>
						<div class="inline-data" v-if="user.business_phone">
							<div class="label">{{ $t('show_user.phone') }}</div>
							<div class="value">{{ user.business_phone }}</div>
						</div>
						<div class="inline-data" v-if="user.date_of_birth">
							<div class="label">{{ $t('show_user.data-of-birth') }}</div>
							<div class="value">{{ user.date_of_birth | moment('DD MMM YYYY') }}</div>
						</div>
						<div class="inline-data" v-if="user.timezone">
							<div class="label">{{ $t('show_user.timezone') }}</div>
							<div class="value">{{ user.timezone }}</div>
						</div>
						<div class="inline-data">
							<div class="label">{{ $t('show_user.married') }}</div>
							<div class="value">{{ user.married ? $t('general.yes') : $t('general.no') }}</div>
						</div>
						<div class="inline-data">
							<div class="label">{{ $t('show_user.childrens') }}</div>
							<div class="value">{{ user.has_children ? $t('general.yes') : $t('general.no') }}</div>
						</div>

						<div class="subtitle-accordion" v-if="user.facebook_link || user.linkedin_link || user.instagram_link || user.twitter_link || user.tiktok_link || user.youtube_link">{{ $t('show_user.social-data') }}</div>
						<div class="inline-data" v-if="user.facebook_link">
							<div class="label">{{ $t('show_user.facebook') }}</div>
							<div class="value"><a target="_blank" :href="user.facebook_link">{{ user.facebook_link }}</a></div>
						</div>
						<div class="inline-data" v-if="user.linkedin_link">
							<div class="label">{{ $t('show_user.linkedin') }}</div>
							<div class="value"><a target="_blank" :href="user.linkedin_link">{{ user.linkedin_link }}</a></div>
						</div>
						<div class="inline-data" v-if="user.instagram_link">
							<div class="label">{{ $t('show_user.instagram') }}</div>
							<div class="value"><a target="_blank" :href="user.instagram_link">{{ user.instagram_link }}</a></div>
						</div>
						<div class="inline-data" v-if="user.twitter_link">
							<div class="label">{{ $t('show_user.twitter') }}</div>
							<div class="value"><a target="_blank" :href="user.twitter_link">{{ user.twitter_link }}</a></div>
						</div>
						<div class="inline-data" v-if="user.tiktok_link">
							<div class="label">{{ $t('show_user.tiktok') }}</div>
							<div class="value"><a target="_blank" :href="user.tiktok_link">{{ user.tiktok_link }}</a></div>
						</div>
						<div class="inline-data" v-if="user.youtube_link">
							<div class="label">{{ $t('show_user.youtube') }}</div>
							<div class="value"><a target="_blank" :href="user.youtube_link">{{ user.youtube_link }}</a></div>
						</div>

						<div class="subtitle-accordion" v-if="user.hobbies.length">{{ $t('show_user.hobbies') }}</div>
						<div class="data hobbies-data" v-if="user.hobbies.length">
							<div class="value">{{ user.hobbies.map((el) => { return el}).join(", ") }}</div>
						</div>
					</div>
				</div>
			</div>

			<div class="accordion-sidebar" v-if="user.files.length">
				<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapseFiles">
					<div class="icon"><icon-file /></div>

					<div class="title">{{ $t('show_user.files') }}</div>
					<div class="actions">
						<button class="arrow-collapse show collapsed" type="button" data-toggle="collapse" data-target="#collapseFiles" aria-expanded="true">
							<icon-arrow />
						</button>
					</div>
				</div>

				<div class="collapse content-accordion" id="collapseFiles">
					<div class="box-radius with-files">
						<div class="files">
							<div class="file" v-for="file in user.files">
								<a class="file-box" :href="file.file_path" target="_blank">
									<div class="icon"><icon-file /></div>
									<div class="name">{{ file.filename }}</div>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconClose from "../../Icons/Close";
	import IconEdit from "../../Icons/Edit";
	import IconSettings from "../../Icons/Settings";
	import IconUser from "../../Icons/User";
	import IconArrow from "../../Icons/Arrow";
	import IconFile from '../../Icons/FilePdf'
	import IconDepartment from "../../Icons/Department";
	import IconSkill from "../../Icons/Skill";
	import IconFiles from "../../Icons/Files";
	import IconGlobe from "../../Icons/Globe";
	import IconWorkSchedule from "../../Icons/WorkSchedule";
	import IconRole from "../../Icons/Role";
	import IconShow from "../../Icons/Show";
	import IconHobbies from "../../Icons/Hobbies";
	import IconResponsibility from "../../Icons/Responsibility";

	export default {
		data() {
			return {
				loaded: false,
				user: {},
				promiseObjective: { ready: false }
			};
		},
		props:{
			data: Object
		},
		computed: {
			soft_objective() {
				return this.$store.getters['applications/getApplication']('Obiective')
			}
		},
		watch: {
			dataSlug: {
				immediate: true,
				deep: true,
				handler (val) {
					this.getUserData()
				}
			}
		},
		components: {
			IconClose,
			IconEdit,
			IconSettings,
			IconUser,
			IconArrow,
			IconFile,
			IconDepartment,
			IconSkill,
			IconFiles,
			IconGlobe,
			IconWorkSchedule,
			IconRole,
			IconShow,
			IconHobbies,
			IconResponsibility
		},
		mounted() {
			if(this.soft_objective) {
				this.getObjectivePromise()
			}
		},
		methods: {
			async getUserData(){
				await axios.get(`users/${this.data.slug}`)
				.then(({data}) => {
					this.user = data.data
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => {
					setTimeout(() => {
						this.loaded = true
					}, 300)
				})			
			},
			closeModal(){
				this.$emit("close_modal");
			},
			getObjectivePromise() {
				axios.get(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/sync-data/${this.data.slug}/check-promise`)
				.then(({data}) => {
					this.promiseObjective = {...data.data, ready: true}
				})
			}
		}
	};
</script>

<style lang="scss">
	.responsibility{
		margin: 0 0 10px 0;
		padding-left: 15px;
		line-height: 1.3;
		margin-top: 10px;
	}
</style>