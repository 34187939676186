<template>
	<div class="full-template">
        <slot></slot>

        <div class="overflow-modal" v-if="show_overflow">
            <div class="bg-modals" @click="closeModals()"></div>
            <modal-company id="modal_company" v-if="show_modal.company" :data="data.company" @close_modal="closeModal('company')"/>
            <modal-user id="modal_user" v-if="show_modal.user" :data="data.user" @close_modal="closeModal('user')"/>
            <modal-department id="modal_department" v-if="show_modal.department" :data="data.department" @close_modal="closeModal('department')"/>
            <modal-delete id="modal_delete" v-if="show_modal.delete" :data="data.delete" @close_modal="closeModal('delete')" />
            <sidebar-org id="modal_sidebar_org" v-if="show_modal.sidebar_org" :data="data.sidebar_org" @close_modal="closeModal('sidebar_org')"/>
            <sidebar-departments id="modal_sidebar_departments" v-if="show_modal.sidebar_departments" @close_modal="closeModal('sidebar_departments')"/>
            <modal-change-subscription id="modal_change_subscription" v-if="show_modal.change_subscription" @close_modal="closeModal('change_subscription')"/>
            <modal-grace-period id="modal_payment_error" v-if="show_modal.payment_error" @close_modal="closeModal('payment_error')"/>
            <sidebar-vacant-job id="modal_sidebar_vacant_job" v-if="show_modal.sidebar_vacant_job" :data="data.sidebar_vacant_job" @close_modal="closeModal('sidebar_vacant_job')"/>
        </div>
	</div>
</template>

<script>
    import SidebarOrg from "../Pages/Company/SidebarOrg"
    import SidebarDepartments from "../Pages/Company/SidebarDepartments"
    import SidebarVacantJob from "../Pages/Company/SidebarVacantJob"
    import ModalCompany from "../Modals/EditCompany"
    import ModalDepartment from "../Modals/Department"
    import ModalUser from "../Modals/User"
    import ModalChangeSubscription from "../Modals/ChangeSubscription"
    import ModalDelete from "../Modals/Delete"
    import ModalGracePeriod from "../Modals/GracePeriod"

    export default {
        data() {
            return {
                show_overflow: false,
                active_modal: '',
                show_modal: {
                    sidebar_org: false,
                    delete: false,
                    department: false,
                    user: false,
                    company: false,
                    payment_error: false,
                    change_subscription: false,
                    sidebar_departments: false
                },
                data: {
                    sidebar_org: {},
                    delete: {},
                    department: {},
                    user: {},
                    company: {}
                }
            }
        },
        components: {
            SidebarOrg,
            SidebarDepartments,
            SidebarVacantJob,
            ModalCompany,
            ModalDepartment,
            ModalUser,
            ModalDelete,
            ModalChangeSubscription,
            ModalGracePeriod
            
        },
        mounted(){
            Intercom('shutdown');
            
            this.$root.$on("open_modal_full_template", (type, data = false) => {
                this.active_modal = type
                this.data[type] = data ? data : ''

                setTimeout(() => {
                    this.show_overflow = true
                    this.show_modal[type] = true
                    setTimeout(() => {
                        $('.overflow-modal').addClass('show')
                        setTimeout(() => {
                            $("#modal_" + type).addClass("active")
                        }, 100);
                    }, 0);
                }, 0);
            })

            this.$root.$on('change_modal', (type) => {
				$("#modal_" + this.active_modal).removeClass("active")
				setTimeout(() => {
					this.show_modal[this.active_modal] = false
					setTimeout(() => {
						this.show_modal[type] = true
						this.active_modal = type
						setTimeout(() => {
							$("#modal_" + type).addClass("active");
						}, 0);
					}, 0);
				}, 200);
            })

            if(this.$auth.check() && this.$auth.user().rights.instance == 'grace_period' && !this.$cookies.get('grace_period_modal')){
                this.$cookies.set('grace_period_modal', true, '1d')
                this.$root.$emit('open_modal', 'payment_error')
            }
        },
        methods: {
            closeModals(){
                var arrayModalsNeedVerification = ['department', 'company']
                if(arrayModalsNeedVerification.includes(this.active_modal)){
                    this.$root.$emit('confirm_close')
                }else{
                    this.closeModal()
                }
            },
            closeModal(type = false){
                var target_modal = type ? type : this.active_modal
                $("#modal_" + target_modal).removeClass("active")

                setTimeout(() => {
                    this.show_modal[target_modal] = false
                    $('.overflow-modal').removeClass('show')
                    setTimeout(() => {
                        this.show_overflow = false;
                    }, 200)
                }, 200);
            }
        }
    }
</script>

<style lang="scss">
    @import "../../styles/templates/full-template.scss";
    @import "../../styles/pages/organigram.scss";
</style>