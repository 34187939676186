<template>
	<div class="modal_info payment_error">
		<div class="header-submodal">
			<div class="title">{{ $t('vacant_jobs.question_delete_job') }}</div>
		</div>
		<div class="description-modal">{{ $t('vacant_jobs.description_delete_job', { vacantJob: data.vacantJob.name }) }}</div>

		<div class="button-actions">
			<button class="btn-tbf red center" @click="deleteVacantJob">
				<div class="text">{{ $t('vacant_jobs.delete') }}</div>
			</button>
			<button class="btn-tbf blue center" @click="closeModal">
				{{ $t('vacant_jobs.keep_active') }}
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		props: {
			data: Object
		},
		mounted() {
			console.log(this.data)
		},
		methods: {
			closeModal(){
				if(this.$route.query.department_id){
					this.$router.push({name: 'company'})
				}else{
					this.$router.push({name: 'user-show', params: { slug: this.data.userSlug }})
				}
			},
			deleteVacantJob(){
				axios.delete(`/vacant-jobs/${this.data.vacantJob.slug}`)
				.then(() => {
					if(this.$route.query.department_id){
						this.$router.push({name: 'company'})
					}else{
						this.$router.push({name: 'user-show', params: { slug: this.data.userSlug }})
					}
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
		}
	};
</script>