<template>
<div class="modal-tbf modal-full-page">
    <div class="container-modal opacity-page" v-if="loaded">
        <div class="header-modal">
            <div class="title">
                {{$t('salary.medical-modal.title')}}
            </div>
            <div class="actions">
                <v-popover v-if="!$route.query.view" class="lock" offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" >
                    <button class="btn-tbf blue only-icon reset-btn" @click="refreshForm()"><icon-update></icon-update></button>
                    <template slot="popover">
                        <div class="simple-text">{{$t('general.reset')}}</div>
                    </template>
                </v-popover>
                
                <button class="btn-tbf white only-icon close-btn" @click="cancelMedical"><icon-close class="icon-close" /></button>
            </div>
        </div>

        <div class="info-content">
            <div class="container-content-modal">
                <div v-for="(n, i) in savedMedical.length" class="form-section">
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-50' : 'w-25'">
                        <div class="label-header">
                            <label class="label">{{$t('salary.medical-modal.month')}}</label>
                        </div>
                        <div class="input-box bg-white role" v-bind:class="{has_error: savedMedical[i].month === ''}">
                            <div class="icon-right" v-if="savedMedical[i].month != '' && !$route.query.view" @click.stop="savedMedical[i].month = ''"><icon-close class="icon-clear" /></div>
                            <input :disabled="$route.query.view == 'true'" @change="savedMedical[i].is_modified = 1" type="number" class="input-text no-icon" oninput="this.value = Math.round(this.value);" v-model.number="savedMedical[i].month">
                        </div>
                    </div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-50' : 'w-25'">
                        <div class="label-header">
                            <label class="label">{{$t('salary.medical-modal.year')}}</label>
                        </div>
                        <div class="input-box bg-white role" v-bind:class="{has_error: savedMedical[i].year === ''}">
                            <div class="icon-right" v-if="savedMedical[i].year != '' && !$route.query.view" @click.stop="savedMedical[i].year = ''"><icon-close class="icon-clear" /></div>
                            <input :disabled="$route.query.view == 'true'" @change="savedMedical[i].is_modified = 1" type="number" class="input-text no-icon" oninput="this.value = Math.round(this.value);" v-model.number="savedMedical[i].year">
                        </div>
                    </div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-50' : 'w-25'">
                        <div class="label-header">
                            <label class="label">{{$t('salary.medical-modal.realised_income')}}</label>
                        </div>
                        <div class="input-box bg-white role" v-bind:class="{has_error: savedMedical[i].realised_income === ''}">
                            <div class="icon-right" v-if="savedMedical[i].realised_income != ''" @click.stop="savedMedical[i].realised_income = ''"><icon-close class="icon-clear" /></div>
                            <input :disabled="$route.query.view == 'true'" @change="savedMedical[i].is_modified = 1" @input="calculateTotal()" type="number" class="input-text no-icon" oninput="this.value = Math.round(this.value);" v-model.number="savedMedical[i].realised_income">
                        </div>
                    </div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-50' : 'w-25'">
                        <div class="label-header">
                            <label class="label">{{$t('salary.medical-modal.realised_days')}}</label>
                        </div>
                        <div class="input-box bg-white role" v-bind:class="{has_error: savedMedical[i].realised_days === ''}">
                            <div class="icon-right" v-if="savedMedical[i].realised_days != ''" @click.stop="savedMedical[i].realised_days = ''"><icon-close class="icon-clear" /></div>
                            <input :disabled="$route.query.view == 'true'" @change="savedMedical[i].is_modified = 1" @input="calculateTotal()" type="number" class="input-text no-icon" oninput="this.value = Math.round(this.value);" v-model.number="savedMedical[i].realised_days">
                        </div>
                    </div>
                </div>
                <div class="form-section">
                    <div v-if="$resize && $mq.above(600)" class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-50' : 'w-50'">
                    </div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-50' : 'w-25'">
                        <div class="label-header">
                            <label class="label">{{$t('salary.medical-modal.total_realised_income')}}</label>
                        </div>
                        <div class="input-box bg-gray role">
                            <input disabled type="text" class="input-text no-icon total" v-model.number="total_income">
                        </div>
                    </div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-50' : 'w-25'">
                        <div class="label-header">
                            <label class="label">{{$t('salary.medical-modal.total_realised_days')}}</label>
                        </div>
                        <div class="input-box bg-gray role">
                            <input disabled type="text" class="input-text no-icon total" v-model.number="total_days">
                        </div>
                    </div>
                </div>
                <div class="form-submit role">
                    <button class="btn-tbf grey center" @click="cancelMedical">
                        <div class="loader"></div>
                        <div class="text">{{$t('general.cancel')}}</div>
                    </button>
					<div class="error-msg" v-show="error_message!=''">{{error_message}}</div>
                    <button v-if="!$route.query.view" id='buttonCreateRole' class="btn-tbf blue center" @click="saveAction('create')">
                        <div class="loader"></div>
                        <div class="text">{{$t('general.add')}}</div>
                    </button>
                </div>
            </div>
        </div>
		<div id="submodalContainer" class="submodal-container" v-if="subModalShow">
			<div class="overlay-submodal"></div>
			<modal-confirm-close id="submodal_confirm_close" v-if="show_confirm_close" @close_modal="closeModalConfirmed" @cancel_close="cancelClose" />
		</div>
	</div>
</div>
</template>

<script type="text/javascript">
import IconClose from '../Icons/Close'
import IconUpdate from '../Icons/Update'
import { required } from 'vuelidate/lib/validators'
import ModalConfirmClose from '../Modals/ConfirmClose'
	export default {
		data() {
			return {
				loaded: false,
				subModalShow: false,
                show_confirm_close: false,
                error_message: '',
                savedMedical: [{month: 0, year: 0, realised_income: 0, realised_days: 0}],
                total_income: 0,
                total_days: 0
			};
		},
        components: {
            IconClose,
            IconUpdate,
			ModalConfirmClose
        },
        validations: {
            savedMedical:[{
                month: { required }
            }]
        },
        props: {
            data: Object
        },
		mounted() {
			setTimeout(() => {
				this.loaded = true
				setTimeout(() => {
					$('.opacity-page').addClass('show')
				}, 0)
			}, 0)
            this.savedMedical = this.data.model
            this.total_income = this.savedMedical.reduce((total, obj) => obj.realised_income + total,0)
            this.total_days = this.savedMedical.reduce((total, obj) => obj.realised_days + total,0)

			this.$root.$on("confirm_close", () => {
                if(this.checkInputs()){
                    this.subModalShow = true
                    this.show_confirm_close = true
                    setTimeout(() => {
                        document.getElementById('submodalContainer').classList.add('active')
                        setTimeout(() => {
                            document.getElementById('submodal_confirm_close').classList.add('active')
                        }, 200);
                    }, 0);
                }else{
                    this.$emit("close_modal");
                }
            });

            this.$root.$on('medicalReleasedIncomeBaseModal', data => {
				this.savedMedical = data
                this.total_income = this.savedMedical.reduce((total, obj) => obj.realised_income + total,0)
                this.total_days = this.savedMedical.reduce((total, obj) => obj.realised_days + total,0)
            })
		},
		methods: {
			cancelMedical(){
				this.$emit("close_modal");
			},
            saveAction(type){
				var buttonName = `buttonCreateRole`
				var btnSubmit = document.getElementById(buttonName);
				btnSubmit.disabled = true

				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
                this.error_message = '';
                if (!$(".input-box.role").hasClass("has_error")){
                    btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('btn-submit.create')
                            this.$root.$emit('medicalReleasedIncomeBase', this.savedMedical);
                            this.$emit("close_modal");
						}, 1000)
					}, 300)
                }else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
			checkInputs(){
                var notEmpty = false

                if(this.name != ''){
                    notEmpty = true
                }

                return notEmpty
            },
            closeModalConfirmed(){
                this.$emit("close_modal");
            },
            cancelClose(){
                document.getElementById('submodal_confirm_close').classList.remove('active')
                setTimeout(() => {
                    document.getElementById('submodalContainer').classList.remove('active')
                    setTimeout(() => {
                        this.subModalShow = false;
                        this.show_confirm_close = false
                    }, 200);
                }, 200);
            },
            calculateTotal() {
                this.total_income = this.savedMedical.reduce((total, obj) => obj.realised_income + total,0)
                this.total_days = this.savedMedical.reduce((total, obj) => obj.realised_days + total,0)
            },
            refreshForm() {
                this.$root.$emit('medicalReleasedIncomeBase', []);
            }
		}
	};
</script>

<style lang="scss">
#modal_role {
    max-width: 500px;
    z-index: 10
}
div.input-box.bg-white.role > input.no-icon{
    padding: 0 45px 0 15px;
}
.form-submit.role > button{
    width: 50%;
}
.input-box{
    .total {
        text-align: center;
    }
}
.reset-btn{
    margin-right: 5px; 
    color: #FFF !important;
}
</style>