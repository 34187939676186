<template>
	<div class="main-template">
		<sidebar v-if="$resize && $mq.above(781)"/>
        <sidebar-settings v-if="$resize && $mq.above(781)" />
		<div class="container-tbf">
			<navbar />
			<div class="container-content">
				<slot></slot>
			</div>
            <sidebar-mobile v-if="$resize && $mq.below(780)"/>
		</div>

        <div class="overflow-modal" v-if="show_overflow">
            <div class="bg-modals" @click="closeModals()"></div>

            <modal-delete id="modal_delete" v-if="show_modal.delete" :data="data.delete" @close_modal="closeModal('delete')" />
            <modal-cancel-subscription id="modal_cancel_subscription" v-if="show_modal.cancel_subscription" @close_modal="closeModal('cancel_subscription')" />
            <modal-renew-subscription id="modal_renew_subscription" v-if="show_modal.renew_subscription" @close_modal="closeModal('renew_subscription')" />
            <modal-change-plan-confirmation id="modal_change_plan_confirmation" v-if="show_modal.change_plan_confirmation" :data="data.change_plan_confirmation" @close_modal="closeModal('change_plan_confirmation')"/>
            <modal-grace-period id="modal_payment_error" v-if="show_modal.payment_error" @close_modal="closeModal('payment_error')"/>
            <modal-change-subscription id="modal_change_subscription" v-if="show_modal.change_subscription" @close_modal="closeModal('change_subscription')"/>
            <modal-notifications id="modal_notifications" v-if="show_modal.notifications" @close_modal="closeModal('notifications')"/>
            <modal-settings-mobile id="modal_settings_mobile" v-if="show_modal.settings_mobile" @close_modal="closeModal('settings_mobile')"/>
            <modal-contact id="modal_contact" v-if="show_modal.contact" @close_modal="closeModal('contact')"/>
            <modal-crud-responsibility id="modal_crud_responsibility" v-if="show_modal.crud_responsibility" :data="data.crud_responsibility" @close_modal="closeModal('crud_responsibility')"/>
            <modal-webinar-tehnic id="modal_webinar_tbf" v-if="show_modal.webinar_tbf" @close_modal="closeModal('webinar_tbf')"/>
            <modal-calendar-list id="modal_calendar_list" v-if="show_modal.calendar_list" :data="data.calendar_list" @close_modal="closeModal('calendar_list')" />
            <modal-crud-day-off id="modal_crud_day_off" v-if="show_modal.crud_day_off" :data="data.crud_day_off" @close_modal="closeModal('crud_day_off')" />
            <modal-show-day-off id="modal_show_day_off" v-if="show_modal.show_day_off" :data="data.show_day_off" @close_modal="closeModal('show_day_off')" />
            <modal-mission-vision id="modal_mission_vision" v-if="show_modal.mission_vision" @close_modal="closeModal('mission_vision')" />
        </div>
	</div>
</template>

<script>
    import Sidebar from '../General/Sidebar.vue'
    import SidebarSettings from '../General/SidebarSettings.vue'
    import Navbar from '../General/Navbar.vue'
    import ModalChangePlanConfirmation from "../Modals/ChangePlanConfirmation"
    import ModalDelete from "../Modals/Delete"
    import ModalGracePeriod from "../Modals/GracePeriod"
    import ModalCancelSubscription from "../Modals/CancelSubscription"
    import ModalRenewSubscription from "../Modals/RenewSubscription"
    import ModalNotifications from "../General/SidebarNotifications"
    import ModalChangeSubscription from "../Modals/ChangeSubscription"
    import SidebarMobile from "../General/SidebarMobile"
    import ModalSettingsMobile from "../General/SidebarSettingsMobile"
    import ModalContact from "../Modals/Contact"
    import ModalCrudResponsibility from "../Modals/CrudResponsibility"
    import ModalWebinarTehnic from "../Modals/WebinarTehnic"
    import ModalCalendarList from "../Modals/CalendarList"
    import ModalCrudDayOff from "../Modals/CrudDayOff"
    import ModalShowDayOff from "../Modals/ShowDayOff"
    import ModalMissionVision from "../Modals/MissionVision"

    export default {
        data() {
            return {
                show_overflow: false,
                show_modal: {
                    change_plan_confirmation: false,
                    cancel_subscription: false,
                    delete: false,
                    notifications: false,
                    renew_subscription: false,
                    contact: false,
                    crud_responsibility: false,
                    webinar_tbf: false,
                    calendar_list: false,
                    crud_day_off: false,
                    show_day_off: false,
                    mission_vision: false,
                    payment_error: false
                },
                data: {
                    change_plan_confirmation: {},
                    delete: {},
                    crud_responsibility: {},
                    calendar_list: {},
                    crud_day_off: {},
                    show_day_off: {}
                },
                active_modal: ''
            }
        },
        components: {
            Sidebar,
            SidebarSettings,
            Navbar,
            ModalChangePlanConfirmation,
            ModalDelete,
            ModalGracePeriod,
            ModalCancelSubscription,
            ModalNotifications,
            ModalRenewSubscription,
            ModalChangeSubscription,
            SidebarMobile,
            ModalSettingsMobile,
            ModalContact,
            ModalCrudResponsibility,
            ModalWebinarTehnic,
            ModalCalendarList,
            ModalCrudDayOff,
            ModalShowDayOff,
            ModalMissionVision
        },
        mounted(){
            this.$root.$on("open_modal", (type, data = false) => {
                this.active_modal = type
                this.data[type] = data ? data : {}

                setTimeout(() => {
                    this.show_overflow = true
                    this.show_modal[type] = true
                    setTimeout(() => {
                        $('.overflow-modal').addClass('show')
                        $("#modal_" + type).addClass("active")
                    }, 0);
                }, 0);
            })

            this.$root.$on("change_modal", (type, data = false) => {
                $("#modal_" + this.active_modal).removeClass("active")

                setTimeout(() => {
                    this.show_modal[this.active_modal] = false
                    setTimeout(() => {
                        this.active_modal = type
                        this.data[type] = data ? data : {}
                        this.show_modal[type] = true
                        setTimeout(() => {
                            $("#modal_" + type).addClass("active")
                        }, 0);
                    }, 0);
                }, 200);
            })
        },
        methods: {
            closeModals(){
                var arrayModalsNeedVerification = ['calendar_list', 'crud_day_off']
                if(arrayModalsNeedVerification.includes(this.active_modal)){
                    this.$root.$emit('confirm_close')
                }else{
                    this.closeModal()
                }
            },
            closeModal(type = false, closeOverflow = true){
                var target_modal = type ? type : this.active_modal
                $("#modal_" + target_modal).removeClass("active")

                setTimeout(() => {
                    this.show_modal[target_modal] = false

                    if(closeOverflow){
                        $('.overflow-modal').removeClass('show')
                        setTimeout(() => {
                            this.show_overflow = false;
                        }, 200)
                    }
                }, 200);
            }
        }
    }
</script>