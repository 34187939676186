<template>
	<div class="sidebar-settings-tbf">
		<div class="header">{{$t('navbar.salary')}}</div>
		<div class="main-menu">
			<div class="item-menu">
				<router-link :to="{name: 'salary-dashboard', params: {data: payroll_date} }">
					<span class="text">{{$t('salary-navbar.dashboard')}}</span>
					<div class="arrow"><icon-arrow /></div>
				</router-link>
			</div>
			<div class="item-menu">
				<router-link :to="{name: 'clocking', params: {data: payroll_date} }">
					<span class="text">{{$t('salary-navbar.clocking')}}</span>
					<div class="arrow"><icon-arrow /></div>
				</router-link>
			</div>
            <div class="item-menu">
				<router-link :to="{name: 'payroll', params: {data: payroll_date} }">
					<span class="text">{{$t('salary-navbar.salary_state')}}</span>
					<div class="arrow"><icon-arrow /></div>
				</router-link>
			</div>
            <div class="item-menu">
				<router-link :to="{name: 'sick-leaves', params: {data: payroll_date} }">
					<span class="text">{{$t('salary-navbar.sick_leaves')}}</span>
					<div class="arrow"><icon-arrow /></div>
				</router-link>
			</div>
            <div class="item-menu">
				<router-link :to="{name: 'other-leaves', params: {data: payroll_date} }">
					<span class="text">{{$t('salary-navbar.other_leaves')}}</span>
					<div class="arrow"><icon-arrow /></div>
				</router-link>
			</div>
            <div class="item-menu">
				<router-link :to="{name: 'tickets', params: {data: payroll_date} }">
					<span class="text">{{$t('salary-navbar.tickets')}}</span>
					<div class="arrow"><icon-arrow /></div>
				</router-link>
			</div>
            <div class="item-menu">
				<router-link :to="{name: 'bonusses', params: {data: payroll_date} }">
					<span class="text">{{$t('salary-navbar.bonuses')}}</span>
					<div class="arrow"><icon-arrow /></div>
				</router-link>
			</div>
			<div class="item-menu">
				<router-link :to="{name: 'others-retentions', params: {data: payroll_date} }">
					<span class="text">{{$t('salary-navbar.others_retentions')}}</span>
					<div class="arrow"><icon-arrow /></div>
				</router-link>
			</div>
            <div class="item-menu">
				<router-link :to="{name: 'retentions', params: {data: payroll_date} }">
					<span class="text">{{$t('salary-navbar.retentions')}}</span>
					<div class="arrow"><icon-arrow /></div>
				</router-link>
			</div>
            <div class="item-menu">
				<router-link :to="{name: 'suspensions', params: {data: payroll_date} }">
					<span class="text">{{$t('salary-navbar.suspensions')}}</span>
					<div class="arrow"><icon-arrow /></div>
				</router-link>
			</div>
            <div class="item-menu">
				<router-link :to="{name: 'detachments', params: {data: payroll_date} }">
					<span class="text">{{$t('salary-navbar.detachments')}}</span>
					<div class="arrow"><icon-arrow /></div>
				</router-link>
			</div>
			<div class="item-menu">
				<router-link :to="{name: 'd112', params: {data: payroll_date} }">
					<span class="text">{{$t('salary-navbar.d112')}}</span>
					<div class="arrow"><icon-arrow /></div>
				</router-link>
			</div>
			<div class="item-menu">
				<router-link :to="{name: 'bank-files', params: {data: payroll_date} }">
					<span class="text">{{$t('salary-navbar.bank_files')}}</span>
					<div class="arrow"><icon-arrow /></div>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
    import IconArrow from '../Icons/Arrow.vue'

	export default {
		components: {
            IconArrow
		},
		data() {
			return {
				payroll_date: {},
			};
		},
		mounted(){
			this.$root.$on('updatePayrollData', (data) => {
				this.payroll_date = data
			})
		},
		methods: {
		}
	};
</script>

<style lang="scss">
    @import "../../styles/templates/settings-template.scss";
</style>