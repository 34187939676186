import Vue       from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

Vue.use(VueRouter)

function loadView(view) {
    return () => import(`../components/${view}.vue`);
}

const routes = [
	{ path: '/login', name: 'login', component: loadView('Auth/Login'), meta: { auth: false, layout: 'login-template'}},
	{ path: '/register', name: 'register', component: loadView('Auth/Register'), meta: { auth: false, layout: 'login-template'}},
	
	{ path: '/password/forgot', name: 'password-forgot', component: loadView('Auth/ForgotPassword'), meta: { auth: false, layout: 'login-template' }},
	{ path: '/password/forgot-success', name: 'password-forgot-success', component: loadView('Auth/ForgotPasswordSuccess'), meta: { auth: false, layout: 'login-template' }},
	{ path: '/reset-password/:token', name: 'password-reset', component: loadView('Auth/ResetPassword'), meta: { auth: false, layout: 'login-template' }},
	{ path: '/reset-password-success', name: 'password-reset-success', component: loadView('Auth/ResetPasswordSuccess'), meta: { auth: false, layout: 'login-template' }},
	{ path: '/register-success', name: 'register-success', component: loadView('Auth/RegisterSuccess'), meta: { auth: true, layout: 'login-template'}},
	
	{ path: '/payment', name: 'payment', component: loadView('Auth/Register'), meta: { auth: true, layout: 'login-template' }},

	{ path: '/', name: 'dashboard', component: loadView('Pages/Company/Organigram'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'full-template'}},

	{ path: '/company', name: 'company', component: loadView('Pages/Company/Organigram'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'full-template' } },
	{ path: '/company/department/:slug', name: 'department', component: loadView('Pages/Company/Organigram'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'full-template' }},

	{ path: '/users', name: 'users', component: loadView('Pages/Users/Index'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'main-template'}},
	{ path: '/users/create', name: 'user-create', component: loadView('Pages/Users/Create'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},
	{ path: '/users/edit/:slug', name: 'user-edit', component: loadView('Pages/Users/Create'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template' } },
	{ path: '/user/:slug', name: 'user-show', component: loadView('Pages/Users/Show'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'main-template' } },

	{ path: '/users/evaluation', name: 'user-evaluation', component: loadView('Pages/UserDetails/Evaluation'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template' } },
	{ path: '/users/edit/evaluation/:id', name: 'user-edit-evaluation', component: loadView('Pages/UserDetails/Evaluation'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template' } },
	{ path: '/users/warning', name: 'user-warning', component: loadView('Pages/UserDetails/Warning'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template' } },
	{ path: '/users/edit/warning/:id', name: 'user-edit-warning', component: loadView('Pages/UserDetails/Warning'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template' } },
	{ path: '/users/congratulation', name: 'user-congratulation', component: loadView('Pages/UserDetails/Congratulation'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template' } },
	{ path: '/users/edit/congratulation/:id', name: 'user-edit-congratulation', component: loadView('Pages/UserDetails/Congratulation'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template' } },

	{ path: '/roles', name: 'roles', component: loadView('Pages/Roles/Index'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'main-template'}},
	{ path: '/roles/create', name: 'roles-create', component: loadView('Pages/Roles/Create'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},
	{ path: '/roles/edit/:id', name: 'roles-edit', component: loadView('Pages/Roles/Create'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template' } },
	{ path: '/roles/:id', name: 'roles-show', component: loadView('Pages/Roles/Show'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'main-template'}},

	{ path: '/settings/subscription', name: 'settings-subscriptions', component: loadView('Pages/Settings/Subscriptions'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'settings-template'}},
	
	{ path: '/settings/work-schedules', name: 'settings-work-schedules', component: loadView('Pages/Settings/WorkSchedules'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'settings-template'}},
	{ path: '/settings/departments', name: 'settings-departments', component: loadView('Pages/Settings/Departments'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'settings-template'}},
	{ path: '/settings/workstations', name: 'settings-workstations', component: loadView('Pages/Settings/Workstations'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'settings-template'}},
	{ path: '/settings/skills', name: 'settings-skills', component: loadView('Pages/Settings/Skills'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'settings-template' } },

	{ path: '/settings/holidays', name: 'settings-holidays', component: loadView('Pages/Settings/Holidays'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'settings-template'}},
	{ path: '/settings/company', name: 'settings-company', component: loadView('Pages/Settings/Company'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'settings-template' } },
	
	{ path: '/settings/profile', name: 'settings-profile', component: loadView('Pages/Settings/Profile'), meta: { auth: true, layout: 'settings-template'}},

	{ path: '/contracts', name: 'contracts', component: loadView('Pages/Contracts/Contracts/Index'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'contracts-template' } },
	{ path: '/contracts/create', name: 'contract-create', component: loadView('Pages/Contracts/Contracts/Create'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},
	{ path: '/contracts/edit/:id', name: 'contract-edit', component: loadView('Pages/Contracts/Contracts/Create'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},
	{ path: '/contracts/:id', name: 'contract-show', component: loadView('Pages/Contracts/Contracts/Show'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'main-template'}},

	{ path: '/contracts/co_last_years', name: 'contract-co-last-years', component: loadView('Pages/Contracts/Contracts/AddCoLastYears'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},
 
	{ path: '/contracts-modification/create/:contract_id', name: 'contract-modification-create', component: loadView('Pages/Contracts/Contracts/CreateContractModification'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},
	{ path: '/contracts-modification/edit/:contract_id/modification_id=:id', name: 'contracts-modification-edit', component: loadView('Pages/Contracts/Contracts/CreateContractModification'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},

	{ path: '/contracts/coinsured/create/contract_id=:contract_id', name: 'coinsured-create', component: loadView('Pages/Contracts/Contracts/ContractDetails/Coinsured'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},
	{ path: '/contracts/coinsured/edit/:id/contract_id=:contract_id', name: 'coinsured-edit', component: loadView('Pages/Contracts/Contracts/ContractDetails/Coinsured'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},
	
	{ path: '/contracts/vacations/create/contract_id=:contract_id', name: 'vacations-create', component: loadView('Pages/Contracts/Contracts/ContractDetails/Vacation'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},
	{ path: '/contracts/vacations/edit/:id/contract_id=:contract_id', name: 'vacations-edit', component: loadView('Pages/Contracts/Contracts/ContractDetails/Vacation'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},
	
	{ path: '/contracts/benefits/create/contract_id=:contract_id', name: 'benefits-create', component: loadView('Pages/Contracts/Contracts/ContractDetails/Benefits'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},
	{ path: '/contracts/benefits/edit/:id/contract_id=:contract_id', name: 'benefits-edit', component: loadView('Pages/Contracts/Contracts/ContractDetails/Benefits'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},
	
	{ path: '/contracts/suspensions/create/contract_id=:contract_id', name: 'suspension-create', component: loadView('Pages/Contracts/Contracts/ContractDetails/Suspension'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},
	{ path: '/contracts/suspensions/edit/:id/contract_id=:contract_id', name: 'suspension-edit', component: loadView('Pages/Contracts/Contracts/ContractDetails/Suspension'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},

	{ path: '/contracts/detachments/create/contract_id=:contract_id', name: 'detachment-create', component: loadView('Pages/Contracts/Contracts/ContractDetails/Detachment'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},
	{ path: '/contracts/detachments/edit/:id/contract_id=:contract_id', name: 'detachment-edit', component: loadView('Pages/Contracts/Contracts/ContractDetails/Detachment'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},

	{ path: '/contracts/retentions/edit/:id/contract_id=:contract_id', name: 'retention-edit', component: loadView('Pages/Contracts/Contracts/ContractDetails/Retentions'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},

	{ path: '/contracts/sick-leaves/create/contract_id=:contract_id', name: 'sick-leaves-create', component: loadView('Pages/Contracts/Contracts/ContractDetails/SickLeave'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},
	{ path: '/contracts/sick-leaves/edit/:id/contract_id=:contract_id', name: 'sick-leaves-edit', component: loadView('Pages/Contracts/Contracts/ContractDetails/SickLeave'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},

	// { path: '/salary/contracts-modification/edit/:contract_id/modification_id=:id', name: 'contracts-modification-edit', component: loadView('Pages/Salary/Contracts/CreateContractModification'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},
	{ path: '/salary/dashboard', name: 'salary-dashboard', component: loadView('Pages/Salary/Dashboard/Index'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'salary-template' } },

	{ path: '/salary/clocking', name: 'clocking', component: loadView('Pages/Salary/Clocking/Index'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'salary-template' } },
	{ path: '/salary/payroll', name: 'payroll', component: loadView('Pages/Salary/Payroll/Index'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'salary-template' } },

	{ path: '/salary/sick-leaves', name: 'sick-leaves', component: loadView('Pages/Salary/SickLeaves/Index'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'salary-template'}},
	{ path: '/salary/sick-leaves/create', name: 'salary-sick-leaves-create', component: loadView('Pages/Contracts/Contracts/ContractDetails/SickLeave'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},

	{ path: '/salary/other-leaves', name: 'other-leaves', component: loadView('Pages/Salary/OtherLeaves/Index'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'salary-template'}},
	{ path: '/salary/vacations/create', name: 'salary-vacations-create', component: loadView('Pages/Contracts/Contracts/ContractDetails/Vacation'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},

	{ path: '/salary/tickets', name: 'tickets', component: loadView('Pages/Salary/Tickets/Index'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'salary-template'}},
	{ path: '/salary/tickets/create', name: 'salary-tickets-create', component: loadView('Pages/Salary/Tickets/Create'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},

	{ path: '/salary/bonusses', name: 'bonusses', component: loadView('Pages/Salary/Bonusses/Index'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'salary-template'}},
	{ path: '/salary/benefits/create', name: 'salary-benefits-create', component: loadView('Pages/Contracts/Contracts/ContractDetails/Benefits'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},

	{ path: '/salary/others-retentions', name: 'others-retentions', component: loadView('Pages/Salary/OthersRetentions/Index'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'salary-template'}},
	{ path: '/salary/others-retentions/create', name: 'salary-others-retentions-create', component: loadView('Pages/Salary/OthersRetentions/Create'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},
	{ path: '/salary/others-retentions/edit/:id', name: 'salary-others-retentions-edit', component: loadView('Pages/Salary/OthersRetentions/Create'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},

	{ path: '/salary/retentions', name: 'retentions', component: loadView('Pages/Salary/Retentions/Index'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'salary-template'}},
	{ path: '/salary/retentions/create', name: 'salary-retentions-create', component: loadView('Pages/Contracts/Contracts/ContractDetails/Retentions'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},

	{ path: '/salary/suspensions', name: 'suspensions', component: loadView('Pages/Salary/Suspensions/Index'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'salary-template'}},
	{ path: '/salary/suspensions/create', name: 'salary-suspensions-create', component: loadView('Pages/Contracts/Contracts/ContractDetails/Suspension'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},

	{ path: '/salary/detachments', name: 'detachments', component: loadView('Pages/Salary/Detachments/Index'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'salary-template'}},
	{ path: '/salary/detachments/create', name: 'salary-detachments-create', component: loadView('Pages/Contracts/Contracts/ContractDetails/Detachment'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},

	{ path: '/salary/d112', name: 'd112', component: loadView('Pages/Salary/D112/Generate'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'salary-template'}},
	{ path: '/salary/bank-files', name: 'bank-files', component: loadView('Pages/Salary/BankFiles/Generate'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'salary-template'}},
	
	{ path: '/nomenclature/benefits', name: 'nomenclature-benefits', component: loadView('Pages/Nomenclature/Benefits'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'nomenclature-template'}},
	{ path: '/nomenclature/retentions', name: 'nomenclature-retentions', component: loadView('Pages/Nomenclature/Retentions'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'nomenclature-template'}},
	{ path: '/nomenclature/banks', name: 'nomenclature-banks', component: loadView('Pages/Nomenclature/Banks'), meta: { auth: { instance: ['active', 'grace_period'] }, layout: 'nomenclature-template' } },
	{ path: '/nomenclature/monthly_settings', name: 'monthly-settings', component: loadView('Pages/Nomenclature/MonthlySettings'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'nomenclature-template'}},
	{ path: '/nomenclature/company', name: 'nomenclature-company', component: loadView('Pages/Nomenclature/CompanyDetails'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'nomenclature-template'}},
	{ path: '/nomenclature/settings', name: 'nomenclature-settings', component: loadView('Pages/Nomenclature/Settings'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'nomenclature-template'}},
	{ path: '/nomenclature/import', name: 'nomenclature-import', component: loadView('Pages/Nomenclature/Import'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'nomenclature-template'}},
    
	{ path: '/reports', name: 'reports', component: loadView('Pages/Reports/Index'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'main-template' } },
	
	{ path: '/vacant_jobs', name: 'vacant-jobs', component: loadView('Pages/VacantJobs/Index'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'main-template' } },
	{ path: '/vacant_jobs/create', name: 'vacant-jobs-create', component: loadView('Pages/VacantJobs/Create'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template'}},
	{ path: '/vacant_jobs/edit/:slug', name: 'vacant-jobs-edit', component: loadView('Pages/VacantJobs/Create'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'form-template' } },
	{ path: '/vacant_jobs/:slug', name: 'vacant-jobs-show', component: loadView('Pages/VacantJobs/Show'), meta: { auth: {instance: ['active', 'grace_period']}, layout: 'main-template' } },

	{ path: '/software-updates', name: 'software-updates', component: loadView('Pages/System/Updates'), meta: { auth: true, layout: 'system-template'}},
	{ path: '/software-updates/:id', name: 'software-updates-show', component: loadView('Pages/System/Updates'), meta: { auth: true, layout: 'system-template'}},
	{ path: '/work-in-progress', name: 'work-in-progress', component: loadView('Pages/System/WorkInProgress'), meta: { auth: true, layout: 'system-template'}},
	{ path: '/work-in-progress/:id', name: 'work-in-progress-show', component: loadView('Pages/System/WorkInProgress'), meta: { auth: true, layout: 'system-template'}},

	{ path: '/403', name: 'forbbiden', component: loadView('Pages/Forbbiden'), meta: { auth: undefined, layout: 'main-template' } },
	{ path: '/404', name: 'not-found', component: loadView('Pages/NotFound'), meta: { auth: undefined, layout: 'main-template' } },
	{ path: '*', name: 'not-exist', component: loadView('Pages/NotFound'), meta: { auth: undefined, layout: 'main-template' } }
]

Vue.router = new VueRouter({
	history: true,
	mode: 'history',
	routes,
	linkActiveClass: 'active-link'
})

export default Vue.router;