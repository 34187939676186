<template>
	<div class="main-template">
		<sidebar v-if="$resize && $mq.above(781)"/>
		<div class="container-tbf">
			<navbar />
			<div class="container-content">
				<slot></slot>
			</div>
            <sidebar-mobile v-if="$resize && $mq.below(780)"/>
		</div>

        <div class="overflow-modal" v-if="show_overflow">
            <div class="bg-modals" @click="closeModals()"></div>
            <modal-required-instance id="modal_required_instance" v-if="show_modal.required_instance" :data="data.required_instance" @close_modal="closeModal('required_instance')"/>
            <modal-change-subscription id="modal_change_subscription" v-if="show_modal.change_subscription" @close_modal="closeModal('change_subscription')"/>
            <modal-delete id="modal_delete" v-if="show_modal.delete" :data="data.delete" @close_modal="closeModal('delete')" />
            <modal-notifications id="modal_notifications" v-if="show_modal.notifications" @close_modal="closeModal('notifications')"/>
            <modal-grace-period id="modal_payment_error" v-if="show_modal.payment_error" @close_modal="closeModal('payment_error')"/>
            <modal-warning-limit-package id="modal_warning_limit_package" v-if="show_modal.warning_limit_package" @close_modal="closeModal('warning_limit_package')"/>
            <modal-settings-mobile id="modal_settings_mobile" v-if="show_modal.settings_mobile" @close_modal="closeModal('settings_mobile')"/>
            <modal-statements id="modal_statements" v-if="show_modal.statements" :data="data.statements" @close_modal="closeModal('statements')" />
            <modal-liquidation id="modal_liquidation" v-if="show_modal.liquidation" :data="data.liquidation" @close_modal="closeModal('liquidation')" />
            <div class="overflow-modal error" v-if="show_overflow_error">
                <info-error id="modal_error" v-if="show_modal.error" :data="data.error" @close_modal="closeModalError('error')"/>
            </div>
            <info-error id="modal_error" v-if="show_modal.error && !show_overflow_error" :data="data.error" @close_modal="closeModal('error')"/>
            <modal-situation id="modal_situation" v-if="show_modal.situation" :data="data.situation" @close_modal="closeModal('situation')" />
            <modal-reset-password id="modal_reset_password" v-if="show_modal.reset_password" :data="data.reset_password" @close_modal="closeModal('reset_password')"/>
            <modal-contact id="modal_contact" v-if="show_modal.contact" @close_modal="closeModal('contact')"/>
            <modal-webinar-tehnic id="modal_webinar_tbf" v-if="show_modal.webinar_tbf" @close_modal="closeModal('webinar_tbf')"/>
            <modal-vacant-job id="modal_vacant_job" v-if="show_modal.vacant_job" :data="data.vacant_job" @close_modal="closeModal('vacant_job')"/>

            <modal-crud-responsibility id="modal_crud_responsibility" v-if="show_modal.crud_responsibility" :data="data.crud_responsibility" @close_modal="closeModal('crud_responsibility')"/>
            <modal-show-responsibility id="modal_show_responsibility" v-if="show_modal.show_responsibility" :data="data.show_responsibility" @close_modal="closeModal('show_responsibility')"/>
            <modal-report-user-responsibilities id="modal_report_user_responsibilities" v-if="show_modal.report_user_responsibilities" :data="data.report_user_responsibilities" @close_modal="closeModal('report_user_responsibilities')"/>
            <modal-confirm-archiving id="modal_confirm_archiving" v-if="show_modal.confirm_archiving" :data="data.confirm_archiving" @close_modal="closeModal('confirm_archiving')" />
            <modal-calendar-list id="modal_calendar_list" v-if="show_modal.calendar_list" :data="data.calendar_list" @close_modal="closeModal('calendar_list')" />
            <modal-show-evaluation id="modal_show_evaluation" v-if="show_modal.show_evaluation" :data="data.show_evaluation" @close_modal="closeModal('show_evaluation')" />
            <modal-user-free-days id="modal_user_free_days" v-if="show_modal.user_free_days" :data="data.user_free_days" @close_modal="closeModal('user_free_days')" />
            <modal-mission-vision id="modal_mission_vision" v-if="show_modal.mission_vision" @close_modal="closeModal('mission_vision')" />
        </div>
	</div>
</template>

<script>
    import Sidebar from '../General/Sidebar.vue'
    import Navbar from '../General/Navbar.vue'
    import ModalRequiredInstance from "../Modals/RequiredInstance"
    import ModalChangeSubscription from "../Modals/ChangeSubscription"
    import ModalDelete from "../Modals/Delete"
    import ModalNotifications from "../General/SidebarNotifications"
    import ModalGracePeriod from "../Modals/GracePeriod"
    import ModalWarningLimitPackage from "../Modals/WarningLimitPackage"
    import SidebarMobile from "../General/SidebarMobile"
    import ModalSettingsMobile from "../General/SidebarSettingsMobile"
    import ModalStatements from "../Modals/Statements"
    import ModalLiquidation from "../Modals/Liquidation"
    import InfoError from "../Modals/InfoError"
    import ModalSituation from "../Modals/Situations"
    import ModalResetPassword from "../Modals/ResetPassword"
    import ModalContact from "../Modals/Contact"
    import ModalCrudResponsibility from "../Modals/CrudResponsibility"
    import ModalShowResponsibility from "../Modals/ShowResponsibility"
    import ModalReportUserResponsibilities from "../Modals/ReportUserResponsibilities"
    import ModalConfirmArchiving from "../Modals/ConfirmArchiving"
    import ModalWebinarTehnic from "../Modals/WebinarTehnic"
    import ModalVacantJob from "../Modals/VacantJob"
    import ModalCalendarList from "../Modals/CalendarList"
    import ModalShowEvaluation from "../Modals/ShowEvaluation"
    import ModalUserFreeDays from "../Modals/UserFreeDays"
    import ModalMissionVision from "../Modals/MissionVision"

    export default {
        data() {
            return {
                show_overflow: false,
                show_overflow_error: false,
                show_modal: {
                    required_instance: false,
                    role: false,
                    delete: false,
                    payment_error: false,
                    change_subscription: false,
                    settings_mobile: false,
                    warning_limit_package: false,
                    statements: false,
                    liquidation: false,
                    error: false,
                    situation: false,
                    reset_password: false,
                    contact: false,
                    crud_responsibility: false,
                    show_responsibility: false,
                    report_user_responsibilities: false,
                    confirm_archiving: false,
                    webinar_tbf: false,
                    vacant_job: false,
                    calendar_list: false,
                    show_evaluation: false,
                    user_free_days: false,
                    mission_vision: false
                },
                data: {
                    required_instance: {},
                    delete: {},
                    statements: {},
                    liquidation: {},
                    error: {},
                    situation: {},
                    reset_password: {},
                    crud_responsibility: {},
                    show_responsibility: {},
                    report_user_responsibilities: {},
                    confirm_archiving: {},
                    vacant_job: {},
                    calendar_list: {},
                    show_evaluation: {},
                    user_free_days: {}
                },
                active_modal: ''
            }
        },
        components: {
            Sidebar,
            SidebarMobile,
            Navbar,
            ModalRequiredInstance,
            ModalChangeSubscription,
            ModalNotifications,
            ModalDelete,
            ModalGracePeriod,
            ModalSettingsMobile,
            ModalWarningLimitPackage,
            ModalStatements,
            ModalLiquidation,
            InfoError,
            ModalSituation,
            ModalResetPassword,
            ModalContact,
            ModalCrudResponsibility,
            ModalShowResponsibility,
            ModalReportUserResponsibilities,
            ModalConfirmArchiving,
            ModalWebinarTehnic,
            ModalVacantJob,
            ModalCalendarList,
            ModalShowEvaluation,
            ModalUserFreeDays,
            ModalMissionVision
        },
        mounted(){
            this.$root.$on("open_modal", (type, data = false) => {
                this.active_modal = type
                this.data[type] = data ? data : {}

                setTimeout(() => {
                    this.show_overflow = true
                    this.show_modal[type] = true
                    setTimeout(() => {
                        $('.overflow-modal').addClass('show')
                        $("#modal_" + type).addClass("active")
                    }, 0);
                }, 0);
            })

            this.$root.$on("open_modal_over_modal", (type, data = false) => {
                this.active_modal = type
                this.data[type] = data ? data : {}

                setTimeout(() => {
                    this.show_overflow_error = true
                    this.show_modal[type] = true
                    setTimeout(() => {
                        $('.overflow-modal.error').addClass('show')
                        $("#modal_" + type).addClass("active")
                    }, 0);
                }, 0);
            })

            this.$root.$on('change_modal', (type, data) => {
				$("#modal_" + this.active_modal).removeClass("active")
				setTimeout(() => {
					this.show_modal[this.active_modal] = false
					setTimeout(() => {
						this.show_modal[type] = true
						this.active_modal = type
                        if(type == 'error') {
                            this.$root.$emit('open_modal', 'error', {data: data.data}, false);
                        } else {
                            setTimeout(() => {
                                $("#modal_" + type).addClass("active");
                            }, 0);
                        }
						
					}, 0);
				}, 200);
            })
            
            if(this.$auth.check() && this.$auth.user().rights.instance == 'grace_period' && !this.$cookies.get('grace_period_modal')){
                this.$cookies.set('grace_period_modal', true, '1d')
                this.$root.$emit('open_modal', 'payment_error')
            }

            var refreshIntervalApps = setInterval(() => {
                if(this.$auth.check()){
                    if(this.$store.state.applications.applications.length){
                        clearInterval(refreshIntervalApps);
                        this.initItercom();
                    }
                }
            }, 200);

            this.checkLanguage();
        },
        methods:{
            closeModals(){
                var arrayModalsNeedVerification = ['key_result', 'objective', 'master_goal', 'user', 'add_promise', 'evaluate_promise', 'error', 'liquidation', 'crud_responsibility', 'calendar_list']

                if(arrayModalsNeedVerification.includes(this.active_modal)){
                    this.$root.$emit('confirm_close')
                }else{
                    this.closeModal()
                }
            },
            closeModal(type = false){
                var target_modal = type ? type : this.active_modal
                $("#modal_" + target_modal).removeClass("active")

                setTimeout(() => {
                    this.show_modal[target_modal] = false
                    $('.overflow-modal').removeClass('show')
                    setTimeout(() => {
                        this.show_overflow = false;
                    }, 200)
                }, 200);
            },
            closeModalError(type = false) {
                var target_modal = type ? type : this.active_modal
                $("#modal_" + target_modal).removeClass("active")

                setTimeout(() => {
                    this.show_modal[target_modal] = false
                    $('.overflow-modal.error').removeClass('show')
                    setTimeout(() => {
                        this.show_overflow_error = false;
                    }, 200)
                }, 200);
            },
            initItercom(){
                var apps = {
                    'Obiective': false,
                    'Proceduri': false,
                    'Cursuri': this.$auth.user().instance && this.$auth.user().instance.id <= 1340 ? true : false,
                    'Organigrama': false    
                };

                this.$store.state.applications.applications.map((el) => {
                    if(el.application){
                        apps[el.application.name] = el.is_active;
                    }
                });

                Intercom('boot', {  
                    app_id: "tk8wk42t",
                    name: this.$auth.user().first_name + ' ' + this.$auth.user().last_name,
                    email: this.$auth.user().email,
                    package_id: this.$auth.user().instance ? this.$auth.user().instance.package_id : '',
                    user_hash: this.$auth.user().intercom_token,
                    created_at: null,
                    hide_default_launcher: this.$resize && this.$mq.above(780) ? false : true,
                    'Obiective': apps['Obiective'],
                    'Proceduri': apps['Proceduri'],
                    'Cursuri': apps['Cursuri'],
                    'Organigrama': apps['Organigrama'] 
                });
            },
            checkLanguage(){
                if(this.$auth.check()){
                   this.$i18n.locale = this.$auth.user().language
                   this.$cookies.set('language', this.$auth.user().language, '1m')
                }else if(this.$cookies.get('language')){
                   this.$i18n.locale = this.$cookies.get('language')
                }
            }
        }
    }
</script>

<style lang="scss">
    @import "../../styles/templates/main-template.scss";
</style>