<template>
    <div class="modal-tbf modal-full-page">
        <div class="container-modal opacity-page" v-if="loaded">
            <div class="header-modal">
                <div class="title">
                    {{$t('create-user.title-modal')}}
                </div>
                <div class="actions">
                    <button class="btn-tbf white only-icon close-btn" @click="cancelSubmit"><icon-close class="icon-close" /></button>
                </div>
            </div>

            <div class="info-content">
                <div class="container-content-modal">
                    <div class="form-section">
                        <div class="input-group w-100">
                            <div class="label-header">
                                <label class="label">{{$t('create-user.users')}}</label>
                                <div class="create-new" @click="$router.push({name: 'user-create'})">{{ $t('create-user.new-user') }}</div>
                            </div>
                            <div class="input-box bg-white" v-bind:class="{has_error: $v.selectedUser.$error}">
                                <div class="icon-left"><icon-people /></div>
                                <div class="icon-right" v-if="selectedUser != ''" @click.stop="selectedUser = ''"><icon-close class="icon-clear" /></div>
                                <multiselect 
                                v-model="selectedUser"
                                v-bind:class="{populate: selectedUser != ''}"
                                :options="optionsUsers"
                                :allow-empty="true"
                                :show-labels="false"
                                :maxHeight="140"
                                track-by="id" 
                                label="name"
                                >
                                    <template slot="placeholder" slot-scope="props">
                                        <span class="text">
                                            {{$t('create-user.choose-user')}}
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">
                                        <div class="option__image">
                                            <img :src="props.option.avatar" v-if="props.option.avatar">
                                            <div v-else class="user-circle">
                                                <icon-people />
                                            </div>
                                            <span>
                                                {{ props.option.name }}
                                            </span>
                                        </div>
                                    </template>
                                    <template slot="noResult">{{$t('general.no_results')}}</template>
                                    <template slot="noOptions">{{$t('general.empty_list')}}</template>
                                </multiselect>
                            </div>
                        </div>
                    </div>
                    <div class="form-submit user">
                        <button class="btn-tbf grey center" @click="cancelSubmit">
                            <div class="loader"></div>
                            <div class="text">{{$t('general.cancel')}}</div>
                        </button>
                        <div class="error-msg" v-show="error_message!=''">{{error_message}}</div>
                        <button id='buttonAddUser' class="btn-tbf blue center" @click="saveAction('create')">
                            <div class="loader"></div>
                            <div class="text">{{$t('general.add')}}</div>
                        </button>
                    </div>
                </div>
            </div>
            
            <div id="submodalContainer" class="submodal-container" v-if="subModalShow">
                <div class="overlay-submodal"></div>
                <modal-confirm-close id="submodal_confirm_close" v-if="show_confirm_close" @close_modal="closeModalConfirmed" @cancel_close="cancelClose" />
            </div>
    	</div>
        <user-loader-modal v-else/>
    </div>
</template>

<script type="text/javascript">
import IconClose from '../Icons/Close'
import IconPeople from '../Icons/People'
import ModalConfirmClose from '../Modals/ConfirmClose'
import { required } from 'vuelidate/lib/validators'
import UserLoaderModal from '../PagesLoaders/UserModal.vue'
	export default {
		data() {
			return {
                loaded: false,
				optionsUsers: [],
			  	selectedUser: '',
                subModalShow: false,
                show_confirm_close: false,
                error_message: ''
			};
		},
        components: {
            IconClose,
            IconPeople,
            ModalConfirmClose,
            UserLoaderModal
        },
       
        props: {
            data: Object
        },
        validations: {
			selectedUser: {required},
		},
		async mounted() {
            await this.getUsers()

            this.$root.$on("confirm_close", () => {
                if(this.checkInputs()){
                    this.subModalShow = true
                    this.show_confirm_close = true
                    setTimeout(() => {
                        document.getElementById('submodalContainer').classList.add('active')
                        setTimeout(() => {
                            document.getElementById('submodal_confirm_close').classList.add('active')
                        }, 200);
                    }, 0);
                }else{
                    this.$emit("close_modal");
                }
            });
		},
		methods: {
             async getUsers(){
                await axios.get( `instances/${this.$auth.user().instance.id}/filter`, { params: {users_without_department_id: this.data.department_id}})
                .then(({data}) => {
					this.optionsUsers = data.data.users_without_department_id;
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				}).finally(() => {
                    this.loaded = true
					setTimeout(() => {
						$('.opacity-page').addClass('show')
					}, 0)
				})
			},
			cancelSubmit(){
                if(this.checkInputs()){
                    this.subModalShow = true
                    this.show_confirm_close = true
                    setTimeout(() => {
                        document.getElementById('submodalContainer').classList.add('active')
                        setTimeout(() => {
                            document.getElementById('submodal_confirm_close').classList.add('active')
                        }, 200);
                    }, 0);
                }else{
                    this.$emit("close_modal");
                }
			},
            saveAction(type){
				var buttonName = `buttonAddUser`
				var btnSubmit = document.getElementById(buttonName);
				btnSubmit.disabled = true

				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
                this.error_message = '';

				this.$v.$touch()
				if(!this.$v.$invalid){
					var objData = {
                        users: [this.selectedUser.id]
					}
					this.addUser(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
            addUser(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post(`departments/${this.data.department_slug}/assign/users`, objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.$v.$reset()
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('btn-submit.create')
                            this.$emit("close_modal");
                            if(this.data.from == 'chart'){
                                this.$root.$emit('refreshCompanyChart');
                            }
						}, 1000)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.add')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
            checkInputs(){
                var notEmpty = false

                if(this.selectedUser != ''){
                    notEmpty = true
                }

                return notEmpty
            },
            closeModalConfirmed(){
                this.$emit("close_modal");
            },
            cancelClose(){
                document.getElementById('submodal_confirm_close').classList.remove('active')
                setTimeout(() => {
                    document.getElementById('submodalContainer').classList.remove('active')
                    setTimeout(() => {
                        this.subModalShow = false;
                        this.show_confirm_close = false
                    }, 200);
                }, 200);
            },
		}
	};
</script>

<style lang="scss">
    #modal_user {
        max-width: 550px;
        z-index: 10
    }
    div.input-box.bg-white.user > input.no-icon{
        padding: 0 45px 0 15px;
    }
    .form-submit.user > button{
        width: 50%;
    }
</style>