<template>
	<div class="sidebar-mobile">
		<div class="main-menu" v-if="$auth.check()">
			<div class="item-menu">
				<router-link :to="{name: 'company' }" :class="{active: $route.name == 'company'}">
					<icon-chart class="icon-item"/>
				</router-link>
			</div>
			<div class="item-menu">
				<router-link :to="{name: 'users' }" :class="{active: $route.name == 'users'}">
					<icon-people class="icon-item"/>
				</router-link>
			</div>
			<!-- <div class="item-menu" @click="showModal('contracts_mobile')"> -->
			<div class="item-menu">
				<router-link :to="{name: 'contracts'}" :class="{active: $route.name == 'contracts'}">
					<icon-contract class="icon-item"/>
				</router-link>
			</div>
			<div class="item-menu" @click="showModal('salary_mobile')">
				<router-link :to="{name: salaryRoutes.includes($route.name) ? $route.name : 'salary-dashboard' }" :class="{active: salaryRoutes.includes($route.name)}">
					<icon-salary class="icon-item"/>
				</router-link>
			</div>
			<div class="item-menu" @click="showModal('nomenclature_mobile')">
				<router-link :to="{name: nomenclatureRoutes.includes($route.name) ? $route.name : 'nomenclature-benefits'}" :class="{active: nomenclatureRoutes.includes($route.name)}">
					<icon-nomenclature class="icon-item"/>
				</router-link>
			</div>
			<div class="item-menu" :title="$t('general.roles')" v-if="$auth.user().rights.admins_instance_crud">
				<router-link :to="{name: 'roles' }" :class="{active: $route.name == 'roles'}">
					<icon-role class="icon-item"/>
				</router-link>
			</div>
			<div class="item-menu" :title="$t('vacant_jobs.title')" v-if="$auth.user().rights.admins_instance_crud">
				<router-link :to="{name: 'vacant-jobs' }" :class="{active: $route.name == 'vacant-jobs'}">
					<icon-vacant-job class="icon-item"/>
				</router-link>
			</div>
			<div class="item-menu">
				<router-link :to="{name: 'reports' }" :class="{active: $route.name == 'reports'}">
					<icon-reports class="icon-item"/>
				</router-link>
			</div>
			<div class="item-menu" @click="showModal('settings_mobile')">
				<router-link :to="{name: !$auth.user().is_entrepreneur ? 'settings-profile' : $auth.user().is_entrepreneur && settingsRoutes.includes($route.name) ? $route.name : 'settings-departments'}" :class="{active: settingsRoutes.includes($route.name)}">
					<icon-settings class="icon-item"/>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	import IconChart from '../Icons/Chart'
	import IconPeople from '../Icons/People'
	import IconContract from '../Icons/Files'
	import IconSalary from '../Icons/Salary'
	import IconNomenclature from '../Icons/Nomenclature'
	import IconRole from '../Icons/Role'
	import IconVacantJob from '../Icons/VacantJob'
	import IconReports from '../Icons/Reports'
	import IconSettings from '../Icons/Settings'

	export default {
		data() {
			return {
				nomenclatureRoutes: ["nomenclature-benefits", "nomenclature-retentions", "nomenclature-company", "nomenclature-settings"],
				salaryRoutes: ['salary-dashboard','clocking', 'payroll', 'sick-leaves', 'other-leaves', 'tickets', 'bonusses', 'retentions','others-retentions', 'suspensions', 'detachments', 'd112'],
				settingsRoutes: ["settings-subscriptions", "settings-work-schedules", "settings-departments", "settings-workstations", "settings-skills", "settings-profile"]
			};
		},
		components: {
			IconChart,
			IconPeople,
			IconContract,
			IconSalary,
			IconNomenclature,
			IconSettings,
			IconRole,
			IconVacantJob,
			IconReports, 
		},
		methods: {
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			}
		}
	};
</script>