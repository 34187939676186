<template>
<div class="modal-tbf modal-full-page">
    <div class="container-modal opacity-page" v-if="loaded">
        <div class="header-modal">
            <div class="title">
                {{data.text + ' ' + data.model.first_name + ' ' + data.model.last_name}}
            </div>
            <div class="actions">
                <button class="btn-tbf white only-icon close-btn" @click="cancelMedical"><icon-close class="icon-close" /></button>
            </div>
        </div>

        <div class="info-content">
            <div class="container-content-modal">
                <div class="form-section">
                    <div v-if="data.from == 'index_contracts_income_situation'" class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-35'">
                        <div class="label-header">
							<label class="label">{{ $t('statements.date_contract') }}</label>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-calendar /></div>
							<FunctionalCalendar
								ref="CalendarDateOfEmployment"
								v-model="income_situation"
								class="calendar-tbf"
								:configs="calendarConfigsRange"
							>
							<template v-slot:datePickerInput="props">
								<input
								class="vfc-single-input custom-input-picker"
								type="text"
								v-if="Object.keys(income_situation).length"/>
								<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('statements.date_contract-ph') }}</div>
							</template>
							</FunctionalCalendar>
						</div>
                    </div>
                    <div v-else class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
                        <div class="label-header">
							<label class="label">{{ $t('statements.date_contract') }}</label>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-calendar /></div>
							<FunctionalCalendar
								ref="CalendarDateOfEmployment"
								v-model="co_cm_situation"
								class="calendar-tbf"
								:configs="calendarConfigs"
							>
							<template v-slot:datePickerInput="props">
								<input
								class="vfc-single-input custom-input-picker"
								type="text"
								:value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
								v-if="Object.keys(income_situation).length"/>
								<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('statements.date_contract-ph') }}</div>
							</template>
							</FunctionalCalendar>
						</div>
                    </div>
                    <div v-if="data.from == 'index_contracts_income_situation'" class="input-group w-100 files" @click="downloadIncomeSituation">
                        <div class="download-section">
                            <div class="situation-icon"><icon-pdf /></div>
                            <div class="statement-text">{{$t('salary.download.income_situation_title')}}</div>
                            <div class="download-icon"><icon-download /></div>
                        </div>
                    </div>
                    <div v-if="data.from == 'index_contracts_co_situation'" class="input-group w-100 files" @click="downloadCoSituation">
                        <div class="download-section">
                            <div class="situation-icon"><icon-pdf /></div>
                            <div class="statement-text">{{$t('salary.download.co_situation_title')}}</div>
                            <div class="download-icon"><icon-download /></div>
                        </div>
                    </div>
                    <div v-if="data.from == 'index_contracts_cm_situation'" class="input-group w-100 files" @click="downloadCmSituation">
                        <div class="download-section">
                            <div class="situation-icon"><icon-pdf /></div>
                            <div class="statement-text">{{$t('salary.download.cm_situation_title')}}</div>
                            <div class="download-icon"><icon-download /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
		<div id="submodalContainer" class="submodal-container" v-if="subModalShow">
			<div class="overlay-submodal"></div>
			<modal-confirm-close id="submodal_confirm_close" v-if="show_confirm_close" @close_modal="closeModalConfirmed" @cancel_close="cancelClose" />
		</div>
	</div>
</div>
</template>

<script type="text/javascript">
import IconClose from '../Icons/Close'
import IconPdf from '../Icons/Pdf'
import IconDownload from '../Icons/FileDownload'
import IconCalendar from '../Icons/Calendar'
import ModalConfirmClose from '../Modals/ConfirmClose'
	export default {
		data() {
			return {
				loaded: false,
				subModalShow: false,
                show_confirm_close: false,
                calendarConfigsRange: {
					dateFormat: 'yyyy-mm-dd',
					isDatePicker: true,
					isDateRange: true,
					isModal: true,
					isAutoCloseable: true,
					changeMonthFunction: true,
					changeYearFunction: true
				},
                calendarConfigs: {
					dateFormat: 'yyyy-mm-dd',
					isDatePicker: true,
					isDateRange: false,
					isModal: true,
					isAutoCloseable: true,
					changeMonthFunction: true,
					changeYearFunction: true
				},
                income_situation: {
                    multipleDateRange: [],
                    selectedDates: [],
                    selectedDateTime: false,
                    selectedDatesItem: "",
                    selectedHour: "00",
                    selectedMinute: "00",
                    dateRange: {end: moment().endOf('month').format("YYYY-MM-DD"),start: moment().startOf('month').format("YYYY-MM-D")}
                },
                co_cm_situation: {
                    selectedDate: moment().format("YYYY-MM-DD"),
                    multipleDateRange: [],
                    selectedDates: [],
                    selectedDateTime: false,
                    selectedDatesItem: "",
                    selectedHour: "00",
                    selectedMinute: "00",
                    dateRange: {end: '',start: ''}
                },
                error_message: ''
			};
		},
        components: {
            IconClose,
            IconPdf,
            IconDownload,
            IconCalendar,
			ModalConfirmClose
        },
        props: {
            data: Object
        },
		mounted() {
			setTimeout(() => {
				this.loaded = true
				setTimeout(() => {
					$('.opacity-page').addClass('show')
				}, 0)
			}, 0)

			this.$root.$on("confirm_close", () => {
                this.$emit("close_modal");
            });
		},
		methods: {
			cancelMedical(){
				this.$emit("close_modal");
			},
            closeModalConfirmed(){
                this.$emit("close_modal");
            },
            downloadIncomeSituation(){
                axios.get(`${this.$auth.user().instance.id}/payroll-reports/export/pdf/contract-payrolls?contract_id=${this.data.model.id}&start_date=${this.income_situation.dateRange.start}&end_date=${this.income_situation.dateRange.end}`, 
				{
                	responseType: 'blob'
				})
				.then((response) => {
                    const file = new Blob([response.data], {type: 'application/pdf'});
                    let fileURL = window.URL.createObjectURL(file);
					let fileName = response.headers['content-disposition'].split('filename=')[1].replace(/"/g, '');

					let fileLink = document.createElement('a');
					fileLink.href = fileURL;
					fileLink.setAttribute( 'download', fileName );
					document.body.appendChild(fileLink);

					fileLink.click();
				})
            },
            downloadCoSituation(){
                 axios.get(`${this.$auth.user().instance.id}/payroll-reports/export/pdf/contract-annual-co?contract_id=${this.data.model.id}&year=${moment(this.co_cm_situation.selectedDate,"YYYY-MM-DD").year()}`, 
				{
                	responseType: 'blob'
				})
				.then((response) => {
                    const file = new Blob([response.data], {type: 'application/pdf'});
                    let fileURL = window.URL.createObjectURL(file);
					let fileName = response.headers['content-disposition'].split('filename=')[1].replace(/"/g, '');

					let fileLink = document.createElement('a');
					fileLink.href = fileURL;
					fileLink.setAttribute( 'download', fileName );
					document.body.appendChild(fileLink);

					fileLink.click();
				})
            },
            downloadCmSituation(){
                axios.get(`${this.$auth.user().instance.id}/payroll-reports/export/pdf/contract-annual-cm?contract_id=${this.data.model.id}&year=${moment(this.co_cm_situation.selectedDate,"YYYY-MM-DD").year()}`, 
				{
                	responseType: 'blob'
				})
				.then((response) => {
                    const file = new Blob([response.data], {type: 'application/pdf'});
                    let fileURL = window.URL.createObjectURL(file);
					let fileName = response.headers['content-disposition'].split('filename=')[1].replace(/"/g, '');

					let fileLink = document.createElement('a');
					fileLink.href = fileURL;
					fileLink.setAttribute( 'download', fileName );
					document.body.appendChild(fileLink);

					fileLink.click();
				})
            },
            cancelClose(){
                document.getElementById('submodal_confirm_close').classList.remove('active')
                setTimeout(() => {
                    document.getElementById('submodalContainer').classList.remove('active')
                    setTimeout(() => {
                        this.subModalShow = false;
                        this.show_confirm_close = false
                    }, 200);
                }, 200);
            },
		}
	};
</script>

<style lang="scss">
.modal-tbf{
    &#modal_situation{
        overflow: visible !important;
        .container-modal{
            overflow-y: initial !important;
        }
    }
    
}
div.input-box.bg-white.role > input.no-icon{
    padding: 0 45px 0 15px;
}
.form-submit.role > button{
    width: 50%;
}
.input-group {
    &.files {
         margin-bottom: 0 !important;
    }
}
.download-section{
    cursor: pointer;
    display: flex;
    border: 1px solid #e5e7ef;
    height: 60px;
    &:hover{
        background: #e5e7ef;
        border: 1px solid#AFB1B8;
        .situation-icon,
        .download-icon {
            svg {
                path {
                    fill: black;
                }
            }
        }
    }
    .statement-text {
        width: 100%;
        align-self: center;
    }
    .situation-icon,
    .download-icon {
        width: 60px;
        align-self: center;
        svg {
            width: 40px;
            height: 40px;
            .path {
                fill: #AFB1B8;
            }
        }
    }
    .download-icon {
        display: flex;
        justify-content: flex-end;
    }
    .situation-icon {
        display: flex;
        justify-content: flex-start;
        svg {
            width: 40px;
            height: 40px;
            path{
                fill: #ec2e2e;
            }
        }
    }
}
</style>