<template>
	<div class="modal_info modal_update">
		<div class="header-submodal">
			<div class="image"><img src="/build/images/Warning.svg"></div>
			<div class="title">{{ $t('update.title') }}</div>
		</div>
		<div v-if="data.type == 'reset'" class="description-modal">
			{{ $t('update.question1',{text: $t('update.' + data.type)}) }}
			{{ $t('update.atention') }}
		</div>
    <div v-else-if="data.type == 'upload_file'" class="description-modal">
      {{ $t('update.question2') }}
      {{ $t('update.upload_file') }}
    </div>
		<div v-else class="description-modal">
			{{ $t('update.question',{text: $t('update.' + data.type)}) }}
			{{ $t('update.atention') }}
		</div>

		<div class="button-actions">
			<button class="btn-tbf white center" @click="cancelUpdate">
				<div class="text">{{ $t('general.cancel') }}</div>
			</button>
			<button id="buttonSubmitUpdate" class="btn-tbf blue center" @click="submitUpdate">
				<div class="loader"></div>
				<div class="text">{{ $t('update.update') }}</div>
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		data() {
			return {
			};
		},
		props: {
			data: Object
		},
		components: {
		},
		mounted() {
		},
		methods: {
			cancelUpdate(){
				this.$emit("close_modal");
			},
			submitUpdate(){
        if(this.data.type == 'upload_file') {
          return this.confirmUploadFile()
        }

				var buttonName = `buttonSubmitUpdate`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				
				if(this.data.type == 'reset') {
					this.resetClocking()
				}  else {
					this.updateClocking()
				}
			},
			async resetClocking() {
				await axios.post('/payroll-timesheet-calculation?reset=true')
				.then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},

      confirmUploadFile() {
        this.$root.$emit('confirmmUpdate')
        this.$emit("close_modal");
      },

			async updateClocking() {
				await axios.post('/payroll-timesheet-calculation')
				.then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},

			refreshPage(){
				var buttonName = `buttonSubmitUpdate`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				this.$root.$emit('refresClockingIndex')

				btnSubmitLoader.classList.add('finish')
				setTimeout(()=>{
					btnSubmitText.innerHTML = this.$t('btn-submit.success')
					btnSubmit.classList.add('completed')
					btnSubmitLoader.classList.remove('onProgress', 'finish')
					btnSubmit.classList.remove('loading')
					setTimeout(()=>{
						btnSubmit.classList.remove('completed')
						btnSubmitText.innerHTML = this.$t('update.update')
						this.$emit("close_modal");
					}, 1000)
				}, 300)
			}
		}
	};
</script>