<template>
	<div class="sidebar-tbf">
		<div class="logo-tbfdigital" @mouseover="logoAnim">
			<lottie class="bird-logo active" :options="defaultOptionsLogo" :height="59" :width="48" v-on:animCreated="handleAnimationLogo"/>
		</div>
		<div class="main-menu" v-if="$auth.check()">
			<div class="item-menu" :title="$t('navbar.chart')">
				<router-link :to="{name: 'company' }" :class="{active: $route.name == 'company'}">
					<div class="border-left-active"></div>
					<icon-chart class="icon-item"/>
				</router-link>
			</div>
			<div class="item-menu" :title="$t('navbar.users')">
				<router-link :to="{name: 'users' }" :class="{active: $route.name == 'users'}">
					<div class="border-left-active"></div>
					<icon-people class="icon-item"/>
				</router-link>
			</div>
			<div class="item-menu" :title="$t('navbar.contracts')">
				<router-link :to="{name: 'contracts' }" :class="{active: $route.name == 'contracts'}">
					<div class="border-left-active"></div>
					<icon-contract class="icon-item"/>
				</router-link>
			</div>
			<div class="item-menu" :title="$t('navbar.dashboard')">
				<router-link :to="{name: 'salary-dashboard' }" :class="{active: salaryRoutes.includes($route.name)}">
					<div class="border-left-active"></div>
					<icon-salary class="icon-item"/>
				</router-link>
			</div>
			<div class="item-menu" :title="$t('navbar.nomenclature')">
				<router-link :to="{name: 'nomenclature-benefits' }" :class="{active: nomenclatureRoutes.includes($route.name)}">
					<div class="border-left-active"></div>
					<icon-nomenclature class="icon-item"/>
				</router-link>
			</div>
			<div class="item-menu" :title="$t('general.roles')" v-if="$auth.user().rights.admins_instance_crud">
				<router-link :to="{name: 'roles' }" :class="{active: $route.name == 'roles'}">
					<div class="border-left-active"></div>
					<icon-role class="icon-item"/>
				</router-link>
			</div>
			<div class="item-menu" :title="$t('vacant_jobs.title')" v-if="$auth.user().rights.admins_instance_crud">
				<router-link :to="{name: 'vacant-jobs' }" :class="{active: $route.name == 'vacant-jobs'}">
					<div class="border-left-active"></div>
					<icon-vacant-job class="icon-item"/>
				</router-link>
			</div>
			<div :title="$t('navbar.reports')" class="item-menu">
				<router-link :to="{name: 'reports' }" :class="{active: $route.name == 'reports'}">
					<div class="border-left-active"></div>
					<icon-reports class="icon-item"/>
				</router-link>
			</div>
			<div class="item-menu" :title="$t('navbar.settings')">
				<router-link :to="$auth.user().is_entrepreneur ? {name: 'settings-departments' } : {name: 'settings-profile' }" :class="{active: settingsRoutes.includes($route.name)}">
					<div class="border-left-active"></div>
					<icon-settings class="icon-item"/>
				</router-link>
			</div>
		</div>
		<div class="logout-sidebar">
			<div class="logout-action" @click="$auth.logout()"><icon-logout /></div>
		</div>
	</div>
</template>

<script>
	import Lottie from 'vue-lottie';
	import * as animationDataLogo from '../../assets/animations/tbfdigital_logo.json';
	import IconDailyPlan from '../Icons/DailyPlan'
	import IconChart from '../Icons/Chart'
	import IconPeople from '../Icons/People'
	import IconContract from '../Icons/Contract'
	import IconRole from '../Icons/Role'
	import IconVacantJob from '../Icons/VacantJob'
	import IconReports from '../Icons/Reports'
	import IconSettings from '../Icons/Settings'
	import IconSalary from '../Icons/Salary'
	import IconNomenclature from '../Icons/Nomenclature'
	import IconLogout from '../Icons/Logout'

	export default {
		data() {
			return {
				load: true,
				defaultOptionsLogo: {
					animationData: animationDataLogo.default,
					autoplay: false,
					loop: false,
				},
				finished: true,
				nomenclatureRoutes: ["nomenclature-benefits", "nomenclature-retentions", "nomenclature-company", "nomenclature-settings"],
				salaryRoutes: ['salary-dashboard', 'clocking', 'payroll', 'sick-leaves', 'other-leaves', 'tickets', 'bonusses', 'retentions','others-retentions', 'suspensions', 'detachments', 'd112'],
				settingsRoutes: ["settings-subscriptions", "settings-work-schedules", "settings-departments", "settings-workstations", "settings-skills", "settings-profile"]
			};
		},
		components: {
			Lottie,
			IconDailyPlan,
			IconChart,
			IconPeople,
			IconContract,
			IconSettings,
			IconSalary,
			IconNomenclature,
			IconRole,
			IconVacantJob,
			IconReports, 
			IconLogout
		},
		methods: {
			handleAnimationLogo(anim){
				this.anim_logo = anim;
				this.anim_logo.setSpeed(1);
			},
			logoAnim(){
				if(this.finished && !this.bird_noti){
					this.finished = false;
					this.anim_logo.play();
					setTimeout(() => {
						this.anim_logo.stop();
						this.finished = true;
					}, 5000);
				}
			}
		}
	};
</script>